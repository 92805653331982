import React from 'react'

import './OdooTraining.css'
import { Link } from 'react-router-dom'
import Nav from '../NavBar/Nav'
import { IoLogoWhatsapp } from 'react-icons/io'
import icon1 from '../../OdooAssets/Icons/L 1.png'
import icon2 from '../../OdooAssets/Icons/K 1.png'
import icon3 from '../../OdooAssets/Icons/TTTT 1.png'
import OdooTrainingImg2 from '../../OdooAssets/OdooTraining Group 12 (1).png'
import OdooTrainingImg3 from '../../OdooAssets/OdooTraining Group 12 (2).png'
import OdooTrainingImg1 from '../../OdooAssets/OdooTraining Group 12.png'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import { PiCurrencyDollar } from "react-icons/pi";
import { CiCircleCheck } from "react-icons/ci";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const OdooTraining = () => {
  return (
    <div className='OdooTraining'>

      <NavScrolled/>

      <div className='OdooTraining-header'>
        <div>
            <span>ONE-TO-ONE FACE TO FACE </span>
            <p>Odoo Training</p>
            <span>
                Our tailored training program provides a comprehensive learning journey, furnishing you with essential skills and valuable insights to optimise your use of the Odoo application.
            </span>
        </div>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooTraining-part1'>
        <div>
            <img src={icon1} alt='icon1'/>
            <p>Functional Training</p>
            <span>
            Unlock the full potential of Odoo modules with our comprehensive functional training sessions tailored for end users, partners, business analysts, and consultants. Gain proficiency in navigating the extensive functionalities of Odoo to enhance business operations and drive success.
            </span>
        </div>
        <div>
            <img src={icon2} alt='icon2'/>
            <p>Functional Training</p>
            <span>
            Elevate your development skills with our specialised technical training designed for developers seeking to customise and innovate within the Odoo platform. Dive deep into advanced concepts and techniques to effectively implement customizations and develop new features.            
            </span>
        </div>
        <div>
            <img src={icon3} alt='icon3'/>
            <p>Corporate Training</p>
            <span>
            Empower your corporate team with our dedicated corporate training program, specifically designed for businesses utilising Odoo or planning a complete migration to the platform. Equip your workforce with the knowledge and skills required to leverage Odoo effectively for streamlined business operations and increased efficiency.
            </span>
        </div>
      </div>

      <div className='OdooTraining-part2'>
        <div>
            <p>Functional Training Overview</p>
            <span>
            Enhance your Odoo expertise with our comprehensive functional training program tailored for clients, infrastructure providers, and end-users seeking a deeper understanding of Odoo's functional aspects. Our functional training covers:
            </span>
            <div className='OdooTraining-part2-points'>
                <p><MdKeyboardDoubleArrowRight size={30}/> In-depth exploration of all functional modules by Odoo Experts.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Hands-on training with the latest editions and versions of Odoo.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Instruction on installation, management, and configuration of basic modules.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Guidance on creating and customising modules using Odoo Studio.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Strategies to optimise time, reduce costs, and enhance business efficiency.</p>
            </div>
            <div className='OdooTraining-part2-price'>
                <div className='OdooTraining-part2-price-package'>
                    <p>Training Package</p>
                    <div><span><PiCurrencyDollar size={15}/>199</span><p>4 Hours</p></div>
                </div>
                <div className='OdooTraining-part2-price-package-desc'>
                    <p>Plan Benefits & Features</p>
                    <span><CiCircleCheck size={20}/>1 Day (4 Hours/Day)</span>
                    <span>
                        <CiCircleCheck size={20} className='OdooTraining-part2-price-package-desc-svg2'/>
                        Modules include CRM, Marketing Automation, Social Marketing, SMS Marketing
                        Want to know more about our Odoo Training Customized Packages, Send an e-mail 
                        to contact@legionssoft.com
                    </span>
                </div>
            </div>
        </div>
        <img src={OdooTrainingImg1} alt='OdooTrainingImg1'/>
      </div>

      <div className='OdooTraining-part3'>
        <div>
            <p>Technical Training Overview</p>
            <span>Our technical Odoo Training program is designed for aspiring candidates and IT professionals interested in Odoo customization and development. This comprehensive training covers the following key aspects:</span>
            <div className='OdooTraining-part3-points'>
                <p><MdKeyboardDoubleArrowRight size={30}/> Installation of Odoo on Linux/Windows platforms.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Overview of the Odoo framework and architecture.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Hands-on experience in developing Odoo apps.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Strategies for troubleshooting technical issues.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Training Package Details</p>
            </div>
            <div className='OdooTraining-part3-price'>
                <div className='OdooTraining-part3-price-package'>
                    <p>Training Package</p>
                    <div><span><PiCurrencyDollar size={15}/>499</span><p>4 Hours</p></div>
                </div>
                <div className='OdooTraining-part3-price-package-desc'>
                    <p>Plan Benefits & Features</p>
                    <span><CiCircleCheck size={20}/>Odoo Installation</span>
                    <span>
                        <CiCircleCheck size={20} className='OdooTraining-part2-price-package-desc-svg2'/>
                        2 Day (4 Hours/Day) <br/> <br/> Want to know more about our Odoo Training Customized Packages, Send an e-mail to contact@legionssoft.com
                    </span>
                </div>
            </div>
        </div>
        <img src={OdooTrainingImg3} alt='OdooTrainingImg3'/>
      </div>

      <div className='OdooTraining-part2'>
        <div>
            <p>Corporate Training Overview</p>
            <span>Our corporate training program is tailored for businesses already utilising Odoo or those planning a complete migration to the platform. This comprehensive training package includes:</span>
            <div className='OdooTraining-part2-points'>
                <p><MdKeyboardDoubleArrowRight size={30}/> In-depth training on relevant Odoo modules used in business processes.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Engaging sessions featuring live demos and interactive learning.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Access to training resources, including instructional videos.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Training Package Details</p>
            </div>
            <div className='OdooTraining-part2-price'>
                <div className='OdooTraining-part2-price-package'>
                    <p>Training Package</p>
                    <div><span><PiCurrencyDollar size={15}/>999</span><p>8 Hours</p></div>
                </div>
                <div className='OdooTraining-part2-price-package-desc'>
                    <p>Plan Benefits & Features</p>
                    <span><CiCircleCheck size={20}/>Odoo Installation</span>
                    <span>
                        <CiCircleCheck size={20} className='OdooTraining-part2-price-package-desc-svg2'/>
                        2 Day (4 Hours/Day) <br/> <br/> Want to know more about our Odoo Training Customized Packages, Send an e-mail to contact@legionssoft.com
                    </span>
                </div>
            </div>
        </div>
        <img src={OdooTrainingImg2} alt='OdooTrainingImg2'/>
      </div>
      
      <div className='OdooTraining-part4-container'>
        <div className='OdooTraining-part4'>
            <p>Why Legions Soft</p>
            <span>
            Legions Soft stands as a certified Odoo Gold Partner, bringing extensive expertise to the realm of Odoo implementation and professional training. Our distinguished offerings include:            </span>
            <div className='OdooTraining-part4-list'>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Comprehensive training on Odoo modules tailored to your business processes.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> A dedicated team of Odoo experts committed to providing unparalleled support.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Prominent contributions to the Odoo app ecosystem, showcasing our commitment to innovation.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Authorship of published works covering multiple versions of the Odoo platform.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Adherence to high coding standards, ensuring robust and reliable solutions.</p>
                </div>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Technical and functional proficiency across all Odoo modules.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Published books on multiple versions of Odoo</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Technical and Functional Expertise in all Odoo modules</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> High quality deliverables</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> 8+ years of experience</p>
                </div>
            </div>
        </div>
      </div>


      <Footer/>
      
    </div>
  )
}

export default OdooTraining