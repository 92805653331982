import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import MobileAppDevelopment from './components/Services/MobileAppDevelopment';
import WebDevelopment from './components/Services/WebDevelopment'
import Contract from './components/Contact/Contract';
import Marketing from './components/Services/Marketing';
import About from './components/AboutTow/About';
import HireDevelopers from './components/Services/HireDevelopers';
import OdooServices from './components/Services/OdooServices';
import OdooCustomization from './components/Odoo Services/OdooCustomization'
import OdooTraining from './components/Odoo Services/OdooTraining';
import OdooERPEducation from './components/Odoo Services/OdooERPEducation'
import OdooERPManufacturing from './components/Odoo Services/OdooERPManufacturing'
import OdooERPService from './components/Odoo Services/OdooERPService'
import OdooERPTrading from './components/Odoo Services/OdooERPTrading'
import OdooImplementation from './components/Odoo Services/OdooImplementation'
import OdooSupport from './components/Odoo Services/OdooSupport'
import OdooEmbroidery from './components/Odoo Services/OdooEmbroidery';

import { Helmet } from 'react-helmet';
import OdooDevelopmentCompany from './components/Odoo Services/OdooDevelopmentCompany';
import OdooDemo from './components/Odoo Services/OdooDemo';
import OdooERP from './components/Odoo Services/OdooERP';
import OdooIntegration from './components/Odoo Services/OdooIntegration';
import OdooMigration from './components/Odoo Services/OdooMigration';
import WebDesign from './components/Marketing Services/WebDesign';
import EmailMarketing from './components/Marketing Services/EmailMarketing';
import SocialMediaMarketing from './components/Marketing Services/SocialMediaMarketing';
import GraphicsDesign from './components/Marketing Services/GraphicsDesign';

import BlockChain from './components/Web Development Services/BlockChain'
import WebCMS from './components/Web Development Services/WebCMS'
import PaymentGetways from './components/Web Development Services/PaymentGetways'
import ServerDevelopment from './components/Web Development Services/ServerDevelopment'
import IOSDevelopment from './components/App Development Services/IOSDevelopment';
import AndroidDevelopment from './components/App Development Services/AndroidDevelopment';
import HybridDevelopment from './components/App Development Services/HybridDevelopment';
import AppSupport from './components/App Development Services/AppSupport';

function App() {
  return (
    <div className="App">  
       <HashRouter>

        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/contact' element={<Contract/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/digital-marketing' element={<Marketing/>}/>
            <Route path='/OdooServices' element={<OdooServices/>}/>
            <Route path='/HireDevelopers' element={<HireDevelopers/>}/>
            <Route path='/MobileAppDevelopment' element={<MobileAppDevelopment/>}/>
            <Route path='/web-development' element={<WebDevelopment/>}/>

            <Route path='/MobileAppDevelopment/IOSDevelopment' element={<IOSDevelopment/>}/>
            <Route path='/MobileAppDevelopment/AndroidDevelopment' element={<AndroidDevelopment/>}/>
            <Route path='/MobileAppDevelopment/HybridDevelopment' element={<HybridDevelopment/>}/>
            <Route path='/MobileAppDevelopment/app-support' element={<AppSupport/>}/>

            <Route path='/web-development/blockchain' element={<BlockChain/>}/>
            <Route path='/web-development/CMS' element={<WebCMS/>}/>
            <Route path='/web-development/payment-getways' element={<PaymentGetways/>}/>
            <Route path='/web-development/server-development' element={<ServerDevelopment/>}/>


            <Route path='/web-design-marketing' element={<WebDesign/>}/>
            <Route path="/emai-marketing" element={<EmailMarketing/>}/>
            <Route path="/social-media-marketing" element={<SocialMediaMarketing/>}/>
            <Route path='/graphics-design-marketing' element={<GraphicsDesign/>} />

            <Route path='/OdooServices/odoo-customization' element={<OdooCustomization/>}/>
            <Route path='/OdooServices/odoo-training' element={<OdooTraining/>}/>
            <Route path='/OdooServices/odoo-support' element={<OdooSupport/>}/>
            <Route path='/OdooServices/odoo-implementation' element={<OdooImplementation/>}/>
            <Route path='/OdooServices/odoo-erp-education' element={<OdooERPEducation/>}/>
            <Route path='/OdooServices/odoo-erp-trading' element={<OdooERPTrading/>}/>
            <Route path='/OdooServices/odoo-erp-service' element={<OdooERPService/>}/>
            <Route path='/OdooServices/odoo-erp-manufacturing' element={<OdooERPManufacturing/>}/>
            <Route path='/OdooServices/OdooDevelopmentCompany' element={<OdooDevelopmentCompany/>}/>
            <Route path='/OdooServices/OdooDemo' element={<OdooDemo/>}/>
            <Route path='/OdooServices/OdooERP' element={<OdooERP/>}/>
            <Route path='/OdooServices/OdooIntegration' element={<OdooIntegration/>}/>
            <Route path='/OdooServices/OdooMigration' element={<OdooMigration/>}/>
            <Route path='/OdooServices/odoo-erp-embroidery' element={<OdooEmbroidery/>}/>
        </Routes>

       </HashRouter>

       <Helmet>
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer />
        </Helmet>

        <div className="elfsight-app-26a018b2-44a8-4386-837b-0bac510dc5ae" data-elfsight-app-lazy>
        </div>
    </div>
  );
}  
export default App;
 