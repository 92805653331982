import React from 'react'
import Nav from '../NavBar/Nav'
import './ServerDevelopment.css'
import icon1 from '../../Web Assets/image 28.png'
import icon2 from '../../Web Assets/image 27.png'
import icon3 from '../../Web Assets/image 30.png'
import icon4 from '../../Web Assets/image 29.png'
import icon5 from '../../Web Assets/image 32.png'
import icon6 from '../../Web Assets/image 31.png'
import img1 from '../../Web Assets/image 33.png'
import img2 from '../../Web Assets/image 34.png'
import img3 from '../../Web Assets/LEGION SOFT (97) 1.png'
import img4 from '../../Web Assets/image 35.png'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const ServerDevelopment = () => {
  return (
    <div className='ServerDevelopment'>

      <NavScrolled/>

      <div className='ServerDevelopment-part-1'>
        <p>Legion DevOps Server</p>
        <span>
          Easily handle all aspects of your software development 
          journey with the Legion DevOps Server. It's your one-stop 
          solution for sharing code, keeping track of tasks, and 
          smoothly delivering software, all hosted on-premises.
        </span>
      </div>



      <div className='ServerDevelopment-part-2'>
        <p>
          You have the flexibility to utilize any combination of 
          Legion DevOps services that best complement your existing 
          workflows.
        </p>  

        <div className='ServerDevelopment-part-2-section-1'>

          <div>
            <img src={icon2} alt='icon'/>
            <p>legion Boards</p>
            <span>
              Gear up your teams to swiftly deliver value to users by 
              tapping into reliable agile tools. These tools not only 
              help in planning and tracking but also foster collaborative 
              discussions across your teams, ensuring everyone's voice is 
              heard.
            </span>
          </div>

          <div>
            <img src={icon1} alt='icon'/>
            <p>Legion Pipelines</p>
            <span>
              Simplify your project workflow by effortlessly building, 
              testing, and deploying with our versatile CI/CD solution. 
              It smoothly adapts to any language, platform, or cloud 
              setup, whether you're using GitHub or any other Git 
              provider.
            </span>
          </div>

          <div>
            <img src={icon4} alt='icon'/>
            <p>Legion Repos</p>
            <span>
              Gain access to unlimited cloud-hosted private Git 
              repositories, enabling seamless collaboration for 
              crafting top-notch code. Utilize features such as 
              pull requests and advanced file management to enhance 
              your development process.
            </span>
          </div>

          <div>
            <img src={icon3} alt='icon'/>
            <p>Legion Test Plans</p>
            <span>
              Feel confident in your software's quality and shipping 
              process by utilizing our manual and exploratory testing 
              tools.
            </span>
          </div>

          <div>
            <img src={icon6} alt='icon'/>
            <p>legion Artifacts</p>
            <span>
              Effortlessly create, host, and share packages with your team, 
              simplifying collaboration. Plus, seamlessly add artifacts to 
              your CI/CD pipelines in just one click, making the process 
              smooth and efficient for everyone.
            </span>
          </div>

          <div>
            <img src={icon5} alt='icon'/>
            <p>Extensions Marketplace</p>
            <span>
              Explore a wide range of extensions, from Slack to SonarCloud 
              and more than 1,000 other apps and services, all created by 
              fellow community members.
            </span>
          </div>

        </div>

        <Link>
          Learn more about Legion DevOps services
        </Link>
        
      </div>

      <div className='ServerDevelopment-part-3'>

        <div className='ServerDevelopment-part-3-section-1'>
          <img src={img1} alt='img'/>
          <img src={img2} alt='img'/>
        </div>

        <div className='ServerDevelopment-part-3-section-2'>
          <div>
            <p>What's the Legion DevOps Server all about?</p>
            <span>
              The Legion DevOps Server is your go-to hub for collaborative software 
              development tools that cater to your entire team.
              <br/>
              <br/>
              Once known as Team Foundation Server (TFS), the Legion DevOps Server 
              brings together a collection of collaborative software development tools, 
              conveniently hosted on-premises. It easily integrates with your existing 
              IDE or editor, allowing your diverse team to work together seamlessly on 
              projects of any size.
            </span>
          </div>
          <div>
            <p>Integration Made Easy</p>
            <span>
              Flexibility and openness define our approach to integration. 
              Seamlessly connect your custom tool or third-party service with Legion 
              DevOps Server using widely recognized standards such as REST APIs and 
              OAuth 2.0. Plus, easily incorporate your preferred tools and services 
              from our extensive marketplace of extensions.
            </span>
          </div>
        </div>

        <div className='ServerDevelopment-part-3-section-3'>
          <p>Top-notch Security and Compliance, at Your Service</p>
          <span>
            We've got your back when it comes to security and compliance. Microsoft 
            goes the extra mile, investing over $1 billion annually in cybersecurity 
            research and development.
            <br/>
            <br/>
            Plus, we have a dedicated team of more than 50 security experts who 
            work tirelessly to protect your data and privacy.
          </span>
        </div>


        <div className='ServerDevelopment-part-3-section-4'>
          <img src={img3} alt='img'/>
          <img src={img4} alt='img'/>
        </div>

      </div>

      <div className='ServerDevelopment-part-4'>
            <p>Don't hesitate any longer!</p>
            <span>
            We're eager to connect with you on social media. Reach out to us today 
            at contact@legionssoft.com or give us a call at 0339-4010310 to embark on your 
            journey with one of Australia's premier social media agencies.
            </span>
        </div>

        <Footer/>
    </div>
  )
}

export default ServerDevelopment