import React from 'react'
import Nav from '../NavBar/Nav'
import './BlockChain.css'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from "react-icons/io";
import icon1 from '../../Web Assets/icons/Crypto Transaction.png'
import icon2 from '../../Web Assets/icons/Security Shield.png'
import icon3 from '../../Web Assets/icons/Topup Payment.png'
import icon4 from '../../Web Assets/icons/Drag List Up.png'
import icon5 from '../../Web Assets/icons/Low Priority.png'
import img1 from '../../Web Assets/Group 443.png'
import img2 from '../../Web Assets/Group 443 (1).png'
import img3 from '../../Web Assets/Group 443 (2).png'
import img4 from '../../Web Assets/Group 443 (3).png'
import icon6 from '../../Web Assets/icons/Blockchain New Logo.png'
import icon7 from '../../Web Assets/icons/Chip.png'
import icon8 from '../../Web Assets/icons/Google Code.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const BlockChain = () => {
  return (
    <div className='BlockChain'>

    <NavScrolled/>

      <div className='BlockChain-part-1'>
          <p>Blockchain Services</p>
          <span>Unlock tangible business value by partnering with a top-tier blockchain development company.</span>
          <Link>
            <IoLogoWhatsapp/>
            Whatsapp
          </Link>
      </div>

      <div className='BlockChain-part-2'>
        <p>Fast-track your digital innovation journey with Blockchain and NFT solutions.</p>
        <span>
          As an experienced blockchain and NFT Solutions company, 
          we help organizations boost efficiency, agility and security 
          through custom blockchain development services. 
          We meet business leaders to consult and accelerate 
          their initiatives by blending our extensive experience 
          with innovation, enabling them to successfully navigate 
          the transition towards Web 3.0 and beyond.
        </span>
      </div>

      <div className='BlockChain-part-3'>

        <div>
          <img src={icon1} alt='icon'/>
          <div>
            <p>Cutting Transaction Costs with Blockchain Technology</p>
            <span>
              Blockchain technology slashes transaction costs by eliminating 
              the need for intermediaries. With transactions conducted directly 
              between parties, costs are drastically reduced while ensuring 
              heightened security and efficiency.
            </span>
          </div>
        </div>

        <div>
          <img src={icon2} alt='icon'/>
          <div>
            <p>Enhancing Security and Transparency</p>
            <span>
              Empower your supply chain with decentralized applications 
              powered by blockchain technology, offering comprehensive 
              tracking of information throughout the product lifecycle. 
              This ensures unparalleled transparency, traceability, and 
              security within your supply chain.
            </span>
          </div>
        </div>

        <div>
          <img src={icon3} alt='icon'/>
          <div>
            <p>Revolutionize Payment Solutions</p>
            <span>
              Enhance your service offerings with cutting-edge payment options, 
              enabling faster transactions and enhanced asset liquidity.
            </span>
          </div>
        </div>

        <div>
          <img src={icon4} alt='icon'/>
          <div>
            <p>Enhance Operational Efficiency with Blockchain</p>
            <span>
              Leverage blockchain technology to optimize business performance, 
              streamline processes, and facilitate real-time financial operations 
              from anywhere.
            </span>
          </div>
        </div>

      </div>

      <div className='BlockChain-part-4'>
          <p>Key Offerings</p>
          <img src={icon5} alt='icon'/>
      </div>

      <div className='BlockChain-part-5'>
        <div>
          <p>Cryptocurrency Exchange</p>
          <span>
            Our blockchain exchange solution encompasses a comprehensive 
            cryptocurrency trading platform, facilitating transparent and immutable 
            NFT transactions. This highly scalable and reliable cloud-based 
            trading platform integrates advanced security measures such as 
            two-factor authentication, cold storage, document verifications, 
            analytics tools, multi-sign wallets, and comprehensive reporting, 
            among other features.
          </span>
        </div>
        <img src={img1} alt='img'/>
      </div>

      <div className='BlockChain-part-5'>
        <img src={img2} alt='img'/>
        <div>
          <p>Public And Private Blockchain</p>
          <span>
            As a bespoke blockchain development firm, we specialise in 
            assisting enterprises in establishing blockchain networks 
            tailored to their specific requirements. Our expertise lies 
            in creating secure decentralised databases, adeptly managing 
            supply chains, facilitating transactions, and addressing various 
            business needs that blockchain technology can resolve, all while 
            maintaining the utmost confidentiality.
          </span>
        </div>
      </div>

      <div className='BlockChain-part-5'>
        <div>
          <p>Decentralized Crypto Vaults</p>
          <span>
            Our offerings cater to exchanges, institutional investors, and 
            merchants seeking secure methods to transact and store significant 
            volumes of crypto assets. This ensures safeguarding of funds against 
            security breaches, even in the event of server compromise. Leveraging 
            our secure and comprehensive blockchain development services, we 
            implement advanced features such as cold storage, multi-factor 
            approvals, threshold schemes, and air-gapped transactions, 
            providing all-encompassing protection for your assets.
          </span>
        </div>
        <img src={img3} alt='img'/>
      </div>


      <div className='BlockChain-part-5'>
        <img src={img4} alt='img'/>
        <div>
          <p>NFT Marketplace Development</p>
          <span>
            Our cutting-edge NFT development services enable 
            the creation of unique NFTs adhering to ERC-721 
            token standards. These NFTs can be associated with 
            various digital assets including artwork, collectibles, 
            videos, and more. As an AI-driven blockchain development 
            company, we boast a highly skilled team of certified 
            developers with extensive experience in building 
            resilient non-fungible token marketplaces. Our 
            platforms empower clients to seamlessly engage 
            in buying, selling, and exploring digital assets.
          </span>
        </div>
      </div>


      <div className='BlockChain-part-6'>
        <p>Offered Business Models by Legions Soft</p>
        
        <div>

          <div>
            <img src={icon6} alt='icon'/>
            <p>Blockchain as a Service (BaaS) Business Model</p>
            <span>
            In this model, we design, develop and implement blockchain-based 
            products for our customers, based on their specific needs. Our 
            blockchain development services power the entire cycle of 
            development and also enable continuous support. This 
            significantly saves costs for our customers and enables 
            them to utilize blockchain technology with maximum ROI.
            </span>
          </div>

          <div>
            <img src={icon7} alt='icon'/>
            <p>Tokenomics/Utility Tokenization Model</p>
            <span>
            The utility token business model drives functionality into 
            business via the use of tokens, which facilitate the network 
            activities. We enable startups, companies, and e-commerce 
            websites to effectively employ the utility token business 
            model. Our blockchain development solutions comprise token 
            minting, distribution, transfer, and burning systems that 
            incentivize network participation while preventing misuse.
            </span>
          </div>

          <div>
            <img src={icon8} alt='icon'/>
            <p>Blockchain-Powered Software Solutions</p>
            <span>
            In our BaaS model, we provide blockchain development services 
            like user authentication, database management, remote updating, 
            cloud storage, and hosting. As one of the most popular blockchain 
            business models, this model empowers companies to experiment, test 
            and conduct research, without having to worry about how blockchain 
            or hardware infrastructure works.
            </span>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BlockChain