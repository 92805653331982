import React from 'react'
import Nav from '../NavBar/Nav'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import OdooPart2 from '../../OdooAssets/Group 12 (1).png'
import './OdooCustomization.css'
import icon1  from '../../OdooAssets/Icons/req 1.png'
import icon2  from '../../OdooAssets/Icons/dd,.png'
import icon3  from '../../OdooAssets/Icons/fd.png'
import icon4  from '../../OdooAssets/Icons/f.png'
import icon5  from '../../OdooAssets/Icons/ghgh.png'
import icon6  from '../../OdooAssets/Icons/fdfd.png'
import icon7  from '../../OdooAssets/Icons/ddd.png'
import icon8  from '../../OdooAssets/Icons/ff.png'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import { GoDotFill } from "react-icons/go";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const OdooCustomization = () => {


  return (
    <div className='OdooCustomization'>

    <NavScrolled/>

    <div className='OdooCustomization-header'>
      <p>Odoo ERP</p>
      <p>Customization</p>
      <span>Unlock the full power of Odoo ERP by customising it to fit your business like a glove. With Odoo's versatility, you can tweak and tailor the software to match your unique needs and make managing your business a breeze!</span>
      <Link><IoLogoWhatsapp/>Whatsapp</Link>
    </div>

    {/* OdooCustomization */}
    <div className='OdooCustomization-part-2'>
      <div>
        <p>Odoo Customization Services</p>
        <span>
          At Legions Soft Technologies, we specialise in tailoring Odoo solutions to meet your specific business needs. Our customization services include:
        </span>
        <div>
          <p><GoDotFill/>Comprehensive Analysis</p>
          <p><GoDotFill/>Strategic Planning</p>
          <p><GoDotFill/>Module Customization</p>
          <p><GoDotFill/>UI/UX Enhancement</p>
          <p><GoDotFill/>Workflow Optimization</p>
          <p><GoDotFill/>Data Migration</p>
          <p><GoDotFill/>Training and Support</p>
        </div>
      </div>
      <img src={OdooPart2} alt='OdooPart2'/>
    </div>

    {/* OdooCustomization */}
    <div className='OdooCustomization-part-3-container'>
      <p>Odoo Customization Process</p>
      <div className='OdooCustomization-part-3'>
        
        <div>
          <h6>Step 1</h6>
          <img src={icon1} alt='icon1'/>
          <p>Requirement Gathering</p>
          <span>
            Our technical team will conduct a thorough meeting with you to gather detailed information about your business needs and requirements.       
          </span>
        </div>
        <div>
          <h6>Step 2</h6>
          <img src={icon2} alt='icon1'/>
          <p>GAP Analysis</p>
          <span>
          A comprehensive study is conducted to assess the feasibility of implementing the requested modifications in Odoo without compromising platform performance or default functionalities.
          </span>
        </div>
        <div>
          <h6>Step 3</h6>
          <img src={icon3} alt='icon1'/>
          <p>Feasibility Study</p>
          <span>
          A study on the feasibility to do the requested modifications in Odoo without, compromising the performance as well as breaking default functionalities of the platform.        </span>
        </div>
        <div>
          <h6>Step 4</h6>
          <img src={icon4} alt='icon1'/>
          <p>Estimation</p>
          <span>
          We provide you with a detailed estimation of the cost and duration of the implementation based on the identified requirements.
          </span>
          </div>
        <div>
          <h6>Step 5</h6>
          <img src={icon5} alt='icon1'/>
          <p>Customer Approval</p>
          <span>
          We await your approval to proceed with the customization process, ensuring alignment with your expectations.
          </span>
        </div>
        <div>
          <h6>Step 6</h6>
          <img src={icon6} alt='icon1'/>
          <p>Development</p>
          <span>
          Our team develops the customised modules according to your needs and requirements, adhering to industry standards and best practices.  
          </span>
        </div>
        <div>
          <h6>Step 7</h6>
          <img src={icon7} alt='icon1'/>
          <p>Testing</p>
          <span>
           Rigorous integrated and unit testing is conducted to ensure the reliability and functionality of the customised Odoo platform.  
          </span>
        </div>
        <div>
          <h6>Step 8</h6>
          <img src={icon8} alt='icon1'/>
          <p>Delivery</p>
          <span>
          Upon successful completion and testing, the customised modules are delivered to you, ready for implementation in your business operations.
          </span>
        </div>
      </div>
    </div>

    <div className='OdooCustomization-part-4-container'>
          <p>Why Legions Soft</p>
          <span>Our team is here to help you identify any technical problems you're experiencing.</span>
          <div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> High coding standards</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Expert in all versions of Odoo</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 8+ years of experience</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 900+ satisfied customers across the globe</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 790+ Free modules contributions in Odoo apps</p>
            </div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> Ranks Fifth in Odoo app downloads</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High-quality deliverables</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Technical & Functional expertise in all Odoo modules</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Service in both enterprise & community editions</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High quality deliverables</p>
            </div>
          </div>
    </div>

    <div className='OdooCustomization-part-5'>
        <p>Our Odoo Services</p>
        <div className='OdooCustomization-part-5-items'>
          <div>
            <span><img src={icon15} alt='icon15'/></span>
            <p>Odoo Customization</p>
          </div>
          <div>
            <span><img src={icon16} alt='icon15'/></span>
            <p>Odoo Implementation</p>
          </div>
          <div>
            <span><img src={icon17} alt='icon15'/></span>
            <p>Odoo Integration</p>
          </div>
          <div>
            <span><img src={icon18} alt='icon15'/></span>
            <p>OOdoo Support & Maintenance</p>
          </div>
          <div>
            <span><img src={icon19} alt='icon15'/></span>
            <p>Odoo Migration</p>
          </div>
          <div>
            <span><img src={icon20} alt='icon15'/></span>
            <p>Odoo Training</p>
          </div>
          <div>
            <span><img src={icon21} alt='icon15'/></span>
            <p>Odoo Consultancy</p>
          </div>
        </div>
    </div>

      <Footer/>

  </div>
  )
}

export default OdooCustomization