import React from 'react'
import img1 from '../../MarketingAssets/Rectangle 555.png'
import img2 from '../../MarketingAssets/Rectangle 557.png'
import img3 from '../../MarketingAssets/Rectangle 559.png'
import './EmailMarketing.css'
import { GoDotFill } from "react-icons/go";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const EmailMarketing = () => {
  return (
    <div className='EmailMarketing-container'>

        <NavScrolled/>

        <div className='EmailMarketing-part-1'>
            <p>Email Marketing</p>
            <span>
                If you care about ROI, you need to be interested in email marketing: A recent DMA study estimated an average return of $42 for every dollar spent on email marketing in 2019. There are very few other channels—if any!—offering returns of that magnitude, and email marketing is one that is available to every brand.
            </span>
        </div>


        <div className='EmailMarketing-part-2'>
            <img src={img1} alt='img'/>
            <div>
                <p>
                    Tap into the Power of Email Marketing for Your Brand
                </p>
                <span>
                    Despite its potential, however, email marketing needs to be done well or it will be ineffective, even harmful. Send off sporadic, lackluster emails, and they’ll go unread in your lead’s inbox. Bombard your audience with poorly designed campaigns or tone-deaf messages, and your reputation will suffer long-term damage. Successful email marketing requires a deep understanding of the industry and your audience to strike the proper tone and balance, but it’s well worth the investment to get it right.
                </span>
            </div>
        </div>


        <div className='EmailMarketing-part-3'>
            <p>Enlist Our Email Marketing Experts</p>
            <span>
                At NEWMEDIA, we’re helping businesses take control of their email marketing and grow their audience, their brand, and their sales. With our help, your brand can tap into the channel’s incredible ROI with tailored campaigns that complement your existing marketing strategies. You’ll get the benefit of our industry expertise not only in crafting the ideal email marketing strategy for your existing audience, but in growing your subscriber base for even more reach. When combined with social media marketing, SEO and PPC, an effective email campaign strategy can really complete your omnichannel marketing campaign.
                <br/>
                <br/>
                Do you need straightforward promotional emails, or a more robust setup with different emails triggered for customers at different points in your sales funnel? How frequently should you be sending them? What content should your emails feature and what calls-to-action should they include? How will you measure success? Our team will help you determine all this and more through our comprehensive email marketing services:
            </span>
            <div className='EmailMarketing-part-3-section'>
                <div>
                    <p><GoDotFill/>Campaign strategy and ongoing management</p>
                    <p><GoDotFill/>Professional, custom email templates for your unique branding</p>
                    <p><GoDotFill/>Customized emails for different audiences</p>
                    <p><GoDotFill/>Ongoing analytics and A/B testing to optimize your campaigns</p>
                    <p><GoDotFill/>Detailed reporting to show you what revenue is being generated</p>
                </div>
                <img src={img2} alt='img'/>
            </div>
        </div>

        <div className='EmailMarketing-part-4'>
            <img src={img3} alt='img'/>
            <div>
                <p>Reach Your Audience with Effective Email Marketing</p>
                <span>If you aren’t taking advantage of the power of email marketing for your brand, you’re leaving money on the table—and a lot of it. NEWMEDIA can help you take it back. Get in touch today for your free quote.</span>
            </div>
        </div>

        <Footer/>

    </div>
  )
}

export default EmailMarketing