import React from 'react'
import Nav from '../NavBar/Nav'
import icon1 from '../../MarketingAssets/Light On.png'
import icon2 from '../../MarketingAssets/Thick Arrow Pointing Down.png'
import img1 from '../../MarketingAssets/wewtewt 1.png'
import img2 from '../../MarketingAssets/Types-of-Facebook-Users-around-the-World 1.png'
import img3 from '../../MarketingAssets/wwwr 1.png'
import img4 from '../../MarketingAssets/WhatsApp-Statistics-1 1.png'
import img5 from '../../MarketingAssets/Demographics-for-Snapchat-removebg-preview 1.png'
import img6 from '../../MarketingAssets/Marketing-Statistics-for-Pinterest 1.png'
import img7 from '../../MarketingAssets/Statistics-for-LinkedIn 2.png'
import './SocialMediaMarketing.css'
import { GoDotFill } from "react-icons/go";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const SocialMediaMarketing = () => {
  return (
    <div className='SocialMediaMarketing-container'>

        <NavScrolled/>

        <div className='SocialMediaMarketing-part-1'>
            <p>Social Media Marketing</p>
            <span>
                Social media networks are powerful marketing platforms that deliver amazing results, which is why so many companies are starting to work with social media marketers.
            </span>
        </div>

        <div className='SocialMediaMarketing-part-2'>

            <div className='WebDesign-part-3-section-1'>
                <div><img src={icon1} alt='icon'/></div>
                <p>Social Media<br/>Marketing</p>
            </div>

            <div className='SocialMediaMarketing-part-2-section-2'>
                <p>1 
                    <span>
                        In the halcyon days of traditional marketing, the idea of using social media channels to engage people directly wasn't even a dream in most marketers' heads. The majority of marketing campaigns were a one-way street, with billboards, print media, and TV and radio ads all sending messages out into the world with no way for the world to respond but through its collective wallet.                    
                    </span>
                </p>
                <p>
                    2
                    <span>
                        The rise of digital marketing changed all of that, with social media networks transforming from places where people could connect with their friends into powerful marketing platforms.                    
                    </span>
                </p>
                <p>
                    3
                    <span>
                        And those platforms (at least when you get this aspect of online marketing right) deliver amazing results, which is why so many companies are starting to work with social media marketers.                    
                    </span>
                </p>
            </div>

        </div>

        <div className='SocialMediaMarketing-part-3'>
            <p>
                You probably have a few profiles set up on the likes of Facebook or LinkedIn yourself, though, which begs the question:
                Do you have a social media marketing plan, and (if you do) have you any idea how to measure its success?
                Surprisingly, the answer to the second question, at least for most companies, is that they have no idea, as these stats from Andrew Macarthy indicate:
                <br/>
                <br/>
                Despite the fact that most companies get why social media marketing is a good thing, a staggering 83% have nothing in place to help them to measure the ROI of their social efforts. Over half (56%) struggle even more when multiple social media channels come into play, with 54% also saying that they have no idea how their social media ROI (assuming they know it) integrates into the rest of their company’s goals.
                <br/>
                <br/>
                Achieving social media platform mastery through intelligent strategies is the key to solving these problems. And with NEWMEDIA by your side, you’ll have a digital marketing company that can finally demonstrate (and wield) the power of going social.
            </p>
            <img src={img1} alt='img'/>
        </div>

        <div className='SocialMediaMarketing-part-4'>
            <p>WHAT IS A SOCIAL MEDIA MARKETING STRATEGY?</p>
            <span>Before you can even think about setting up any social media marketing campaigns, there’s an important question to answer:</span>
            <div className='SocialMediaMarketing-part-4-section-1'>
                <p>What is social media marketing, exactly?</p>
                <span>
                    For many businesses (particularly those that don’t leverage social channels as well as they could), coming up with social media marketing strategies can feel like a chore. You have to deal with these “always on” platforms that give potential customers 24/7 access to your business so they can research, write about, share, and even complain about your company.
                    Sure, it’s great to have multiple platforms on which to share your message. But with so many platforms to choose from (and different social media strategies for each), you might feel like you’re trying to keep a dozen plates spinning with both hands tied behind your back.
                    <br/>
                    <br/>
                    Thankfully, social media marketing isn’t as complicated as it first appears as long as you know how to focus your digital marketing efforts in the right direction. That means less aimless posting where you put something (anything!) up on your profiles to grab the attention of social media users. Instead, your marketing efforts boil down to four key actions, which combine to help you create great social media campaigns and, as importantly, manage those campaigns for ongoing social media success.
                    <br/>
                    <br/>
                    So, if we boil social media marketing down to the bare basics, there are four main activities
                </span>
            </div>
            <div className='SocialMediaMarketing-part-4-section-2'>
                <div>
                    <p><GoDotFill/> Creating and optimizing your social media profiles.</p>
                    <p><GoDotFill/> Posting content onto social platforms so you can attract social media followers and get people sharing cool stuff about your business.</p>
                    <p><GoDotFill/> Responding to what people say to you (or share about your business).</p>
                    <p><GoDotFill/> Engaging with anybody and everybody on social media who can help you, ranging from the community you’ve built to influencers who can turn the tide of public sentiment in your favor.</p>
                </div>
                <span>
                    Et those four core pillars right (with the help of the digital marketing experts from NEWMEDIA) and you’re in the perfect position to transform social media from nuisance to marketing nirvana.
                </span>
            </div>
        </div>


        <div className='SocialMediaMarketing-part-5'>
            <p>DIGGING INTO THE SOCIAL MEDIA PLATFORMS</p>
            <div>
                <p>Let’s start with some good news:</p>
                <span>
                    No matter which social media platforms you use for marketing, the above elements all come into play in some way or another.
                </span>
                <p>Now, the bad news:</p>
                <span>
                    You’ve got to be choosy when it comes to which platforms you use for your social media marketing because trying to do them all is like trying to stretch a rubber band over a bowling ball – eventually, the elastic snaps. Or, in business terms, you’ll spend more money than you have on campaigns that don’t have your full attention, leading to social media marketing losing money for your company.
                </span>
                <p>So, which social media marketing platform(s) should you choose?</p>
                <span>
                    We can’t give you the answers outright (we’d need to learn more about your business and its target audience first), but we can give you some insight into what each platform offers.
                </span>
            </div>
            <img src={icon2} alt='icon'/>
        </div>

        <div className='SocialMediaMarketing-part-6'>
            <div>
                <p>Facebook</p>
                <span>
                    The granddaddy of social media marketing (sorry, MySpace), Facebook is the go-to platform for most who are new to using social to speak to potential clients. It’s also one of the most effective platforms around, as these stats from Finances Online:
                </span>
                <img src={img2} alt='img'/>
            </div>
            <div>
                <p>Twitter</p>
                <span>
                    Forget about all of the controversy (and chaos) that has come with Elon Musk’s takeover of Twitter because the platform is still extremely powerful when it comes to marketing to a large audience.
                <br/>
                Why?
                <br/>
                    Engagement is the key to Twitter, as its short-form version of posting (coupled with the ease with which people can tag their favorite brands) makes it the best place for people to communicate with companies and vice-versa:
                </span>
                <img src={img3} alt='img'/>
            </div>
            <div>
                <p>WhatsApp</p>
                <span>
                    “Wait a second,” we hear you say. “WhatsApp is a messaging platform. I can see how it replaced SMS messaging as a marketing technique (especially because it’s cheaper), but how does it fit into the social media marketing equation?”
                </span>
                <img src={img4} alt='img'/>
            </div>
            <div>
                <p>Snapchat</p>
                <span>
                    Just like WhatsApp, Snapchat is a great way to run messaging campaigns with a community, with the added bonus being that brands can create their own accounts to share short-form videos.
                <br/>
                    Think of the app like a Frankenstein’s baby of TikTok and WhatsApp, with the added bonus (or issue) of content impermanence. In other words, the stuff you share on Snapchat disappears after 24 hours (or less), making it a great choice for short-term campaigns but perhaps not the best social media platform for multi-month or multi-year initiatives.
                </span>
                <img src={img5} alt='img'/>
            </div>
            <div>
                <p>Pinterest</p>
                <span>
                    Discovery is the name of the game with Pinterest, which is a social media channel that allows users to “pin” their favorite pictures to online boards. Think of it like an online photo album or, better yet, a vision board that people can use to cobble together images of stuff they want to add to their lives.
                </span>
                <img src={img6} alt='img'/>
            </div>
            <div>
                <p>LinkedIn</p>
                <span>
                    Just like WhatsApp, Snapchat is a great way to run messaging campaigns with a community, with the added bonus being that brands can create their own accounts to share short-form videos.
                <br/>
                    Think of the app like a Frankenstein’s baby of TikTok and WhatsApp, with the added bonus (or issue) of content impermanence. In other words, the stuff you share on Snapchat disappears after 24 hours (or less), making it a great choice for short-term campaigns but perhaps not the best social media platform for multi-month or multi-year initiatives.
                </span>
                <img src={img7} alt='img'/>
            </div>
        </div>

        <div className='SocialMediaMarketing-part-7'>
            <p>So, what are you waiting for?</p>
            <span>
                We’re ready to get social with you, so send us a message at contact@legionssoft.com or call us at -339-4010310 to start your journey with one of Australian leading social media agencies.
            </span>
        </div>

        <Footer/>


    </div>
  )
}

export default SocialMediaMarketing