import React from 'react'
import Nav from '../NavBar/Nav'
import './HybridDevelopment.css'
import img1 from '../../App Assets/12 1.png'
import icon1 from '../../App Assets/icons/ui-design-(1) 1.png';
import icon2 from '../../App Assets/icons/mobile-app.png';
import icon3 from '../../App Assets/icons/cross-platform.png';
import icon4 from '../../App Assets/icons/Android Phone.png';
import icon5 from '../../App Assets/icons/mobile-game 1.png';
import img2 from '../../App Assets/Rectangle 711.png'
import img3 from '../../App Assets/Rectangle 712.png'
import img4 from '../../App Assets/Rectangle 713.png'
import img5 from '../../App Assets/Rectangle 711 (1).png'
import img6 from '../../App Assets/Rectangle 712 (1).png'
import img7 from '../../App Assets/Rectangle 713 (1).png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled';


const HybridDevelopment = () => {
  return (
    <div className='HybridDevelopment'>

        <NavScrolled/>


        <div className='HybridDevelopment-part-1'>
            <p>HYBRID APPLICATION DEVELOPMENT</p>
            <span>
                A hybrid app is a software application that joins components of 
                both native applications and web applications. Hybrid applications 
                include an additional layer between the source code and the objective 
                stage, they may perform marginally slower than native web adaptations 
                of the equivalent application.
            </span>
        </div>

        <div className='HybridDevelopment-part-2'>
            <p>
                It works on the same operating system (IOS), as the iPhones. Using an IPad 
                gives you an edge over laptops. After complete market analysis Legions Soft 
                team has come up with some wonderful applications that the IPad supports.
                <br/>
                <br/>
                <br/>
                Soft Solutions are quite honored to design few applications for IPad. Legions 
                Soft always believed in customer satisfaction and our work visibly sets us apart 
                from others in the market.
                <br/>
                <br/>
                <br/>
                Legions Soft have designed these IPad applications using IOS written in Java. Legions 
                Soft have a separate testing team apart from the development team that has tested these 
                functions in the emulator against the requirements.
            </p>
        </div>

        <div className='HybridDevelopment-part-3'>
            <div>
                <p>
                    <span>OUR</span>
                    PORTFOLIO
                </p>
                <span>
                    Legions Soft works hard to create practical and 
                    business generating solutions for its clients. 
                    Our portfolio is the place where we feel 
                    extremely proud. Years of work and thriving 
                    businesses aided with out technology.
                </span>
            </div>
            <img src={img1} alt='img'/> 
        </div>

        <div className='HybridDevelopment-part-4'>
            <p>Related Services</p>
            <div className='HybridDevelopment-part-3-section-1'>
                <div>
                    <img src={icon1} alt='icon'/>
                    <p>Mobile Apps Design & Development</p>
                </div>
                <div>
                    <img src={icon2} alt='icon'/>
                    <p>IPHONE Application Development</p>
                </div>
                <div>
                    <img src={icon3} alt='icon'/>
                    <p>ANDROID Application Development</p>
                </div>
                <div>
                    <img src={icon4} alt='icon'/>
                    <p>Cross Platform Application Development</p>
                </div>
                <div>
                    <img src={icon5} alt='icon'/>
                    <p>Mobile Game Development</p>
                </div>
            </div>
        </div>

        <div className='HybridDevelopment-part-5'>
            <span>EXPLORE MORE</span>
            <p>Unlock Your Brand's Potential with Mobile Apps</p>
            <div className='HybridDevelopment-part-5-section-1'>
                <div>
                    <span></span>
                    <p>Crafted Just for You:</p>
                </div>
                <div>
                    <span></span>
                    <p>Designed for People</p>
                </div>
                <div>
                    <span></span>
                    <p>Staying Ahead Together</p>
                </div>
            </div>
        </div>

        <div className='HybridDevelopment-part-6'>
            <span>OUR BLOG</span>
            <p>Spreading Positivity</p>
            <div className='HybridDevelopment-part-6-section-1'>

                <div>
                    <p>Why Small and Mid-Sized Businesses Need a Stellar Website?</p>
                    <img src={img2} alt='img'/>
                </div>
                <div>
                    <p>Insider Insights: Ecommerce Website Development in Pakistan</p>
                    <img src={img3} alt='img'/>
                </div>
                <div>
                    <p>Accelerate Your Business Journey: Harness the Power of Mobile Apps</p>
                    <img src={img4} alt='img'/>
                </div>
                
                <div>
                    <img src={img5} alt='img'/>
                    <p>What's Responsive Design and Why Should I Care for My Website?</p>
                </div>

                <div>
                    <img src={img6} alt='img'/>
                    <p>Steps for a Successful Mobile App Launch</p>
                </div>

                <div>
                    <img src={img7} alt='img'/>
                    <p>Insider's Guide: What You Need to Know About Developing Mobile Apps</p>
                </div>

            </div>
        </div>

        <div className='HybridDevelopment-part-7'>
            <p>Don't hesitate any longer!</p>
            <span>
            We're eager to connect with you on social media. Reach out to us today 
            at contact@legionssoft.com or give us a call at 0339-4010310 to embark on your 
            journey with one of Australia's premier social media agencies.
            </span>
        </div>
        <Footer/>
    </div>
  )
}

export default HybridDevelopment