import React from 'react'
import Nav from '../NavBar/Nav'
import './WebDesign.css'
import icon1 from '../../assets/icons/Design.png'
import icon2 from '../../assets/icons/Down Button.png'
import icon3 from '../../assets/icons/Bobbin.png'
import icon4 from '../../assets/icons/Strategy News.png'
import icon5 from '../../assets/icons/Goal.png'
import img1 from '../../MarketingAssets/responsive-web-design-example 1.png'
import img2 from '../../MarketingAssets/FZZD-removebg-preview 1.png'
import img3 from '../../MarketingAssets/SDSSDDS-removebg-preview 1.png'
import { Link } from 'react-router-dom'
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const WebDesign = () => {
  return (
    <div className='WebDesign-container'>

        <NavScrolled/>

        <div className='WebDesign-part-1'>
            <p>WEB DESIGN</p>
        </div>

        <div className='WebDesign-part-2'>
            <p>Whether you are in the market to design a new website, redesign your current website, add or enhance your ecommerce shopping cart, build or expand your content marketing, or create or manage your social media brand, our experts can consult with you, strategize the right plan and execute it perfectly, at a price that may surprise you.</p>
        </div>

        <div className='WebDesign-part-3'>

            <div className='WebDesign-part-3-section-1'>
                <div><img src={icon1} alt='icon'/></div>
                <p>Awesome<br/>Web Design</p>
            </div>

            <div className='WebDesign-part-3-section-2'>
                <p>1 
                    <span>
                        The initial impression is design related. You can’t expect a person to read a great headline your copywriter wrote in under 50 milliseconds and then create an impression (good or bad) about your website. No. This doesn’t happen.
                    </span>
                </p>
                <p>
                    2
                    <span>
                        It is the website design and overall aesthetic of the web page (preferably above the fold) that creates the very first impression. This impression determines whether a visitor will continue to stay or abandon your website.
                    </span>
                </p>
                <p>
                    3
                    <span>
                        Website design matters more than you think. It is a deciding factor. A key to traffic, leads, sales, revenue, and growth. This web design guide for businesses covers everything you need to know to have an attention-grabbing and amazing website.
                    </span>
                </p>
            </div>

        </div>

        <div className='WebDesign-part-4'>
            <p>what is web design</p>
            <span>
                Web design is much more than the design of your website. It refers to the planning, arrangement, and organization of content on your website so that it is easily accessible to site visitors. It covers visual design that’s geared towards aesthetics and functional design that improves user experience throughout the visitor journey.<br/><br/>
                One of the key aspects of web design today is responsiveness. Responsive web design refers to creating dynamic websites that change based on the device and screen size of the users (e.g., mobile devices, mobile apps, tablets, desktops, etc.). This makes your website accessible across all types of devices and platforms. Here is an Example of a responsive web design:
            </span>
            <span className='WebDesign-part-4-itrate'>Here is an Example of a responsive web design:</span>
            <img src={img1} alt='img'/> 
            <span>The purpose of web design is to make a website user-friendly, accessible, and visually pleasing.</span>
        </div>

        <div className='WebDesign-part-5'>
            <Link>LEARN MORE<MdOutlineArrowForwardIos/></Link>
            <p>WEB DESIGN VS. WEB DEVELOPMENT</p>
            <span>
                Web design refers to the visual appearance, usability, and layout of your website. Website development is related to building and maintaining the core structure of the website.
            </span>
            <img src={icon2} alt='icon'/>
        </div>

        <div className='WebDesign-part-6'>

            <div>
                <p>Web design includes:</p>
                <span>1.
                    <span>
                        User experience (UX): It covers making the user experience simple and straightforward by making it easier for visitors/users to reach what they want.
                    </span>
                </span>
                <span>2.
                    <span>
                        User interface (UI): It refers to each individual page, making each interaction count, and increasing the usability of the website. It defines the way how a visitor interacts with your website.                    
                    </span>
                </span>
                <span>3.
                    <span>
                        The visual appearance of your website: It includes the overall design and visual appeal of the website and how it looks and feels. It covers UX and UI and much more.                    
                    </span>
                </span>

                <img src={img3} alt='img'/>

                <span>These tasks require advanced knowledge of HTML, CSS, designing, wireframing, prototyping, user journey mapping, web accessibility, design principles and theories, and design tools.</span>
            </div>


            <div>
                <p>Web design includes:</p>
                <span>1.
                    <span>
                    Front-end development: Also known as client-side development is the coding of visual elements of a website that are visible to the visitors. Front-end development is closely related to web design.                    
                    </span>
                </span>
                <span>2.
                    <span>
                    Back-end development: Also known as server-side development involves building and coding the backend of the website that’s not visible to the website visitors.                    
                    </span>
                </span>
                <span>3.
                    <span>
                        Full-stack development: It covers all the key aspects of web development including front-end and back-end.                    
                    </span>
                </span>

                <img src={img2} alt='img'/>

                <span>
                Web development requires knowledge of coding languages including PHP, JavaScript, CSS, HTML, and several others along with in-depth knowledge of database management, testing, debugging, server management, content management system .
                </span>
            </div>

        </div>

        <div className='WebDesign-part-7'>
            <p>Here are the key differences between web design and web development:</p>

            <div className='WebDesign-part-7-section'>
                <div>
                    <img src={icon5} alt='icon'/>
                    <p>Different goals</p>
                    <span>
                    Web design focuses on appearance, visuals, user interface design, and experience while the development of a website focuses on structure and foundation.
                    </span>
                </div>
                <div>
                    <img src={icon3} alt='icon'/>
                    <p>Work Method</p>
                    <span>
                    Web design is the starting point of website development where many web designers create a mockup based on an idea and then web development experts convert that idea into a website’s structure via coding.
                    </span>
                </div>
                <div>
                    <img src={icon4} alt='icon'/>
                    <p>Difficulty Levels</p>
                    <span>
                    Web designing is less technical while web development is a hardcore technical task.
                    </span>
                </div>
            </div>

            <span>
            Keeping differences aside, both web design and development work together towards the design and development of a professional website. Web designers and developers are key entities of the web design process and it’s hard to create a functional website without both.
            </span>
        
        </div>

        <div className='WebDesign-part-8'>
            <Link>LEARN MORE<MdOutlineArrowForwardIos/></Link>
            <p>Why Does Website Design Matter?</p>
            <span>Web design is much more than the visual appearance of your website. It matters for all the following reasons:</span>
        </div>

        <Footer/>

    </div>
  )
}

export default WebDesign