import React, { useEffect, useState } from 'react'
import Nav from '../NavBar/Nav'

import AOS from 'aos';
import 'aos/dist/aos.css';

import './OdooServices.css'
import { Link } from 'react-router-dom'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { MdOutlineArrowForward } from 'react-icons/md'
import { FaLinkedinIn} from "react-icons/fa";

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import heroImage from '../../assets/image 11.png'

import iosFintech from '../../assets/ios-fintech.png'
import iosREALeSTATE from '../../assets/ios-realestate.png'
import iosEcommerce from '../../assets/ios- e-commerce (1) 1.png'
import iosHealthCare from '../../assets/ios-healtcare.png'
import iosSupplyChain from '../../assets/ios-supply-chain 1.png'
import iosLogistics from '../../assets/ios-logistics 1.png'
import iosBankling from '../../assets/ios-banking 1.png'

import servicesImg1 from '../../assets/servceis-odoo 1.png'
import servicesImg2 from '../../assets/servceis-consultancy 1.png'
import servicesImg3 from '../../assets/servceis-customization 1.png'
import servicesImg4 from '../../assets/servceis-integration (1) 1.png'
import servicesImg5 from '../../assets/servceis-support 1.png'
import servicesImg6 from '../../assets/servceis-training 1.png'

import HireDevImg1 from '../../assets/hireodoo-Ellipse 1.png'
import HireDevImg2 from '../../assets/hireodoo-Ellipse 12.png'
import HireDevImg3 from '../../assets/hireodoo-Ellipse 2.png'
import HireDevImg4 from '../../assets/hireodoo-Ellipse 3.png'
import HireDevImg5 from '../../assets/hireodoo-Ellipse 4.png'

import Clutch from '../../assets/mobile-dev-securities-and-exchange-commission-of-pakistan-logo-7828A88801-seeklogo 2.png'
import ISO from '../../assets/mobile-dev-iso-2-1-logo-png-transparent 2.png'
import Pasha from '../../assets/P@SHA_Logo 2.png'
import PSeb from '../../assets/pseb-logo-1 2.png'
import priceImg1 from '../../assets/Tasklist.png'
import contactUs from '../../assets/contactus-mobile-dev.png'


import TestimonialImg from '../../assets/Ellipse 8.png'
import { CiLocationOn } from "react-icons/ci";
import ContactUs from '../ContactUs/ContactUs'
import Footer from '../Footer/Footer'
import Loader from '../Loader/Loader'
import axios from 'axios'

const OdooServices = () => {
  const [loading, setLoading] = useState(true);

  const [isScrolled, setIsScrolled] = useState(false);

  const [pricingTab, setPricingTab] = useState('Monthly')
  const [secondPricingTab, setSecondPricingTab] = useState('Monthly')

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const testimonials = [
    { id: 1, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
    { id: 2, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
    { id: 3, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
    { id: 4, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
    { id: 5, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
    { id: 6, img: TestimonialImg, author: 'Arslan Ahmed', position: "Co-founder, CEO", city: "Lahore", text: 'With 19 years of experience in both software development and management, Pavel leads Legion Soft to reach new heights and become a leading software engineering and digital transformation company.' },
  ];

  const handlePriceTabClick = (tab) => {
    setPricingTab(tab);
  }

  const handleSeconfPriceTabClick = (tab) => {
    setSecondPricingTab(tab);
  }

  const handleScroll = () => {
    if (window.scrollY > 230) {
        console.log("window.scrollY", window.scrollY)
        setIsScrolled(true);
    } else {
        setIsScrolled(false);
    }
  };

  useEffect(() => {
    const imagesToLoad = [
      heroImage,
      iosFintech,
      iosREALeSTATE,
      iosEcommerce,
      iosHealthCare,
      iosSupplyChain,
      iosLogistics,
      iosBankling,
      servicesImg1,
      servicesImg2,
      servicesImg3,
      servicesImg4,
      servicesImg5,
      servicesImg6,
      HireDevImg1,
      HireDevImg2,
      HireDevImg3,
      HireDevImg4,
      HireDevImg5,
      Clutch,
      ISO,
      Pasha,
      PSeb,
      contactUs,
      TestimonialImg,
      // ... (list all your image imports here)
    ];

  

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadImages = async () => {
      try {
        const promises = imagesToLoad.map((image) => loadImage(image));
        await Promise.all(promises);
        setLoading(false); // All images are loaded
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);

  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    AOS.init({ duration: 2000 }); 
    setLoading(false); 
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {loading ? <Loader/> : (

        <div>

          <div className='OdooServices-container'>

            <div className={`home-nav  ${isScrolled ? 'home-nav-scrolled' : ''} `}>
                <Nav/>
            </div>

            <div className='odoo-hero-container'>

              <div className='oddo-dev-wrapper'>

                  <div className='Bread-crum-odoo-dev'>
                    <Link to='/'>Home</Link><IoIosArrowRoundForward />
                    <p>Services</p><IoIosArrowRoundForward />
                    <Link className='active-bread-crum-current-page'>Mobile App Development</Link>
                  </div>

                  <div className='oddo-app-hero-headings'>
                    <p data-aos="fade-up">
                      Odoo is Your
                      <br/>
                      Right Choice
                    </p>
                    <span data-aos="fade-up">Save time, money and resources with cross-platform app development</span>
                    <Link to={"/contact"} data-aos="fade-up">
                          Contact Us Today
                          <MdOutlineArrowForward size={22}/>
                    </Link>
                  </div>

                  <div className='oddo-app-dev-hero-footer' data-aos="fade-up">
                    <div>
                      <p>400+</p>
                      <span>Projects</span>
                    </div>
                    <div>
                      <p>300+</p>
                      <span>Mobile Tech Experts</span>
                    </div>
                    <div>
                      <p>4.9/5</p>
                      <span>On Clutch</span>
                    </div>
                  </div>

              </div>

              <img src={heroImage} alt='abc' className='odoo-hero-img'/>
            </div>

          </div>

          <div className='odooServices-services-container'>
              <p  data-aos="fade-up">
                Our <span>Services</span> in Odoo Development
              </p>

              <div className='odooServices-services-wrapper'>

                <div  data-aos="fade-up">
                  <img src={servicesImg1} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>
                <div  data-aos="fade-up">
                  <img src={servicesImg3} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>

                <div  data-aos="fade-up">
                  <img src={servicesImg6} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>

                <div  data-aos="fade-up">
                  <img src={servicesImg5} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>

                <div  data-aos="fade-up">
                  <img src={servicesImg4} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>

                <div  data-aos="fade-up">
                  <img src={servicesImg2} alt='abc'/>
                  <p>Odoo Implementation</p>
                  <span>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</span>
                </div>

              </div>
          </div>


          <div className='odooServices-hireodoo'>
            <p  data-aos="fade-up">Hire Odoo Developers</p>
            <div className='odooServices-hireodoo-container'>
                <div className='odooServices-hireodoo-wrapper'  data-aos="fade-up">
                  <div>
                    <img src={HireDevImg1} alt='abc'/>
                    <p>Roman Bondarenko</p>
                    <span>CEO</span>
                    <Link><FaLinkedinIn/></Link>
                  </div>
                  <div>
                    <img src={HireDevImg2} alt='abc'/>
                    <p>Roman Bondarenko</p>
                    <span>CEO</span>
                    <Link><FaLinkedinIn/></Link>
                  </div>
                  <div>
                    <img src={HireDevImg3} alt='abc'/>
                    <p>Roman Bondarenko</p>
                    <span>CEO</span>
                    <Link><FaLinkedinIn/></Link>
                  </div>
                  <div>
                    <img src={HireDevImg4} alt='abc'/>
                    <p>Roman Bondarenko</p>
                    <span>CEO</span>
                    <Link><FaLinkedinIn/></Link>
                  </div>
                  <div>
                    <img src={HireDevImg5} alt='abc'/>
                    <p>Roman Bondarenko</p>
                    <span>CEO</span>
                    <Link><FaLinkedinIn/></Link>
                  </div>
                </div>
                <Link to={"/talk-to-us"}  data-aos="fade-up">Request A Developer<MdOutlineArrowForward/></Link>
            </div>
          </div>

        

          {/* pricing  */}
          <div className='odooServceis-pricing'>
            <div className='odooServceis-pricing-section-1'>
              <p>Pricing</p>
              <span>
                For precise Odoo pricing tailored to your business requirements, we advise reaching out to our dedicated team at Legions Soft. Costs may differ based on location and specific business needs.
              </span>
              <div className='odooServceis-pricing-section-1-tabs-button'>
                <button 
                  className={pricingTab === 'Monthly' ? 'odooServceis-pricing-section-1-tabs-button-active' : 'odooServceis-pricing-section-1-tabs-button-inactive'}
                  onClick={() => handlePriceTabClick('Monthly')}
                >Monthly</button>
                <button 
                  className={pricingTab === 'Yearly' ? 'odooServceis-pricing-section-1-tabs-button-active' : 'odooServceis-pricing-section-1-tabs-button-inactive'}
                  onClick={() => handlePriceTabClick('Yearly')}
                >Yearly</button>
              </div>
            </div>
            {pricingTab === 'Monthly' && 
              <div className='odooServceis-prices-tabs-container'>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>One App</p>
                    For one app only, unlimited users Odoo Online
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>All Apps</p>
                    For one app only, unlimited users Odoo Online
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>All Apps</p>
                    Odoo Studio
                    Multi-Company
                    External API
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
              </div>
            }
            {pricingTab === 'Yearly' && 
              <div className='odooServceis-prices-tabs-container'>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>One App</p>
                    For one app only, unlimited users Odoo Online
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>All Apps</p>
                    For one app only, unlimited users Odoo Online
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
                <div>
                  <p>One App Free</p>
                  <div>
                    <p>Tier 1 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 2 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <div>
                    <p>Tier 3 countries</p>
                    <span>$ 0/user/month</span>
                  </div>
                  <span>
                    <p>All Apps</p>
                    Odoo Studio
                    Multi-Company
                    External API
                  </span>
                  <Link>WhatsApp</Link>
                  <Link>Chat Now</Link>
                </div>
              </div>
            }
          </div>

          {/* pricing 2 */}
          <div className='odooServceis-prices-Odoosh'>
                <div className='odooServceis-prices-Odoosh-section-1'>
                  <p>Odoo.sh Server Pricing</p>
                  <span>
                    For accurate Odoo.sh server pricing aligned with your business needs, we suggest reaching out to our Odoo team at Legions Soft. Costs may vary depending on your country and specific requirements.
                  </span>
                </div>
                <div className='odooServceis-prices-Odoosh-content'>
                  <p className='odooServceis-prices-Odoosh-content-header'>
                    <img src={priceImg1} alt='priceImg1'/>
                    Plan
                  </p>
                  <div className='odooServceis-prices-Odoosh-content-tab-buttons'>
                    <button 
                      className={secondPricingTab === 'Monthly' ? 'odooServceis-prices-Odoosh-content-tab-button-active' : 'odooServceis-prices-Odoosh-content-tab-button-inactive'}
                      onClick={() => handleSeconfPriceTabClick('Monthly')}
                    >Monthly</button>
                    <button 
                      className={secondPricingTab === 'Yearly' ? 'odooServceis-prices-Odoosh-content-tab-button-active' : 'odooServceis-prices-Odoosh-content-tab-button-inactive'}
                      onClick={() => handleSeconfPriceTabClick('Yearly')}
                    >Yearly</button>
                  </div>


                   {secondPricingTab === 'Monthly' && 
                    <div className='odooServceis-prices-Odoosh-content-tab-content'>

                      <div>
                        <p>Workers</p>
                        <input value="1" disabled/>
                        <span>$72.00/worker/month</span>
                      </div>

                      <div>
                        <p>Storage</p>
                        <input value="1" disabled/>
                        <span>$0.25/GB/month</span>
                      </div>

                      <div>
                        <p>Staging Environment</p>
                        <input value="1" disabled/>
                        <span>$18.00/environment/month</span>
                      </div>

                    </div>
                   }

                   {secondPricingTab === 'Yearly' && 
                    <div className='odooServceis-prices-Odoosh-content-tab-content'>

                      <div>
                        <p>Workers</p>
                        <input value="1" disabled/>
                        <span>$72.00/worker/month</span>
                      </div>

                      <div>
                        <p>Storage</p>
                        <input value="1" disabled/>
                        <span>$0.25/GB/month</span>
                      </div>

                      <div>
                        <p>Staging Environment</p>
                        <input value="1" disabled/>
                        <span>$18.00/environment/month</span>
                      </div>

                    </div>
                   }

                   {secondPricingTab === 'Monthly' && 
                    <div className='odooServceis-prices-Odoosh-content-tab-content-total-amount'>
                      <div>
                        <p>$72.25</p>
                        <span>/month</span>
                      </div>
                      <p>$867.00/year</p>
                    </div>
                   }

                   {secondPricingTab === 'Yearly' && 
                    <div className='odooServceis-prices-Odoosh-content-tab-content-total-amount'>
                      <div>
                        <p>$72.25</p>
                        <span>/month</span>
                      </div>
                      <p>$867.00/year</p>
                    </div>
                   }

                   <div className='odooServceis-prices-Odoosh-content-tab-button'>
                    <button>WhatsApp</button>
                   </div>

                </div>
          </div>
          
          {/* success-pack */}
          <div className='odooServceis-success-pack'>
              <div className='odooServceis-success-pack-header'>
                <p>Odoo</p>
                <span>Success Pack</span>
              </div>
              <div className='dooServceis-success-pack-content'>

                <div>
                  <span className='dooServceis-success-pack-1'></span>
                  <div className='dooServceis-success-pack-pkg'>
                    <p>Basic</p>
                    <span>$900</span>
                  </div>
                  <div className='dooServceis-success-pack-pkg-pirce'>
                    <p>Duration</p>
                    <span>25 Hours</span>
                  </div>
                  <div className='dooServceis-success-pack-button-1'>
                    <Link>Get Started</Link>
                  </div>
                </div>
                
                <div>
                  <span className='dooServceis-success-pack-2'></span>
                  <div className='dooServceis-success-pack-pkg'>
                    <p>Basic</p>
                    <span>$900</span>
                  </div>
                  <div className='dooServceis-success-pack-pkg-pirce'>
                    <p>Duration</p>
                    <span>25 Hours</span>
                  </div>
                  <div className='dooServceis-success-pack-button-2'>
                    <Link>Get Started</Link>
                  </div>
                </div>

                <div>
                  <span className='dooServceis-success-pack-3'></span>
                  <div className='dooServceis-success-pack-pkg'>
                    <p>Basic</p>
                    <span>$900</span>
                  </div>
                  <div className='dooServceis-success-pack-pkg-pirce'>
                    <p>Duration</p>
                    <span>25 Hours</span>
                  </div>
                  <div className='dooServceis-success-pack-button-3'>
                    <Link>Get Started</Link>
                  </div>
                </div>

                <div>
                  <span className='dooServceis-success-pack-4'></span>
                  <div className='dooServceis-success-pack-pkg'>
                    <p>Basic</p>
                    <span>$900</span>
                  </div>
                  <div className='dooServceis-success-pack-pkg-pirce'>
                    <p>Duration</p>
                    <span>25 Hours</span>
                  </div>
                  <div className='dooServceis-success-pack-button-4'>
                    <Link>Get Started</Link>
                  </div>
                </div>


              </div>
              <div className='odooServceis-success-pack-footer'>
                <p>What is an Odoo success pack?</p>
                <span>
                  The Odoo Success Pack, provided by Legionsoft, is a premium offering designed to facilitate the seamless implementation and optimization of Odoo ERP systems. It encompasses a wide range of services and resources, including user training, development, customization, integration, technical support, and ongoing maintenance.
                </span>
              </div>
              <div className='odooServceis-success-pack-footer'>
                <p>Benefits from Legion Soft</p>
                <span>
                At Legionsoft, our Success Pack includes the expertise of dedicated Functional and Techno Functional consultants who work closely with your team to understand your business requirements and tailor the Odoo applications accordingly. A designated success coach guides you through the implementation process, offering valuable advice and ensuring that the Odoo solution aligns perfectly with your business objectives.
                </span>
              </div>
          </div>

          {/* ios dev solution section */}
          <div className='OdooServices-ios-dev-section'>
            <p  data-aos="fade-up">
              Our iOS Development Solutions
              <br/>
              For Different
              <span> Industries</span> 
            </p>
            <div className='OdooServices-ios-dev-section-items'  data-aos="fade-up">
              <div>
                <img src={iosFintech} alt='abc'/>
                <p>Fintech</p>
              </div>
              <div>
                <img src={iosREALeSTATE} alt='abc'/>
                <p>Real Estate</p>
              </div>
              <div>
                <img src={iosEcommerce} alt='abc'/>
                <p>E-Commerce</p>
              </div>
              <div>
                <img src={iosHealthCare} alt='abc'/>
                <p>Healthcare</p>
              </div>
              <div>
                <img src={iosSupplyChain} alt='abc'/>
                <p>Supply Chain</p>
              </div>
              <div>
                <img src={iosLogistics} alt='abc'/>
                <p>Logistics</p>
              </div>
              <div>
                <img src={iosBankling} alt='abc'/>
                <p>Banking</p>
              </div>
            </div>
          </div>


          {/* testimonial section section */}
          <div className='OdooServices-testimonial-section'>
                <div className='OdooServices-testimonial-section-heading' data-aos="fade-up">
                  <p>Testimonials</p>
                  <span>We are proud of the great projects Andersen's developers have taken part in. Here is what our customers say about our expertise in App Development.</span>
                </div>
              <div className='OdooServices-testimonial-section-container'  data-aos="fade-up">
                <Slider {...settings}>
                  {testimonials.map(testimonial => (
                    <div key={testimonial.id} className='OdooServices-testimonial-section-wrapper'>
                      <div className='OdooServices-testimonial-section-card-user-info'>
                        <img src={testimonial.img} alt="client"/>
                        <div>
                          <p>{testimonial.author}</p>
                          <span>{testimonial.position}</span>
                          <button><CiLocationOn/>{testimonial.city}</button>
                        </div>
                      </div>
                      <div className='OdooServices-testimonial-section-card-user-review'>
                        <p>{testimonial.text}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
          </div>

          {/* top rated section */}
          <div className='OdooServices-top-rated-section'>
              <p  data-aos="fade-up">Top-rated mobile development company</p>
              <div  data-aos="fade-up">
                <img src={Clutch} alt='abc'/>
                <img src={ISO} alt='abc'/>
                <img src={Pasha} alt='abc'/>
                <img src={PSeb} alt='abc'/>
              </div>
          </div>

          {/* contactus section */}
          <ContactUs/>

          <Footer/>
        </div>
      )}
    </>
  )
}

export default OdooServices