import React from 'react'
import './Marketing.css'
import Nav from '../NavBar/Nav'
import img1 from '../../assets/icons/Down Button.png'
import img2 from '../../assets/icons/Courage Symbol.png'
import img3 from '../../assets/icons/Gmail Login.png'
import img4 from '../../assets/icons/Sound.png'
import img5 from '../../assets/icons/Web.png'
import img6 from '../../assets/Rectangle 535.png';
import img7 from '../../assets/Rectangle 536.png'
import img8 from '../../assets/Rectangle 537.png'
import img9 from '../../assets/Rectangle 538.png'
import Footer from '../Footer/Footer'


import { Link } from 'react-router-dom'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Marketing = () => {
  return (
    <div className='Marketing-container'>

        <Nav/>

        <div className='Marketing-part-1'>
            <p>LOOKING FOR DIGITAL MARKETING? <br/> WE'RE HERE ALL DAY</p>
        </div>

        <div className='Marketing-part-2'>
            <p>Whether you are in the market to design a new website, redesign your current website, add or enhance your ecommerce shopping cart, build or expand your content marketing, or create or manage your social media brand, our experts can consult with you, strategize the right plan and execute it perfectly, at a price that may surprise you.</p>

            <img src={img1} alt='img'/>


            <div className='Marketing-part-2-container'>
                <div>
                    <img src={img5} alt='img'/> 
                    <p>Custom Website Design</p>
                    <span>What is the first thing you look at when you visit a website? Do you start reading content right away or look at the design? Science says that it only takes 50 milliseconds for a person to form an opinion about a website.</span>
                </div>
                <div>
                    <img src={img4} alt='img'/> 
                    <p>Social Media Marketing</p>
                    <span>What is the first thing you look at when you visit a website? Do you start reading content right away or look at the design? Science says that it only takes 50 milliseconds for a person to form an opinion about a website.</span>
                </div>
                <div>
                    <img src={img3} alt='img'/> 
                    <p>Email Marketing</p>
                    <span>What is the first thing you look at when you visit a website? Do you start reading content right away or look at the design? Science says that it only takes 50 milliseconds for a person to form an opinion about a website.</span>
                </div>
                <div>
                    <img src={img2} alt='img'/> 
                    <p>Graphic Designing Marketing</p>
                    <span>What is the first thing you look at when you visit a website? Do you start reading content right away or look at the design? Science says that it only takes 50 milliseconds for a person to form an opinion about a website.</span>
                </div>
            </div>

            <Link>GET IN TOUCH <MdOutlineKeyboardArrowRight size={30}/></Link>

        </div>

        <div className='Marketing-part-3'>
            <p>LATEST PROJECTS</p>
            
            <div>
                <img src={img6} alt='abc'/>
                <img src={img7} alt='abc'/>
                <img src={img8} alt='abc'/>
                <img src={img9} alt='abc'/>
            </div>

        </div>

        <Footer/>

    </div>
  )
}

export default Marketing