import React from 'react'
import './WebDevelopment.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import { MdOutlineArrowForward } from 'react-icons/md'
import img1 from '../../assets/image 16.png'
import img2 from '../../assets/LEGION SOFT (92) 1.png'
import img3 from '../../assets/LEGION SOFT (93) 1.png'
import img4 from '../../assets/image 17.png'
import img5 from '../../assets/image 18.png'
import img6 from '../../assets/image 19.png'
import img7 from '../../assets/image 20.png'
import Footer from '../Footer/Footer'

const WebDevelopment = () => {
  return (
    <div className='WebDevelopment'>

      <Nav/>

      <div className='WebDevelopment-part-1'>
        <p>web development</p>
          <span>Simplify your web development journey and save valuable time, money, and resources with our cross-platform solution.</span>
          <Link to={'/contact'}>
              Conatct Us Today
              <MdOutlineArrowForward size={22}/>
          </Link>
      </div>

      <div className='WebDevelopment-part-2'>
        <div>
          <p>User-Friendly Web Solutions</p>
          <span>
            User-Friendly Web Solutions
            <br/>
            We're here to make your business operations run seamlessly, 
            fast, and efficiently. From creating web applications to 
            building MVPs, portals, websites, and custom software, 
            we've got the expertise to handle it all.
            <br/>
            <br/>
            Our team of experts is well-versed in a wide range of 
            technologies, including PHP, .Net, WordPress, Magento, 
            JavaScript, AJAX, JQuery, React, Mongo DB, Agular.JS, 
            Node.js, and Ruby on Rails, among others.
          </span>
          <img src={img1} alt='img'/>
        </div>
      </div>

      <div className='WebDevelopment-part-3'>
        <p>CUSTOM WEB DEVELOPMENT</p>
        <span>
          For years, we've been the go-to choice for entrepreneurs, 
          SMBs, and corporate giants alike, helping them thrive online. 
          Our dedicated developers are always up-to-date with the latest 
          tech trends, ensuring your web applications are nothing short 
          of exceptional.
        </span>
        <img src={img2} alt='img'/>
      </div>

      <div className='WebDevelopment-part-4'>
        <p>E-COMMERCE SOLUTION</p>
        <span>We're always ahead of the game, making sure our e-commerce solutions cater to every step of the modern shopping journey.</span>
        <img src={img3} alt='img'/>
      </div>


      <div className='WebDevelopment-part-5'>
        <p>SHOPPING CART</p>
        <span>
          We're here to make your online retail dreams a reality 
          with our customised shopping cart solutions. Our team puts 
          their heart into crafting online storefront designs that 
          perfectly match your vision, using the latest tech to ensure 
          your success.
        </span>
        <img src={img4} alt='img'/>
      </div>

      <div className='WebDevelopment-part-6'>
        <p>Our Latest Projects</p>
        <img src={img5} alt='img'/>
        <Link>VIEW PORTFOLIO</Link>
      </div>

      <div className='WebDevelopment-part-7'>
        <div>
          <img src={img6} alt='img'/>
          <p>MOBILE APP</p>
          <span>Our desktop software solutions are built to be powerful, flexible, and personalized, ensuring reliability for Windows-based PCs and tablets.</span>
        </div>
        <div>
          <img src={img7} alt='img'/>
          <p>DESKTOP</p>
          <span>Revolutionising Business Solutions by Crafting the Perfect Recipe for Mobile App success.</span>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default WebDevelopment