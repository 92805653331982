import React from 'react'
import Nav from '../NavBar/Nav'
import './OdooEmbroidery.css'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import img1 from '../../OdooAssets/OdooAssets2/Group 229.png'
import icon1 from '../../OdooAssets/Icons/Checkmark.png'
import img2 from '../../OdooAssets/OdooAssets2/Screenshot from 2024-04-23 11-12-32 2.png'
import img3 from '../../OdooAssets/OdooAssets2/snap_22 2.png'
import img4 from '../../OdooAssets/OdooAssets2/Screenshot from 2024-04-23 11-12-32 3.png'
import img5 from '../../OdooAssets/OdooAssets2/Screenshot from 2024-04-23 15-12-14 2.png'
import img6 from '../../OdooAssets/OdooAssets2/Screenshot from 2024-04-23 15-12-14 3.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const OdooEmbroidery = () => {
  return (
    <div className='OdooEmbroidery'>

      <NavScrolled/>


      <div className='OdooEmbroidery-header'>
        <p>ERP for Embroidery Industry</p>
        <span>Streamline embroidery operations with tailored ERP solutions</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooEmbroidery-part1'>
        <h6>Embroidery Management System</h6>
        <p>Embroidery is a versatile craft that involves decorating fabric or other materials with needle and thread. It is used to create intricate designs, patterns, and images, adding beauty and detail to various items such as clothing, accessories, home decor, and more.</p>
        <span></span>
      </div>


      <div className='OdooEmbroidery-part2'>
        <p>Odoo Embroidery Management</p>
        <img src={img1} alt='Service'/>
      </div>

      <div className='OdooEmbroidery-part3'>
        <p>Embroidery System</p>
        <span>Embroidery  Management system These systems are designed to streamline the management of  Manufacturing Order system  Scrap Orders system Manage Products  Manage Product Variants  Create Billing of Materials . </span>
        <div className='OdooEmbroidery-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Complete embroidery solution </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Manufacturing Order system  </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Scrap Orders system </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Complete Reporting</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Manage Products</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Manage Product Variants</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Create Billing of Materials</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Embroidery Reporting</p>
            </div>
          </div>

        </div>
        <img src={img2} alt='img2'/>
      </div>

      <div className='OdooEmbroidery-part4'>
        <p>Embroidery Stitching</p>
        <span>Odoo Embroidery  Management System will help you to manage your Stitching Product with General Information</span>
        <div className='OdooEmbroidery-part4-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Stitching Order Management </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Material Management </p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Comprehensive Task Management System</p>
            </div> */}
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Stitching Pattern Management </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Production Planning and Scheduling </p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Efficient Employee Allocation Strategies</p>
            </div> */}
          </div>

        </div>
        <img src={img2} alt='img2'/>
        <img src={img3} alt='img3'/>
      </div>


      <div className='OdooEmbroidery-part3'>
        <p>Material Billing Management </p>
        <span>Manage the operations of Billings with billing system</span>
        <div className='OdooEmbroidery-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Complete Billing solution  </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Components Billing   </p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Scrap Orders system </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Complete Reporting</p>
            </div> */}
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Orders Billing </p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Component rate adjustments</p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Create Billing of Materials</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Embroidery Reporting</p>
            </div> */}
          </div>

        </div>
        <img src={img4} alt='img2'/>
      </div>

      <div className='OdooEmbroidery-part4'>
        <p>Embroidery Manufacturing Reporting</p>
        {/* <span>Odoo Embroidery  Management System will help you to manage your Stitching Product with General Information</span> */}
        <div className='OdooEmbroidery-part4-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Complete reporting system</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Day, Month, Year Reporting</p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Comprehensive Task Management System</p>
            </div> */}
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Create report for employees, customer staging</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Component rate adjustments</p>
            </div>
            {/* <div>
              <img src={icon1} alt='icon1'/>
              <p>Efficient Employee Allocation Strategies</p>
            </div> */}
          </div>

        </div>
        <img src={img5} alt='img2'/>
        <img src={img6} alt='img3'/>
      </div>

      <div className='OdooERPService-part5'>
        <div>
          <div></div>
          <p>Why choose Legions Soft?</p>
          <span>Crafting an excellent service management solution can only be done by experts, professionals and developers who have high amounts of expertise in it.Odoo experts who are well capable of crafting exceptional service management solutions for your business.</span>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooEmbroidery