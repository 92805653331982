import FeaturedCasesImg from '../../assets/featuredcases img.png'
import FeaturedcasesBodyImg from '../../assets/Pure-Plank-01-caa33339cb2db17d3ff98c6f654db80d.webp.png'

export const featuredCases = [
    {
        imgSrc: FeaturedCasesImg,
        title: 'The Idea Behind Pure Plank',
        description: (
            <>
                Plank is an online platform for fitness and exercise enthusiasts aiming to look after their health and exercise at home. With video tutorials and challenges, this platform brings a new idea to life. <br /><br />
                TekRevol has developed several digital solutions, including mobile apps, pertaining to the fitness and healthcare sector. <br /><br />
                The Plank app is a complete solution surrounding the planking exercise where users get all the functions and features in one place to get the ideal results. The app also had video tutorials and instructions for the users, as well as the option to buy accessories with complete payment integration options.
            </>
        ),
        bodyImgSrc: FeaturedcasesBodyImg, 
    },
    {
        imgSrc: FeaturedCasesImg,
        title: 'Case Title 2',
        description: (
            <>
                Description for case 2. This can be another project or feature that your company has developed. <br />
                Additional information about case 2.
            </>
        ),
        bodyImgSrc: FeaturedcasesBodyImg, 
    },
    {
        imgSrc: FeaturedCasesImg, 
        title: 'Case Title 3',
        description: (
            <>
                Description for case 3. This can be another project or feature that your company has developed. <br />
                Additional information about case 3.
            </>
        ),
        bodyImgSrc: FeaturedcasesBodyImg, 
    },
];