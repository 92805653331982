import React from 'react'

import './OdooERPManufacturing.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import manufacturingindustries from '../../OdooAssets/OdooSubServicesAssets/manufacturing-industries-infographic 1.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const OdooERPManufacturing = () => {
  return (
    <div className='OdooERPManufacturing'>

      <NavScrolled/>

      <div className='OdooERPManufacturing-header'>
        <p>Odoo ERP for Manufacturing</p>
        <span>The ultimate production control tool</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>


      <div className='OdooERPManufacturing-part1'>
        <p>Manufacturing, a form of commodity production which was established right in the ice ages, has evolved out to be the one that we now know as the production aspect involving automation. The production management tools should be able to function with numerous constraints and control centers of operation.</p>
        <span></span>
      </div>

      <div className='OdooERPManufacturing-part2'>
        <p>Manufacturing is not only about production</p>
        <span>Odoo with the dedicated modular infrastructure has a well-defined Manufacturing management module providing you complete control and 360-degree visibility of the various stages of the manufacturing process. Odoo holds dedicated operational tools which will be helpful in the planning, execution of the production process, running quality check and sending it out to warehouses or customers.</span>
        <img src={manufacturingindustries} alt='manufacturingindustries'/>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooERPManufacturing