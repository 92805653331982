import React from 'react'
import './AppSupport.css'
import Nav from '../NavBar/Nav'
import img1 from '../../App Assets/All-Apps-1 1.png'
import img2 from '../../App Assets/User-Experience 1.png'
import img3 from '../../App Assets/Group.png'
import img4 from '../../App Assets/image 22.png'
import img5 from '../../App Assets/image 23.png'
import img6 from '../../App Assets/Group 465.png'
import img7 from '../../App Assets/image 24.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const AppSupport = () => {
  return (
    <div className='AppSupport'>

        <NavScrolled/>


        <div className='AppSupport-part-1'>
            <p>App Support</p>
            <span>
                Need assistance with your app? Our dedicated support 
                team is here to help you every step of the way, ensuring 
                smooth sailing for your users.
            </span>
        </div>

        <div className='AppSupport-part-2'>
            <p>Unlock Unlimited Android App Access</p>
            <span>
                Enjoy limitless access to all Android apps, regardless 
                of your embedded Linux-based platform. Whether you're 
                in automotive infotainment, industrial applications, 
                embedded systems, or mobile platforms, our solution 
                fits seamlessly into your needs. With millions of 
                deployments since 2013, it's primed to elevate your 
                platform with effortless app integration.
            </span>
        </div>

        <div className='AppSupport-part-3'>

            <div className='AppSupport-part-3-section-1'>
                <p>All apps</p>
                <div></div>
                <strong>Empower Your Customers with Limitless App Choices</strong>
                <span>
                    Give your customers the freedom to explore the full Android app catalogue 
                    while keeping your brand identity intact and retaining control over the 
                    digital cockpit journey.
                    <br/>
                    <br/>
                    AppSupport goes beyond just Android Automotive or OEM-specific apps.
                    <br/>
                    <br/>
                    It opens doors to a vast array of apps spanning Android Automotive, 
                    Mobile, Tablet, and TV categories, ensuring a richer user experience 
                    across all platforms
                </span>
            </div>    
            <img src={img1} alt='img'/>
        </div>

        <div className='AppSupport-part-4'>
            <img src={img2} alt='img'/>
            <div className='AppSupport-part-4-section-1'>
                <p>User experience</p>
                <div></div>
                <strong>Seamless Integration for Enhanced OEM Experience</strong>
                <span>
                    Get ready to innovate and shape your digital cockpit without 
                    limitations. AppSupport empowers you to design freely, 
                    fostering new value chains without being constrained by 
                    third-party platform rules.
                    <br/>
                    <br/>
                    AIntegrate apps seamlessly into your distinct user experience design, 
                    ensuring a cohesive brand identity.
                    <br/>
                    <br/>
                    AppSupport ensures full compatibility with your vehicle's native 
                    environment and critical safety functions like Driver Distraction, 
                    all without any need to tweak the applications themselves.
                </span>
            </div>    
        </div>

        <div className='AppSupport-part-3'>

            <div className='AppSupport-part-3-section-1'>
                <p>Life Cycle</p>
                <div></div>
                <strong>Extend the Life of Your Vehicle's Infotainment System</strong>
                <span>
                    Imagine giving your car's infotainment system a new lease on life. 
                    That's exactly what we do with our technology. By offering the latest 
                    consumer apps to vehicles already on the road, we help you get more 
                    out of your investment.
                    <br/>
                    <br/>
                    Our AppSupport technology is like a breath of fresh air for your car. 
                    It can be upgraded independently over-the-air, without touching the HostOS. 
                    This means your infotainment system can stay up-to-date with the latest 
                    Android APIs, ensuring it remains relevant for years to come.
                    <br/>
                    <br/>
                    And here's the best part: even older vehicle models can enjoy the perks. 
                    With our solution, you can offer the latest apps and maintain brand 
                    consistency across your entire fleet, regardless of age. It's like 
                    giving your car a timeless upgrade.
                </span>
            </div>    
            <img src={img3} alt='img'/>
        </div>

        <div className='AppSupport-part-4'>
            <img src={img6} alt='img'/>
            <div className='AppSupport-part-4-section-1'>
                <p>Security</p>
                <div></div>
                <strong>Your Security Matters</strong>
                <span>
                    When it comes to your peace of mind, security is paramount. That's why 
                    our AppSupport architecture is built to keep you safe and isolated from 
                    any potential risks in the HostOS.
                    <br/>
                    <br/>
                    It operates independently, like a fortress guarding your system, 
                    with just a few standardized integration points needed to ensure it 
                    runs smoothly on any Linux-based OS.
                    <br/>
                    <br/>
                    We're all about transparency and control. Only the necessary APIs 
                    for your IVI system are exposed, with the HostOS taking care of all 
                    interactions with the AppSupport environment.
                    <br/>
                    <br/>
                    And when it comes to updates, we've got you covered. With full OTA 
                    support, you can quickly receive the latest security patches, 
                    keeping your system safe and sound, no matter what the HostOS 
                    is up to.
                </span>
            </div>    
        </div>

        <div className='AppSupport-part-3'>

            <div className='AppSupport-part-3-section-1'>
                <p>Performance</p>
                <div></div>
                <strong>Experience Exceptional Performance</strong>
                <span>
                    Get ready for a smooth ride with our solution, where security is a top priority without sacrificing speed.
                    <br/>
                    <br/>
                    You'll enjoy flawless operation, with no lags and impeccable frame rates, 
                    all while using minimal memory. It's like having the performance of an 
                    Android OS-based solution right at your fingertips.
                    <br/>
                    <br/>
                    And here's the kicker: our unique memory allocation not 
                    only boosts performance but also saves costs for OEMs. Plus, 
                    our platform's app performance and user experience are on par 
                    with native Android solutions.
                </span>
            </div>    
            <img src={img4} alt='img'/>
        </div>

        <div className='AppSupport-part-4'>
            <img src={img5} alt='img'/>
            <div className='AppSupport-part-4-section-1'>
                <p>App stores</p>
                <div></div>
                <strong>Your Own OEM-branded App Stores</strong>
                <span>
                    With AppSupport, you can craft your very own OEM-branded App Stores 
                    effortlessly. Collaborate with trusted partners to access a wide 
                    array of pre-screened apps, perfectly suited for infotainment systems.
                    <br/>
                    <br/>
                    Say goodbye to the hassle of rewriting apps for your OS. 
                    Our platform offers a rich selection of existing, quality-tested, 
                    and automotive-adapted apps, ensuring a seamless experience for 
                    your users.
                    <br/>
                    <br/>
                    Take charge of your brand's journey by deploying your unique OEM-branded 
                    App Store, putting you in complete control of the value chain.
                </span>
            </div>    
        </div>

        <div className='AppSupport-part-5'>
            <img src={img7} alt='img'/>
        </div>
        <Footer/>
    </div>
  )
}

export default AppSupport