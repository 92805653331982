import React, { useState } from 'react'
import './ContactUs.css'
import axios from 'axios'

const ContactUs = () => {


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [budget, setBudget] = useState('');
    const [desc, setDesc] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        const formData = {
            name: name,
            email: email,
            company: company,
            phone: phone,
            subject: subject,
            budget: budget,
            desc: desc,
        };

        console.log("formData", formData)

        try {
            
        
            const res = await axios.post('http://localhost:3000/contact_form_handler.php', {
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData),
            })
            console.log('res', res)
        } catch (error) {
            console.log(error)       
        }

    };


  return (
    <div className='ContactUs-general'>

        <div className='ContactUs-general-heading'>
            <p>Contact us!</p>
            <span>Book a call or fill out the form below and we’ll get back to you once we’ve processed your request.</span>
        </div>

        <div className='ContactUs-general-wrapper'> 

            <form className='ContactUs-general-form' onSubmit={handleSubmit}>
                <div className='ContactUs-general-form-input-type-1'>
                    <input type='text' placeholder='Name*' required value={name} onChange={(e) => setName(e.target.value)}/>
                    <input type='text' placeholder='Company*' required value={company} onChange={(e) => setCompany(e.target.value)}/>
                </div>
                <div className='ContactUs-general-form-input-type-1'>
                    <input type='email' placeholder='Corporate email*' required value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <input type='number' placeholder='Phone*' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className='ContactUs-general-form-input-type-2'>
                    <select required value={subject} onChange={(e) => setSubject(e.target.value)}>
                        <option selected disabled>Subject*</option> 
                        <option>IT staff augmentation</option>
                        <option>Turnkey product development</option>
                        <option>Support and enhancement</option>
                        <option>Careers</option>
                        <option>Others</option>
                    </select>
                    <select required value={budget} onChange={(e) => setBudget(e.target.value)}>
                        <option selected disabled>Project budget (min.$15k)*</option> 
                        <option>$15K-$30K</option>
                        <option>$30K-$100K</option>
                        <option>$100K-$250K</option>
                        <option>$250K-$500K</option>
                        <option>More than $500K</option>
                    </select>
                </div>
                <div className='ContactUs-general-form-input-type-3' value={desc} onChange={(e) => setDesc(e.target.value)}>
                    <textarea placeholder='Describe your needs in detail*'/>
                </div>
                <p>Please include project details, duration, tech stack, IT professionals needed, and other relevant info</p>
                <div className='ContactUs-general-form-input-type-4'>
                    <p>Attach additional documents as needed</p>
                    <label htmlFor='filehandle'>Upload file</label>
                    <input type='file' id='filehandle'/>
                </div>
                <div className='ContactUs-general-form-submit'>
                    <p>Please be informed that when you click the Send button Innowise will process your personal data in accordance with our Privacy Policy for the purpose of providing you with appropriate information.</p>
                    <button type='submit'>Send</button>
                </div>
            </form>

            <div className='ContactUs-general-what-happened'>
                <p>What happens next?</p>
                <div className="steps-list">
                    <div className="step-item">
                        <div className="step-number">1</div>
                        <p>Having received and processed your request, we will get back to you shortly to detail your project needs and sign an NDA to ensure the confidentiality of information.</p>
                    </div>
                    <div className="step-item">
                        <div className="step-number">2</div>
                        <p>After examining requirements, our analysts and developers devise a project proposal with the scope of works, team size, time and cost estimates.</p>
                    </div>
                    <div className="step-item">
                        <div className="step-number">3</div>
                        <p>We arrange a meeting with you to discuss the offer and come to an agreement.</p>
                    </div>
                    <div className="step-item">
                        <div className="step-number">4</div>
                        <p>We sign a contract and start working on your project as quickly as possible.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
  )
}

export default ContactUs