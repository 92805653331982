import React, { useEffect, useState } from 'react'
import Nav from './Nav';

const NavScrolled = () => {

    const [isScrolled, setIsScrolled] = useState(false);


    const handleScroll = () => {
        if (window.scrollY > 230) {
            console.log("window.scrollY", window.scrollY)
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

  return (
    <div className={`home-nav  ${isScrolled ? 'home-nav-scrolled' : ''} `}>
        <Nav/>
    </div>
  )
}

export default NavScrolled