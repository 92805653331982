import React from 'react'

import './OdooERPService.css'
import Nav from '../NavBar/Nav'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import ImgPart2 from '../../OdooAssets/OdooSubServicesAssets/WGE-01 1.png'
import icon1 from '../../OdooAssets/Icons/Checkmark.png'
import img3 from '../../OdooAssets/OdooSubServicesAssets/odoo-book-17-service-management-01 1.png'
import img4 from '../../OdooAssets/OdooSubServicesAssets/odoo-book-17-service-management-02 1.png'
import img5 from '../../OdooAssets/OdooSubServicesAssets/odoo-book-17-service-management-03 (1) 1.png'
import img6 from '../../OdooAssets/OdooSubServicesAssets/odoo-book-17-service-management-04 1.png'
import img7 from '../../OdooAssets/OdooSubServicesAssets/odoo-book-17-service-management-05 1.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const OdooERPService = () => {
  return (
    <div className='OdooERPService'>

    <NavScrolled/>

    <div className='OdooERPService-header'>
      <p>ERP for Service Industry</p>
      <span>Optimise Your Service Business Operations with ERP</span>
      <Link><IoLogoWhatsapp/>Whatsapp</Link>
    </div>

    <div className='OdooERPService-part1'>
      <h6>Enhancing Service Industry Operations with Odoo ERP</h6>
      <p>The service sector experiences continuous growth and evolving operational challenges. A specialised management solution is essential to address the dynamic needs of service-oriented businesses. Odoo ERP offers a robust framework for effectively managing service industry operations. The platform is tailored to accommodate the unique requirements of service-based businesses, with expert partners like Legions Soft providing dedicated configuration and support.</p>
      <span></span>
    </div>

    <div className='OdooERPService-part2'>
      <p>Odoo Service Management</p>
      <img src={ImgPart2} alt='Service'/>
    </div>

    <div className='OdooERPService-part3'>
        <p>Efficient Project Management and Planning for Service Providers</p>
        <span>Integrated project management, coupled with planning tools, enables efficient planning and execution of operations for your service provider company.</span>
        <div className='OdooERPService-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Project Definition for Operational Management</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Task and Subtask Creation within Project Structures</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Comprehensive Task Management System</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Strategic Operational Planning using Planning Modules</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Project Forecasting Capabilities</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Efficient Employee Allocation Strategies</p>
            </div>
          </div>

        </div>
        <img src={img3} alt='img2'/>
    </div>

    {/*  */}
    <div className='OdooERPService-part4'>
        <p>Manage Finances Efficiently with Odoo Accounting</p>
        <span>The robust accounting module of Odoo empowers your service industry with advanced tools and functionalities for streamlined finance management operations.</span>
        <div className='OdooERPService-part4-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Refine Regional Taxation</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Establish Accounting Protocols</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Facilitate Diverse Payment Channels</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Structure Chart of Accounts</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Designate Fiscal Positions and Timelines</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Automate Invoice Generation</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Prescribe Payment Terms</p>
            </div>
          </div>

        </div>
        <img src={img4} alt='img2'/>
    </div>

    {/*  */}
    <div className='OdooERPService-part3'>
        <p>Optimise Sales Operations</p>
        <span>Leverage Robust Sales Management Tools within the Odoo Platform</span>
        <div className='OdooERPService-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Streamlined Sales Processes</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Efficiently Generate Sales Quotations and Orders</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Seamlessly Incorporate Service Charges and Timesheets</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Automate Invoicing for Service-Based Transactions</p>
            </div>
          </div>

        </div>
        <img src={img5} alt='img2'/>
    </div>

    {/*  */}
    <div className='OdooERPService-part4'>
        <p>Procurement and Inventory Management</p>
        <span>Efficiently Handle Product Procurement and Optimise Warehouse Operations for Enhanced Inventory Control</span>
        <div className='OdooERPService-part4-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Initiate requests for quotation to vendors</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Create and customise purchase orders</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Manage relationships with multiple vendors</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Efficiently handle and track vendor bills</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Implement three-way matching for purchase validation</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Organise and manage product locations and warehouses</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Implement lot and serial number tracking for products</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Assign expiry dates to products for inventory control</p>
            </div>
          </div>

        </div>
        <img src={img6} alt='img2'/>
    </div>

    {/*  */}
    <div className='OdooERPService-part3'>
        <p>Field Service Management Solution</p>
        <span>Facilitate efficient field service operations with Odoo's specialised field service management tool tailored for service-oriented industries.</span>
        <div className='OdooERPService-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>On-Site Task Planning and Assignment</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Task Definition</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Integrated Google Maps</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Invoice Generation based on Intervention</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Invoice Generation based on Timesheets</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Product Sales in the Field</p>
            </div>
          </div>

        </div>
        <img src={img7} alt='img2'/>
    </div>

    <div className='OdooERPService-part5'>
      <div>
        <div></div>
        <p>Why choose Legions Soft?</p>
        <span>Crafting an excellent service management solution can only be done by experts, professionals and developers who have high amounts of expertise in it.Odoo experts who are well capable of crafting exceptional service management solutions for your business.</span>
      </div>
    </div>


    <Footer/>

  </div>
  )
}

export default OdooERPService