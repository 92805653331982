import React from 'react'
import './OdooDevelopmentCompany.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import img1 from '../../OdooAssets/OdooNewAssets/Group 13.png'
import img2 from '../../OdooAssets/OdooNewAssets/logo-odoo-couleur_0cf0a14c 1.png'
import icon1 from '../../OdooAssets/OdooNewAssets/pencil-square.png'
import icon2 from '../../OdooAssets/OdooNewAssets/area-chart.png'
import icon3 from '../../OdooAssets/OdooNewAssets/thumbs-up.png'
import icon4 from '../../OdooAssets/OdooNewAssets/users.png'
import img3 from '../../OdooAssets/OdooNewAssets/f8607ce2-5835-4352-bee9-2113916701bf (1) 1.png'
import img4 from '../../OdooAssets/OdooNewAssets/odoo-statitics 1.png'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const OdooDevelopmentCompany = () => {
  return (
    <div className='OdooDevelopmentCompany'>

        <NavScrolled/>

        <div className='OdooDevelopmentCompany-header'>
            <p>Odoo Development by Legions Soft</p>
            <span>Discover the power of customised Odoo development services from Legions Soft, meticulously crafted to optimise your business processes and drive success.</span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
        </div>

        <div className='OdooDevelopmentCompany-part-1'>   
            <div>
                <img src={img2} alt='img 2'/>
                <div>
                    <p>Why choose Odoo ERP/Open Source?</p>
                    <span>Odoo / OpenERP is a suite of enterprise management applications, targeting Small, Medium and Multinational Enterprises encompassing several departments. Odoo ERP streamlines all managements segments like Manufacturing, Finance and Accounting, Purchase, Sales, Warehouse, Customer Relationship Management, Human Resource, Point of Sale, Ecommerce and Website, Project Management, Document Management under one roof, thereby improving the efficiency and productivity of an enterprise.</span>
                </div>
            </div>
            <img src={img1} alt='img 1'/>
        </div>

        <div className='OdooDevelopmentCompany-part-2'>
            <p>Why Odoo ERP?</p>
            <div>
                <div>
                    <img src={icon1} alt='icon 1'/>
                    <p>OpenSource</p>
                    <span>No Vendor Lock-in Frequent Product Growth</span> 
                </div>
                <div>
                    <img src={icon2} alt='icon 1'/>
                    <p>Proven Product with 12 Million+ Users</p>
                    <span>Globally Accepted Application Proven Quality</span> 
                </div>
                <div>
                    <img src={icon3} alt='icon 1'/>
                    <p>High Quality at Reasonable Price</p>
                    <span>Outstanding ERP solution Minimal Investment</span> 
                </div>
                <div>
                    <img src={icon4} alt='icon 1'/>
                    <p>40000+ Ready to Use Modules</p>
                    <span>Fully Integrated Modules Expanding Day By Day</span> 
                </div>
            </div>
        </div>

        <div className='OdooDevelopmentCompany-part-3'>
            <div className='OdooDevelopmentCompany-part-3-sec-1'>
                <p>Legions Soft and Odoo ERP</p>
                <span>
                    Legions Soft acts as one of the best and trusted Odoo partner, constantly serving the best customization and implementation in Odoo ERP. We are enriched with highly skilled technical experts for developing, implementing and designing the ERP providing ultimate benefit to our customer base. Today Legions Soft has reached par excellent in odoo implementation and allied services, proving its credibility and reliability. Our robust, foolproof ERP solutions are used by customers sprawling across the globe. Legions Soft help their clients achieve strategic business goals via technical ingeniousness, proven domain experience, and project management skills.We have been partners with Odoo for 15+ years and we were there at the initial stages when Odoo was developed onto a prominent ERP solution from being a Tiny ERP software. Today we have a large clientele varying in sectors, scales, and regions of operation. Our Odoo development services are well crafted and planned as per your operational requirements after continuous consultation and analysis on your business operation.
                </span>
            </div>
            <div className='OdooDevelopmentCompany-part-3-sec-2'>
                <img src={img3} alt='img'/>
                <div>
                    <p>Our Range of Odoo Applications</p>
                    <span>
                    Legions Soft, we proudly present our extensive range of Odoo applications. With over 670 applications and plugins developed for the community, we are committed to providing reliable solutions that streamline business operations. Our user-friendly interface ensures ease of use, enhancing productivity and efficiency. Whether you're looking for free or paid applications, you can find them on the Odoo apps store or our website. Trust Legions Soft for top-notch Odoo solutions tailored to your business needs.
                    </span>
                </div>
            </div>
        </div>

        <div className='OdooDevelopmentCompany-part-4'>
            <div>
                <div>
                    <p>Our Range of Odoo Services</p>
                    <span>
                    At Legions Soft, we take pride in our role as a leading provider of Odoo development services, following closely behind the Odoo community in app contributions. Our dedication is evident in our second-place ranking for app downloads by Odoo users. Our focus is on crafting solutions that address specific challenges and introduce advanced functionalities to enhance user operations within the Odoo platform.                    </span>
                </div>
                <img src={img4} alt='img4'/>
            </div>
        </div>

        <div className='OdooDevelopmentCompany-part-5'>
          <p>Why Legions Soft</p>
          <span>Our team is here to help you identify any technical problems you're experiencing.</span>
          <div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> High coding standards</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Expert in all versions of Odoo</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 8+ years of experience</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 900+ satisfied customers across the globe</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 790+ Free modules contributions in Odoo apps</p>
            </div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> Ranks Fifth in Odoo app downloads</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High-quality deliverables</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Technical & Functional expertise in all Odoo modules</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Service in both enterprise & community editions</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High quality deliverables</p>
            </div>
          </div>
        </div>

        <div className='OdooDevelopmentCompany-part-6'>
            <div>
                <p>Frequently Asked Questions</p>
                <div>
                    <p>How Does Legions Soft Ensure Seamless Integration with Odoo ERP?</p>
                    <span>At Legions Soft, our approach to Odoo ERP integration involves meticulous planning, thorough analysis of business requirements, and expert development to ensure seamless integration with existing systems. Our team leverages advanced techniques and best practices to minimise disruptions and optimise functionality.</span>
                </div>
                <div>
                    <p>What Sets Legions Soft Apart in Odoo Module Development?</p>
                    <span>Legions Soft distinguishes itself in Odoo module development through a combination of technical expertise, industry knowledge, and a client-centric approach. Our developers possess a deep understanding of Odoo's architecture and customization capabilities, allowing us to create tailored modules that address specific business needs effectively.</span>
                </div>
                <div>
                    <p>How Does Legions Soft Ensure Optimal Performance in Odoo Development?</p>
                    <span>Legions Soft prioritises performance optimization in Odoo development by employing efficient coding practices, rigorous testing methodologies, and continuous performance monitoring. Our team conducts thorough analysis and optimization techniques to enhance system responsiveness, scalability, and overall efficiency.</span>
                </div>
            </div>
        </div>

        <div className='OdooDevelopmentCompany-part-7'>
        <p>Our Odoo Services</p>
        <div className='OdooDevelopmentCompany-part-7-items'>
          <div>
            <span><img src={icon15} alt='icon15'/></span>
            <p>Odoo Customization</p>
          </div>
          <div>
            <span><img src={icon16} alt='icon15'/></span>
            <p>Odoo Implementation</p>
          </div>
          <div>
            <span><img src={icon17} alt='icon15'/></span>
            <p>Odoo Integration</p>
          </div>
          <div>
            <span><img src={icon18} alt='icon15'/></span>
            <p>OOdoo Support & Maintenance</p>
          </div>
          <div>
            <span><img src={icon19} alt='icon15'/></span>
            <p>Odoo Migration</p>
          </div>
          <div>
            <span><img src={icon20} alt='icon15'/></span>
            <p>Odoo Training</p>
          </div>
          <div>
            <span><img src={icon21} alt='icon15'/></span>
            <p>Odoo Consultancy</p>
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooDevelopmentCompany