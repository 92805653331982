import React from 'react'

import './OdooERPTrading.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import img1 from '../../OdooAssets/OdooSubServicesAssets/trading-erp-infographic 1.png'
import icon1 from '../../OdooAssets/Icons/Checkmark.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const OdooERPTrading = () => {
  return (
    <div className='OdooERPTrading'>

      <NavScrolled/>

      <div className='OdooERPTrading-header'>
        <p>Odoo ERP for Trading Companies</p>
        <span>The complete solutions for wholesale and retail trading business</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooERPTrading-part1'>
        <span>Trading is a form of business where different forms of goods and service-based products are sold to the customer in small amounts or bulk. The customers can be larger establishments or retail shops. Today the trading operations run on a B2B and B2C basis where a company mainly specializes in a product or a type of product and functions locally or globally.</span>
        <p>Aspects of the best trading business</p>
        <div>
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Strong logistic organization</p>
          </div>
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Faster distribution</p>
          </div> 
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Dedicated operational methodology</p>
          </div> 
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Modernized marketing</p>
          </div> 
        </div>
      </div>

      <div className='OdooERPTrading-part3'>
        <p>Odoo for your Trading Business</p>
        <span>Odoo a modernized business management solution will be your apt choice for the effective functioning of your trading business. Equipped with advanced operational functionalities Odoo puts forward your business operational satisfaction and easiness. The exceptional operational features of Odoo which are suitable to run a Trading business make it the best solution.</span>
        <img src={img1} alt='Trading'/>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooERPTrading