import React, { useEffect, useState } from 'react'
import Nav from '../NavBar/Nav'
import { GoDotFill } from 'react-icons/go'
import publicImg from './aboutAssets/public.png'
import publicImg1 from './aboutAssets/1_2.png'
import publicImg2 from './aboutAssets/1_3.png'
import publicImg3 from './aboutAssets/1_4.png'
import publicImg4 from './aboutAssets/1_5.png'
import publicImg5 from './aboutAssets/1_6.png'
import icon1 from './aboutAssets/review-icons/ic-quotes.svg'
import recognizeImg5 from '../../assets/Logo-Strategic-Partnerships-2022_1-2-2 1.png';
import recognizeImg2 from '../../assets/clutch-top-info-tech-white 1.png';
import recognizeImg3 from '../../assets/Clip path group.png';
import recognizeImg4 from '../../assets/ENG-Inc.-5000 1.png';
import recognizeImg1 from '../../assets/ISO.png';
import Footer from '../Footer/Footer';


import './About.css'
import ContactUs from '../ContactUs/ContactUs'

const About = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [technologyTabs, setTechnologyTabs] = useState('Seamless management tools');

    const handleScroll = () => {
        if (window.scrollY > 230) {
            console.log("window.scrollY", window.scrollY)
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleTechnologyTabClick = (tab) => {
        setTechnologyTabs(tab);
    };
    

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [])

  return (
    <div>

    <div className='about-two-container'>

      <div className={`HireDevelopers-nav ${isScrolled ? 'HireDevelopers-nav-scrolled' : ''} `}>
          <Nav/>
      </div>

      <div className='about-two-hero-container'>
          <p>Three.<br/>Two.<br/>Online</p>
          <img src={publicImg} alt='img'/>
      </div>

    </div>

    <div className='about-two-part-1-container'>
        <p>About us</p>
        <div className='about-two-part-1-container-section-1'>
            <div>
                <p>Find your online success with Legions Soft</p>
                <span>
                    Legions Soft is on a mission to make online success possible for anyone – 
                    from developers to aspiring bloggers and business owners. With our 
                    fast hosting technology, AI-powered Website Builder, and easy-to-operate hPanel, 
                    growing your website is nothing short of effortless.
                </span>
            </div>
            <img src={publicImg1} alt='img'/>
        </div>
        <div className='about-two-part-1-container-section-2'>
            <img src={publicImg2} alt='img'/>
            <div>
                <p>Globally recognized web host</p>
                <span>
                    Launched in 2004 as a Lithuania-based tech startup, Hostinger is now a leading provider of web hosting solutions, and we serve over 3 million people from 150+ countries
                    <br/>
                    Staying true to our reputation as the fastest-growing hosting brand of 2020*, we’ll continue to improve our services, unlock new markets, and be a part of even more success stories.
                </span>
            </div>
        </div>
    </div>

    <div className='about-two-part-2-container'>
        <p>Our history</p>
        <div>
            <div>
                <p>2018</p>
                <span>We launched the first-class cPanel web hosting brand Hosting24.com.</span>
            </div>
            <div>
                <p>2019</p>
                <span>We launched the first-class cPanel web hosting brand Hosting24.com.</span>
            </div>
            <div>
                <p>2020</p>
                <span>We launched the first-class cPanel web hosting brand Hosting24.com.</span>
            </div>
            <div>
                <p>2021</p>
                <span>We launched the first-class cPanel web hosting brand Hosting24.com.</span>
            </div>
        </div>
    </div>

    <div className='about-two-part-3-container'>
        <p>Our strengths</p>
        <div className='about-two-part-3-container-section-1'>
            <div className='about-two-part-3-container-section-1-part-1'>
                <button
                    className={technologyTabs === 'Seamless management tools' ? 'about-two-secondary-tabs-active' : ''}
                    onClick={() => handleTechnologyTabClick('Seamless management tools')}
                >Seamless management tools 
                </button>
                <button
                    className={technologyTabs === 'Website speed' ? 'about-two-secondary-tabs-active' : ''}
                    onClick={() => handleTechnologyTabClick('Website speed')}
                >Website speed
                </button>
                <button
                    className={technologyTabs === 'Dedicated 24/7 support' ? 'about-two-secondary-tabs-active' : ''}
                    onClick={() => handleTechnologyTabClick('Dedicated 24/7 support')}
                >Dedicated 24/7 support
                </button>
            </div>

            {technologyTabs === 'Seamless management tools' && (
                <div className='about-two-part-3-container-section-2'>
                    <p>Seamless management tools</p>
                    <span>
                        Regardless of your background or technical expertise, our site-building and management tools have been created with user experience and power in mind. Control everything in one place with hPanel, from domains and web hosting to email accounts and more. Have more time for what matters with our AI-powered drag-and-drop Hostinger Website Builder – create and publish a website within minutes, without limits.
                    </span>
                </div>
            )}
            {technologyTabs === 'Website speed' && (
                <div className='about-two-part-3-container-section-2'>
                    <p>Website speed</p>
                    <span>
                        We want website creators and business owners to move forward, and fast. Imagine having your site load in milliseconds anywhere in the world with our globally distributed servers and 99.9% uptime guarantee. Give your audience the best user experience, and watch as your site rankings improve.
                    </span>
                </div>
            )}
            {technologyTabs === 'Dedicated 24/7 support' && (
                <div className='about-two-part-3-container-section-2'>
                    <p>Dedicated 24/7 support</p>
                    <span>
                        We are here for every committed online hustler who aims to rock the web. Our Customer Success team speaks 10+ languages, so you can confidently communicate your thoughts and concerns in your own language. Spend less time worrying about technical issues – we promise to get back to you quickly with helpful solutions.
                    </span>
                </div>
            )}
        </div>

        </div>

        <div className='about-two-part-4-container'>
            <p>Thousands of satisfied customers</p>
            <span>Don’t just take our word for it – thousands of customers are happy using Hostinger.</span>
            <div className='about-two-part-4-container-section-1'>
                <div className='about-two-part-4-container-section-1-part-1'>
                    <div className='about-two-part-4-container-section-1-part-1-1'>
                        <p>Podaz Store</p>
                        <span>Online shop</span>
                    </div>
                    <div className='about-two-part-4-container-section-1-part-1-2'>
                        <img src={icon1} alt='icon'/>
                        <p>I was recently in contact with Hostinger’s Customer Success team. The service was one of the best I have experienced – their agents were informative and helpful. Even with a small amount of information, they managed to detect the problem and solve it quickly.</p>
                    </div>
                </div>
                <div className='about-two-part-4-container-section-1-part-1'>
                    <div className='about-two-part-4-container-section-1-part-1-1'>
                        <p>Podaz Store</p>
                        <span>Online shop</span>
                    </div>
                    <div className='about-two-part-4-container-section-1-part-1-2'>
                        <img src={icon1} alt='icon'/>
                        <p>I was recently in contact with Hostinger’s Customer Success team. The service was one of the best I have experienced – their agents were informative and helpful. Even with a small amount of information, they managed to detect the problem and solve it quickly.</p>
                    </div>
                </div>
                <div className='about-two-part-4-container-section-1-part-1'>
                    <div className='about-two-part-4-container-section-1-part-1-1'>
                        <p>Podaz Store</p>
                        <span>Online shop</span>
                    </div>
                    <div className='about-two-part-4-container-section-1-part-1-2'>
                        <img src={icon1} alt='icon'/>
                        <p>I was recently in contact with Hostinger’s Customer Success team. The service was one of the best I have experienced – their agents were informative and helpful. Even with a small amount of information, they managed to detect the problem and solve it quickly.</p>
                    </div>
                </div>
            </div>
            <button>Read more reviews</button>
        </div>

        <div className='about-two-part-5-container'>
            <div className='about-two-part-5-container-section-1'>
                <img src={publicImg3} alt='img'/>
                <div className='about-two-part-5-container-section-1-part-1'>
                    <div>
                        <p>Technology</p>
                        <span>Innovation on the go</span>
                    </div>
                    <p>As one of the fastest and most efficient web hosting service providers around, we keep adapting to the latest tech advancements in the industry. We constantly improve our servers’ infrastructure with advanced anti-DDoS solutions, LiteSpeed-powered tech stack, and our custom-built control panel – hPanel.</p>
                </div>
            </div>
            <div className='about-two-part-5-container-section-1'>
                <div className='about-two-part-5-container-section-1-part-1'>
                    <div>
                        <p>People</p>
                        <span>A committed team of heroes</span>
                    </div>
                    <p>Hostinger is one of the fastest-growing web hosting and AI website builder providers, with over 1,000 employees in 54 countries. Like our dedication to our clients, we take care of our own so that we can grow professionally and take our customers to the next level. Join Hostinger and hustle with us!</p>
                </div>
                <img src={publicImg4} alt='img'/>
            </div>
            <div className='about-two-part-5-container-section-1'>
                <img src={publicImg5} alt='img'/>
                <div className='about-two-part-5-container-section-1-part-1'>
                    <div>
                        <p>Customer obsession</p>
                        <span>Before we speak, we listen</span>
                    </div>
                    <p>You, The Customer, hold the highest rank at Hostinger. Your feedback is key to improving our products, processes, and overall customer satisfaction. We always seek out our clients’ input through surveys, online reviews, and one-on-one interviews.</p>
                </div>
            </div>
        </div>

        <div className='home-recognize-container'>
                
            <p>Recognized among the best, by the best</p>
            <div  >
                <img src={recognizeImg1} alt='abc'/>
                <img src={recognizeImg2} alt='abc'/>
                <img src={recognizeImg3} alt='abc'/>
                <img src={recognizeImg4} alt='abc'/>
                <img src={recognizeImg5} alt='abc'/>
            </div>
        </div>

        <ContactUs/>

        <div className='inside-footer'>
            <Footer/>
        </div>
        

    </div>
  )
}

export default About