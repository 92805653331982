import React from 'react'
import Nav from '../NavBar/Nav'
import img1 from '../../App Assets/Group 402.png'
import img2 from '../../App Assets/LEGION SOFT (78) 3.png'
import img3 from '../../App Assets/LEGION SOFT (90) 1.png'
import img4 from '../../App Assets/Group 403.png'
import img5 from '../../App Assets/Rectangle 702 (1).png'
import img6 from '../../App Assets/image 14.png'
import img7 from '../../App Assets/image 15.png'
import './IOSDevelopment.css'
import { IoIosArrowDown } from "react-icons/io";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const IOSDevelopment = () => {
  return (
    <div className='IOSDevelopment'>

      <NavScrolled/>

        <div className='IOSDevelopment-part-1'>
          <p>Discover Seamless iOS App Development Solutions</p>
          <span>
            Embark on a journey of cutting-edge iOS app development with Legions Soft. 
            Elevate your brand's presence on Apple devices with seamless, user-centric 
            applications. Whether opting for cross-platform versatility or native 
            development using Swift, we serve as your gateway to innovative iOS 
            solutions in Los Angeles. 
          </span>
        </div>

        <div className='IOSDevelopment-part-2'>
          <p>Achieve Cross-Platform Excellence on iOS</p>
          <span>
            Dive into the versatile world of cross-platform development with 
            Legions Soft. Our expertise in React and Flutter ensures your iOS 
            app seamlessly adapts across various devices, offering users a consistent 
            and intuitive experience. Experience unmatched efficiency, 
            cost-effectiveness, and broad market reach by partnering with us for your 
            iOS app development. Take the leap to enhance your digital footprint with 
            Legions Soft's expert guidance.
          </span>
          <p>Swift Solutions: iOS Experience with Native Development Brilliance</p>
          <span>
            Immerse yourself in the realm of precision and performance with 
            Legions Soft's native iOS development expertise. Our seasoned developers 
            leverage the power of Swift to craft scalable, high-performing 
            applications that align seamlessly with your project's distinctive 
            needs. Entrust us to transform your iOS app vision into reality with 
            finesse and efficiency.
          </span>
        </div>

        <div className='IOSDevelopment-part-3'> 
          <div>
            <p>30+</p>
            <span>APPS</span>
          </div>

          <div>
            <p>3 M+</p>
            <span>TOTAL USERS</span>
          </div>


          <div>
            <p>$20m</p>
            <span>RAISED</span>
          </div>


          <div>
            <p>9yrs</p>
            <span>DEVELOPER'S AVG EXPERIENCE</span>
          </div>
        </div>

        <div className='IOSDevelopment-part-4'>
          <p>Our <span>Successfully</span> Developed iOS Apps</p>
          <div className='IOSDevelopment-part-4-section-1'>
            <p>
              DOCIB Pharmacy - Healthcare Management
              <img src={img3} alt='img'/>
            </p>
            <div>
              <p>
                Introducing DOCIB Pharmacy, a cutting-edge mobile application 
                meticulously crafted by our team to revolutionize your healthcare 
                experience. We've designed DOCIB Pharmacy with a focus on simplicity, 
                ensuring seamless navigation and an intuitive user interface for 
                effortless access to a world of health, beauty, and wellness.
                <br/>
                <br/>
                Experience the convenience of managing your health on the go with 
                DOCIB Pharmacy. Whether you're seeking skincare recommendations or 
                expert health advice, our app is your trusted companion, available 
                anytime, anywhere.
              </p>
              <img src={img1} alt='img'/>
            </div>
            <img src={img2} alt='img'/>
          </div>
        </div>

        <div className='IOSDevelopment-part-5'>
          <div className='IOSDevelopment-part-5-section-1'>
            <p>
              Sree Super Bazar
              <img src={img5} alt='img'/>
            </p>
            <div>
              <p>
                What sets Sree Super Bazaar apart is its seamless navigation and 
                intuitive interface, making it effortless for users to browse 
                through a vast array of products. Whether you're in need of groceries,
                bakery items, household essentials, beverages, snacks, or branded foods, our app has you covered.
                <br/>
                <br/>
                We've also integrated advanced features to enhance the overall 
                experience. From personalised recommendations based on user 
                preferences to real-time updates on deals and discounts, Sree 
                Super Bazaar ensures that every interaction with the app is 
                tailored to the individual needs of our users.
              </p>
              <img src={img4} alt='img'/>
            </div>
            <img src={img2} alt='img'/>
          </div>
        </div>

        <div className='IOSDevelopment-part-6'>
          <div className='IOSDevelopment-part-6-section-1'>
            <p>
              Royal Taj Restaurant
              <img src={img7} alt='img'/>
            </p>
            <div>
              <p>
                What sets Sree Super Bazaar apart is its seamless navigation and 
                intuitive interface, making it effortless for users to browse 
                through a vast array of products. Whether you're in need of groceries,
                bakery items, household essentials, beverages, snacks, or branded foods, our app has you covered.
                <br/>
                <br/>
                We've also integrated advanced features to enhance the overall 
                experience. From personalised recommendations based on user 
                preferences to real-time updates on deals and discounts, Sree 
                Super Bazaar ensures that every interaction with the app is 
                tailored to the individual needs of our users.
              </p>
              <img src={img6} alt='img'/>
            </div>
            <img src={img2} alt='img'/>
          </div>
        </div>


        <div className='IOSDevelopment-part-7'>
          <div>
            <p>Cross-platform development</p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>Swift</p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>User-centric design</p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>Scalability</p>
            <IoIosArrowDown size={35}/>
          </div>
        </div>


        <div className='IOSDevelopment-part-8'>
          <p>Frequently Asked Questions:</p>

          <div>
          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>Why should I choose Legions Soft for iOS app development in Austalia?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              abc
            </div>
          </div>

          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>How do you approach scalability in iOS app development?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              abc
            </div>
          </div>

          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>How do you ensure cross-platform brilliance for iOS apps?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>What are IOS services?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>Can you seamlessly integrate Swift into iOS app development?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='IOSDevelopment-part-8-section-to-hover'>
              <p>Can you manage both iOS and Android app development for my project?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='IOSDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

        </div>
      </div>

      <div className='IOSDevelopment-part-9'>
        <p>Don't hesitate any longer!</p>
        <span>
          We're eager to connect with you on social media. Reach out to us today 
          at contact@legionssoft.com or give us a call at 0339-4010310 to embark on your 
          journey with one of Australia's premier social media agencies.
        </span>
      </div>
      <Footer/>
    </div>
  )
}

export default IOSDevelopment