import React,{useEffect, useRef, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu, RxCross2  } from "react-icons/rx";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import icon8Cms from '../../assets/Odoo-icons8-o-50.png'
import Logo from '../../assets/Layer_logo.png'
import { MdOutlineStarPurple500 } from "react-icons/md";
import { MdOutlineStarHalf } from "react-icons/md";

import img1 from '../../assets/nav-clutch.svg'
import img2 from '../../assets/nav-google.svg'
import img3 from '../../assets/nav-logo_blue.svg'
import img4 from '../../assets/nav-glassdoor.svg'

import './Nav.css'

const Nav = () => {

    const [showNavbar, setShowNavbar] = useState(false);
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showOdooDropdown, setShowOdooDropdown] = useState(false);
    const [showOdooDropdownR, setShowOdooDropdownR] = useState(false);
    const [showDropdownR, setShowDropdownR] = useState(false);
    const dropdownRef = useRef(null);
    const odoodropdownRef = useRef(null);


    const isServicesPage = location.pathname
    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar);
    };
  
    const handleClick = () => {
      setShowDropdown(!showDropdown);
      handleClickR()
    };

    const handleClickOdoo = () => {
      setShowOdooDropdown(!showOdooDropdown);
      handleClickO()
    }

    const handleClickR = () => {
      setShowDropdownR(!showDropdownR);
    };

    const handleClickO = () => {
      setShowOdooDropdownR(!showOdooDropdownR);
    };


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
        if (odoodropdownRef.current && !odoodropdownRef.current.contains(event.target)) {
          setShowOdooDropdown(false);
        }
      };
   
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef, odoodropdownRef]);


  return (
     <nav className={`navbar ${showDropdown === true || showOdooDropdown === true ? "navbardrop-open" : "navbar" } ${showNavbar === true && "navbar"}`}>
      <div className="container">
        <Link className="logo" to='/'>
            <img src={Logo} alt='Logo' width={65}/>
          <div className={`nav-logo_desc ${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage ===  '/contract' || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page-nav' : ''}`}>
            <p>LEGIONS SOFT</p>
            <span>Innovate, Integrate, Elevate</span>
          </div>
        </Link>
        <div className="menu-icon" onClick={handleShowNavbar}>
          {
            (isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract' || isServicesPage ===  '/OdooServices/OdooIntegration' ) ? (
              <>
                {showNavbar ? <RxCross2 size={25} className='rxCrosswhite' /> : <RxHamburgerMenu size={25} className='rxCrossBlack' />}
              </>
            ) : (
              <>
                {showNavbar ? <RxCross2 size={25} className='rxCrosswhite' /> : <RxHamburgerMenu size={25} className='rxCrosswhite' />}
              </>
            )
          }
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract'  || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page' : ''} ${showNavbar ? 'nav-element-white' : ''}`}>
              <Link to="/about">About Us</Link>
            </li>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract'  || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page' : ''} ${showNavbar ? 'nav-element-white' : ''}`} >
              <Link className='nav-services-icon' onClick={handleClick}>Services <MdOutlineKeyboardArrowDown size={23}/></Link>
              {showDropdownR && (
                <div className='nav-services-dropdown-r'>
                  <div className='nav-services-dropdown-container-r'> 
                    <p>App Development</p>
                      <div>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                      </div>
                  </div>
                  <div className='nav-services-dropdown-container-r'> 
                    <p>Web Development</p>
                      <div>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                      </div>
                  </div>
                  <div className='nav-services-dropdown-container-r'> 
                    <p>Digital Marketing</p>
                      <div>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                        <Link>abc1</Link>
                      </div>
                  </div>
                </div>
              )}
            </li>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract' || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page' : ''} ${showNavbar ? 'nav-element-white' : ''}`}>
              <Link className='nav-services-icon' onClick={handleClickOdoo}>Odoo Services <MdOutlineKeyboardArrowDown size={23}/></Link>
              {showOdooDropdownR && (
                <div className='nav-services-dropdown-r'>
                  <div className='nav-services-dropdown-container-r'> 
                    <p>Odoo</p>
                    <div>
                      <Link to="/OdooServices">Odoo</Link>
                      <Link to="/OdooServices/odoo-implementation">Odoo Implementation</Link>
                      <Link to="/OdooServices/odoo-customization">Odoo Cusomization</Link>
                      <Link to="/OdooServices/odoo-support">Odoo Support</Link>
                      <Link to="/OdooServices/odoo-training">Odoo Training</Link>
                    </div>
                    <div>
                      <Link to="/OdooServices/OdooDemo">Odoo Demo</Link>
                      <Link to="/OdooServices/OdooERP">Odoo ERP</Link>
                      <Link to="/OdooServices/OdooDevelopmentCompany">Odoo Development</Link>
                      <Link to="/OdooServices/OdooIntegration">Odoo Integration</Link>
                      <Link to="/OdooServices/OdooMigration">Odoo Migration</Link>
                    </div>
                    <div>
                      <Link to="/OdooServices/odoo-erp-service">Odoo ERP For Service</Link>
                      <Link to="/OdooServices/odoo-erp-trading">Odoo ERP For Trading</Link>
                      <Link to="/OdooServices/odoo-erp-manufacturing">Odoo ERP For Manufacturing</Link>
                      <Link to="/OdooServices/odoo-erp-education">Odoo ERP For Education</Link>
                      <Link to="/OdooServices/odoo-erp-embroidery">Odoo ERP For Embroidery</Link>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract' || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page' : ''} ${showNavbar ? 'nav-element-white' : ''}`}>
              <Link to="/HireDevelopers">Hire Developers</Link>
            </li>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract'  || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page ' : ''} ${showNavbar ? 'nav-element-white' : ''} contact-us-page-1`}>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li className={`${isServicesPage === '/about' || isServicesPage === '/HireDevelopers' || isServicesPage === '/contract'  || isServicesPage ===  '/OdooServices/OdooIntegration' ? 'active-page' : ''} ${showNavbar ? 'nav-element-white' : ''} contact-us-page-2` }>
              <Link to="/contact">Contact Us</Link>
            </li>
            {/* <li className='nav-quote'>
              <Link to="/contract">Request A Quote <MdOutlineArrowForward size={22}/></Link>
            </li> */}
          </ul>
        </div>
      </div>

      {showDropdown && (
        <div  ref={dropdownRef} className='nav-services-dropdown'>
          <div className='nav-services-dropdown-section-1'>
            <p>What We Do</p>
            <span>Depending on your goals, Legions will serve as your core team or augment your existing team.</span>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img1} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img2} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img3} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img4} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
          </div>
          <div className='nav-services-dropdown-section-2'>
            <div className='nav-services-dropdown-container'> 
              <Link to="">App Development</Link>
                <div>
                  <Link to="/MobileAppDevelopment">App Development</Link>
                  <Link to='/MobileAppDevelopment/IOSDevelopment'>IOS Development</Link>
                  <Link to='/MobileAppDevelopment/AndroidDevelopment'>Andriod Development</Link>
                  <Link to='/MobileAppDevelopment/app-support'>App Support</Link>
                  <Link to='/MobileAppDevelopment/HybridDevelopment'>Hybrid Development</Link>
                </div>
            </div>
            <div className='nav-services-dropdown-container'> 
              <Link>Web Development</Link>
                <div>
                  <Link to='/web-development'>Web Development</Link>
                  <Link to='/web-development/blockchain'>Block Chain</Link>
                  <Link to='/web-development/CMS'>CMS Development</Link>
                  <Link to='/web-development/payment-getways'>Payment Getways</Link>
                  <Link to='/web-development/server-development'>Server Development</Link>
                </div>
            </div>
            <div className='nav-services-dropdown-container'> 
              <Link>Marketing</Link>
                <div>
                  <Link to="/digital-marketing">Digital Marketing</Link>
                  <Link to="/web-design-marketing">Web Design</Link>
                  <Link to="/emai-marketing">Email Marketing</Link>
                  <Link to='/social-media-marketing'>Social Media Marketing</Link>
                  <Link to='/graphics-design-marketing'>Graphics Design</Link>
                </div>
            </div>
          </div>
          
        </div>
      )}

      {showOdooDropdown && (
        <div  ref={odoodropdownRef} className='nav-services-dropdown'>
          <div className='nav-services-dropdown-section-1'>
            <p>What We Do</p>
            <span>Depending on your goals, Legions will serve as your core team or augment your existing team.</span>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img1} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img2} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img3} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
            <div className='nav-services-dropdown-section-1-box'>
              <img src={img4} alt='img'/>
              <div>
                <p>5.0 
                  <span><MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarPurple500 size={22}/> <MdOutlineStarHalf size={22}/></span>
                </p>
                <span>Based on 28 reviews</span>
              </div>
            </div>
          </div>
          <div className='nav-services-dropdown-container-odoo'> 
              <Link><img src={icon8Cms} alt='Odoo'/>Odoo</Link>
              <div>
                <div>
                    <Link to="/OdooServices">Odoo</Link>
                    <Link to="/OdooServices/odoo-implementation">Odoo Implementation</Link>
                    <Link to="/OdooServices/odoo-customization">Odoo Cusomization</Link>
                    <Link to="/OdooServices/odoo-support">Odoo Support</Link>
                    <Link to="/OdooServices/odoo-training">Odoo Training</Link>
                </div>
                <div>
                  <Link to="/OdooServices/OdooDemo">Odoo Demo</Link>
                  <Link to="/OdooServices/OdooERP">Odoo ERP</Link>
                  <Link to="/OdooServices/OdooDevelopmentCompany">Odoo Development</Link>
                  <Link to="/OdooServices/OdooIntegration">Odoo Integration</Link>
                  <Link to="/OdooServices/OdooMigration">Odoo Migration</Link>
                </div>
                <div>
                    <Link to="/OdooServices/odoo-erp-service">Odoo ERP For Service</Link>
                    <Link to="/OdooServices/odoo-erp-trading">Odoo ERP For Trading</Link>
                    <Link to="/OdooServices/odoo-erp-manufacturing">Odoo ERP For Manufacturing</Link>
                    <Link to="/OdooServices/odoo-erp-education">Odoo ERP For Education</Link>
                    <Link to="/OdooServices/odoo-erp-embroidery">Odoo ERP For Embroidery</Link>
                </div>
              </div>
          </div>
          
        </div>
      )}
    </nav>
  )
}

export default Nav