import React from 'react'

import './Loader.css';

const Loader = () => {
  return (
    <div className='loader-loader'>
        <div className="cube-loader">
            <div className="cube-top"></div>
                <div class="cube-wrapper">
                    <span style={{ '--i': 0 }} className="cube-span"></span>
                    <span style={{ '--i': 1 }} className="cube-span"></span>
                    <span style={{ '--i': 2 }} className="cube-span"></span>
                    <span style={{ '--i': 3 }} className="cube-span"></span>
                </div>
            </div>
        
    </div>
  )
}

export default Loader