import React, { useEffect, useState } from 'react';
import Nav from '../NavBar/Nav'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './MobileAppDevelopment.css'
import { Link } from 'react-router-dom'
import { MdOutlineArrowForward } from 'react-icons/md'
import { IoIosArrowRoundForward } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";


import MobxImg from '../../assets/Mobx.png'
import MaterialImg from '../../assets/Materia;ui.png'
import ReduxImg from '../../assets/Redux.png'
import FirebaseImg from '../../assets/firebase.png'
import webpackImg from '../../assets/webpaclk.png'
import vsCodeImg from '../../assets/vscode.png'
import JestImg from '../../assets/jest.png'

import iosFintech from '../../assets/ios-fintech.png'
import iosREALeSTATE from '../../assets/ios-realestate.png'
import iosEcommerce from '../../assets/ios- e-commerce (1) 1.png'
import iosHealthCare from '../../assets/ios-healtcare.png'
import iosSupplyChain from '../../assets/ios-supply-chain 1.png'
import iosLogistics from '../../assets/ios-logistics 1.png'
import iosBankling from '../../assets/ios-banking 1.png'

import Clutch from '../../assets/mobile-dev-securities-and-exchange-commission-of-pakistan-logo-7828A88801-seeklogo 2.png'
import ISO from '../../assets/mobile-dev-iso-2-1-logo-png-transparent 2.png'
import Pasha from '../../assets/P@SHA_Logo 2.png'
import PSeb from '../../assets/pseb-logo-1 2.png'
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";

import contactUs from '../../assets/contactus-mobile-dev.png'
import appDevSericesPic from '../../assets/app-dev-services.png'
import pseudo from '../../assets/pseudo.png'
import TestimonialImg from '../../assets/Ellipse 8.png'

import img1 from '../../assets/Android App Development-01.png'
import img2 from '../../assets/Cross-Platform Mobile Apps-01.png'
import img3 from '../../assets/design thinking-01.png'
import img4 from '../../assets/iOS App Development-01.png'
import img5 from '../../assets/The Latest Tech-01.png'
import img6 from '../../assets/Top Quality-01.png'
import img7 from '../../assets/Consulting-and-Prototyping.png'
import img8 from '../../assets/mbivr-appIntegration.svg'
import img9 from '../../assets/Native-App-Development.svg'
import img10 from '../../assets/mobile-app-dev-section_1.png'

import Footer from '../Footer/Footer'
import Loader from '../Loader/Loader';
import ContactUs from '../ContactUs/ContactUs';

const FAQDATA = [
  {"id":1, "heading": "What is the cost of mobile app development?", "data": "Depending on the complexity of the app, mobile app development can cost about $24,000 for a simple app and around $137,000 for a more sophisticated app. Highly complex apps can cost $500,000 or more. The mobile app development company you partner with can also influence the cost of building your app." },
  {"id": 2, "heading": "How can a mobile app benefit my business?", "data": "Mobile apps are an effective way to build stronger relationships with your customers. The biggest benefit of a mobile app is increased brand awareness. You also get higher customer engagement and deeper insights into your customers’ behavior. This valuable data can be used to improve your ads, outreach efforts, and even future app versions."},
  {"id": 3, "heading": "What kind of mobile apps do you develop?", "data": "Legion builds both native and cross-platform mobile apps. We work with you to determine the best kind of app for your needs. Whether you want to build a mobile application from scratch, redesign an existing app, or modernize your mobile app infrastructure, you’ll get secure, stable, high-quality results from our mobile app development team. Contact us to learn more."},
  {"id": 4, "heading": "How do you choose a mobile app development platform?", "data": "When it comes to mobile app development, businesses have three options: Native, Hybrid, and Web. Native apps are specific to one operating system, providing the best performance and user experience. Hybrid apps combine elements of both Native and Web, offering cross-platform compatibility and cost-effectiveness. Web apps are accessed through browsers and are simple to use without installation."},
  {"id": 5, "heading": "What programming language do you use to develop iOS/Android apps?", "data": "Several programming languages can be used to build Android and iOS apps. Our mobile app development team uses the best language and technology combination for your needs."}
]

const MobileAppDevelopment = () => {


  const [openId, setOpenId] = useState(null);
  const [activeMainTab, setActiveMainTab] = useState('IOS Apps');
  const [loading, setLoading] = useState(true);

  const [platForm, setPlatForm] = useState();
  const [selectDevice, setSelectDevice] = useState()
  const [stage, setStage] = useState();
  const [totalPlatoformPrice, setTotalPlatoformPrice] = useState()
  const [totalSelectDevicePrice, setTotalSelectDevicePrice] = useState()
  const [totalStagePrice, setTotalStagePrice] = useState()
  const [totalPrice, setTotalPrice] = useState(null)

  const [isScrolled, setIsScrolled] = useState(false);

  const toggleFaq = (id) => {
    setOpenId(openId === id ? null : id);
  };

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,  
    slidesToShow: 3,
    autoplaySpeed: 1000,
    slidesToScroll: 1,
    draggable: true, 
  };

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
  }




  useEffect(() => {
    const imagesToLoad = [
      MobxImg,
      MaterialImg,
      ReduxImg,
      FirebaseImg,
      webpackImg,
      vsCodeImg,
      JestImg,
      iosFintech,
      iosREALeSTATE,
      iosEcommerce,
      iosHealthCare,
      iosSupplyChain,
      iosLogistics,
      iosBankling,
      Clutch,
      ISO,
      Pasha,
      PSeb,
      contactUs,
      appDevSericesPic,
      pseudo,
      TestimonialImg,
    ];

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadImages = async () => {
      try {
        const promises = imagesToLoad.map((image) => loadImage(image));
        await Promise.all(promises);
        setLoading(false); 
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);


  const handleScroll = () => {
    if (window.scrollY > 230) {
        console.log("window.scrollY", window.scrollY)
        setIsScrolled(true);
    } else {
        setIsScrolled(false);
    }
  };


  const handlePlatformCheckboxChange = (value) => {
    setPlatForm(value);
  
    let platformPrice = 0;
  
    if (value === "IOS") {
      platformPrice = 35;
    } else if (value === "Android") {
      platformPrice = 30;
    } else if (value === "Cross-Platform") {
      platformPrice = 40;
    }
  
    setTotalPlatoformPrice(platformPrice);
  };
  
  const handleDeviceCheckboxChange = (value) => {
    setSelectDevice(value);
  
    let devicePrice = 0;
  
    if (value === "Mobile") {
      devicePrice = 4;
    } else if (value === "Tablet") {
      devicePrice = 5;
    } else if (value === "Watch") {
      devicePrice = 10;
    } else if (value === "Smart TV") {
      devicePrice = 12;
    }
  
    setTotalSelectDevicePrice(devicePrice);
  };
  
  const handleStageCheckboxChange = (value) => {
    setStage(value);
  
    let stagePrice = 0;
  
    if (value === "IOS") {
      stagePrice = 4;
    } else if (value === "Idea") {
      stagePrice = 5;
    } else if (value === "Prototype") {
      stagePrice = 10;
    } else if (value === "MVP") {
      stagePrice = 12;
    } else if (value === "Designed Solution") {
      stagePrice = 10;
    } else if (value === "Developed Application") {
      stagePrice = 12;
    }
  
    setTotalStagePrice(stagePrice);
  };
  
  const handleCalculateTotalPrice = () => {
    setTotalPrice(totalPlatoformPrice + totalSelectDevicePrice + totalStagePrice);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    AOS.init({ duration: 2000 }); 
    setLoading(false); 
    return ()=>{
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <> 

    {loading ? <Loader/> : (

      <div>
      
        <div className='Mobileappdev-container'>
          <div className={`Mobileappdev-nav  ${isScrolled ? 'Mobileappdev-nav-scrolled' : ''} `}>
            <Nav/>
          </div>
          <div className='mobile-app-dev-wrapper'>

            <div className='Bread-crum-mobileapp-dev'>
              <Link to='/'>Home</Link><IoIosArrowRoundForward />
              <p>Services</p><IoIosArrowRoundForward />
              <Link className='active-bread-crum-current-page'>Mobile App Development</Link>
            </div>

            <div className='mobile-app-hero-headings'>
              <p >
                MOBILE
                <br/>
                DEVELOPMENT
              </p>
              <span >Save time, money and resources with cross-platform app development</span>
                <Link to={"/contact"} >
                    Contact Us Today
                    <MdOutlineArrowForward size={22}/>
                </Link>
            </div>

            <div className='mobile-app-dev-hero-footer' > 
              <div>
                <p>400+</p>
                <span>Projects</span>
              </div>
              <div>
                <p>300+</p>
                <span>Mobile Tech Experts</span>
              </div>
              <div>
                <p>4.9/5</p>
                <span>On Clutch</span>
              </div>
            </div>

          </div>
        </div>

        {/* services section */}
        <div className='mobileappedev-think-legion'>
          <p>For a fresh mobile app, think Legions</p>
          <span>A well-crafted, intuitive UI makes it fast and easy for people to get what they need out of your product. The trick is making them love it.</span>
          
          <div className='mobileappedev-think-legion-section-1'>
            <div>
              <div><img src={img3} alt='img'/></div>
              <p>Fresh Thinking</p>
              <span>From insightful business analysis to innovative design and development, we deliver a fresh perspective on achieving your goals.</span>
            </div>
            <div>
              <div><img src={img5} alt='img'/></div>
              <p>The Latest Tech</p>
              <span>Get the latest mobile technology backed by years of experience in delivering custom mobile app development across a wide range of industries.</span>
            </div>
            <div>
              <div><img src={img6} alt='img'/></div>
              <p>Top Quality</p>
              <span>Whether you want to build a new mobile application or redesign an existing one, you’ll get a secure, stable, responsive app from our team.</span>
            </div>
            <div>
              <div><img src={img4} alt='img'/></div>
              <p>iOS App Development</p>
              <span>Take advantage of the full range of features and functionality offered by Apple devices with an app built specifically for iOS.</span>
            </div>
            <div>
              <div><img src={img1} alt='img'/></div>
              <p>Android App Development</p>
              <span>Leverage the capabilities of Android devices with an app built exclusively for this open-source platform.</span>
            </div>
            <div>
              <div><img src={img2} alt='img'/></div>
              <p>Cross-Platform Mobile Apps</p>
              <span>Get an app that looks great and works well on both Android and iOS devices, without having to maintain two separate versions.</span>
            </div>
          </div>
          
          <div className='mobileappedev-think-legion-section-2'>
            <p>Our Mobile App Services</p>
            <span>Native or hybrid, we build eye-popping apps for any device or platform. We won’t rest until you love your app.</span>
            <div className='mobileappedev-think-legion-section-2-part-1'>
              <div>
                <p>Consulting and Prototyping</p>
                <span>We offer consulting advice on the feasibility of your mobile application idea and can help you create a prototype that will bring your idea to life.</span>
                <br/>
                <br/>
                <span>This will enable potential investors and business partners to evaluate the ROI before committing resources to a full development project.</span>
                <div>
                  <p><GiCheckMark/>Business Goals</p>
                  <p><GiCheckMark/>User Requirements</p>
                  <p><GiCheckMark/>Market Research</p>
                </div>
              </div>
              <img src={img7} alt='img'/>
            </div>

            <div className='mobileappedev-think-legion-section-2-part-2'>
              <img src={img8} alt='img'/>
              <div>
                <p>Integration</p>
                <span>We can integrate new mobile applications to function seamlessly with your existing business and website tools.</span>
                <br/>
                <br/>
                <span>What’s more, we can adapt and re-engineer your business tools for mobile so your employees can access and submit information in real-time, whenever they want and wherever they are.</span>
                <div>
                  <p><GiCheckMark/>Information Architecture</p>
                  <p><GiCheckMark/>Wireframes</p>
                </div>
              </div>
            </div>

            <div className='mobileappedev-think-legion-section-2-part-1'>
              <div>
                <p>Native App Development</p>
                <span>Our developers design and build high-quality native apps for both iOS and Android systems that meet security and business requirements.</span>
                <br/>
                <br/>
                <span>Native apps allow you to offer your customers a feature-rich mobile application that works seamlessly with their device’s OS.</span>
                <div>
                  <p><GiCheckMark/>Visual Design</p>
                  <p><GiCheckMark/>Style Guide & Design Systems</p>
                  <p><GiCheckMark/>Prototype and Test</p>
                </div>
              </div>
              <img src={img9} alt='img'/>
            </div>

            
          </div>

        </div>
        

        {/* tech section */}
        <div className='Mobileappdev-tech-section'>
            <p >Technologies & Tools We Use</p>
            <div className='Mobileappdev-tech-section-container' >
              <div className='Mobileappdev-tech-tabs' >
                <button
                  className={activeMainTab === 'IOS Apps' ? 'Mobileappdev-tech-tabs-button-active' : ''}
                  onClick={() => handleMainTabClick('IOS Apps')}
                >IOS Apps</button>
                <button
                  className={activeMainTab === 'Android app' ? 'Mobileappdev-tech-tabs-button-active' : ''}
                  onClick={() => handleMainTabClick('Android app')}
                >Android apps</button>
                <button
                  className={activeMainTab === 'Cross-Platforms Engineering' ? 'Mobileappdev-tech-tabs-button-active' : ''}
                  onClick={() => handleMainTabClick('Cross-Platforms Engineering')}
                >Cross-Platforms Engineering</button>
              </div>
              {activeMainTab === "IOS Apps" && (
                <div className='Mobileappdev-tech-section-tabs-body' >
                  <div>
                    <span><img src={ReduxImg} alt='abc'/></span>
                    <p>Redux</p>
                  </div>
                  <div>
                    <span><img src={MobxImg} alt='abc'/></span>
                    <p>Mobx</p>
                  </div>
                  <div>
                    <span><img src={MaterialImg} alt='abc'/></span>
                    <p>Material UI</p>
                  </div>
                  <div>
                    <span><img src={JestImg} alt='abc'/></span>
                    <p>Jest</p>
                  </div>
                  <div>
                    <span><img src={vsCodeImg} alt='abc'/></span>
                    <p>Vs Code</p>
                  </div>
                  <div>
                    <span><img src={FirebaseImg} alt='abc'/></span>
                    <p>Firebase</p>
                  </div>
                  <div>
                    <span><img src={webpackImg} alt='abc'/></span>
                    <p>Web Pack</p>
                  </div>
                  <div>
                    <span><img src={ReduxImg} alt='abc'/></span>
                    <p>Redux</p>
                  </div>
                  <div>
                    <span><img src={MobxImg} alt='abc'/></span>
                    <p>Mobx</p>
                  </div>
                  <div>
                    <span><img src={MaterialImg} alt='abc'/></span>
                    <p>Material UI</p>
                  </div>
                  <div>
                    <span><img src={JestImg} alt='abc'/></span>
                    <p>Jest</p>
                  </div>
                  <div>
                    <span><img src={vsCodeImg} alt='abc'/></span>
                    <p>Vs Code</p>
                  </div>
                  <div>
                    <span><img src={FirebaseImg} alt='abc'/></span>
                    <p>Firebase</p>
                  </div>
                  <div>
                    <span><img src={webpackImg} alt='abc'/></span>
                    <p>Web Pack</p>
                  </div>
                </div>
              )}
              {activeMainTab === "Android app" && (
                <div className='Mobileappdev-tech-section-tabs-body' >
                  <div>
                      <span><img src={ReduxImg} alt='abc'/></span>
                      <p>Redux</p>
                    </div>
                    <div>
                      <span><img src={MobxImg} alt='abc'/></span>
                      <p>Mobx</p>
                    </div>
                    <div>
                      <span><img src={MaterialImg} alt='abc'/></span>
                      <p>Material UI</p>
                    </div>
                    <div>
                      <span><img src={JestImg} alt='abc'/></span>
                      <p>Jest</p>
                    </div>
                    <div>
                      <span><img src={vsCodeImg} alt='abc'/></span>
                      <p>Vs Code</p>
                    </div>
                </div>
              )}
              {activeMainTab === "Cross-Platforms Engineering" && (
                <div className='Mobileappdev-tech-section-tabs-body' >
                  <div>
                      <span><img src={ReduxImg} alt='abc'/></span>
                      <p>Redux</p>
                    </div>
                    <div>
                      <span><img src={MobxImg} alt='abc'/></span>
                      <p>Mobx</p>
                    </div>
                    <div>
                      <span><img src={JestImg} alt='abc'/></span>
                      <p>Jest</p>
                    </div>
                    <div>
                      <span><img src={vsCodeImg} alt='abc'/></span>
                      <p>Vs Code</p>
                    </div>
                </div>
              )}
            </div>

            <Link className='Mobileappdev-tech-section-button' to={"/talk-to-us"} >
                Request Services
              <MdOutlineArrowForward size={22}/>
            </Link>
        </div>

        {/* ios dev solution section */}
        <div className='Mobileappdev-ios-dev-section'>
          <p >
            Our iOS Development Solutions
            <br/>
            For Different
            <span> Industries</span> 
          </p>
          <div className='Mobileappdev-ios-dev-section-items' >
            <div>
              <img src={iosFintech} alt='abc'/>
              <p>Fintech</p>
            </div>
            <div>
              <img src={iosREALeSTATE} alt='abc'/>
              <p>Real Estate</p>
            </div>
            <div>
              <img src={iosEcommerce} alt='abc'/>
              <p>E-Commerce</p>
            </div>
            <div>
              <img src={iosHealthCare} alt='abc'/>
              <p>Healthcare</p>
            </div>
            <div>
              <img src={iosSupplyChain} alt='abc'/>
              <p>Supply Chain</p>
            </div>
            <div>
              <img src={iosLogistics} alt='abc'/>
              <p>Logistics</p>
            </div>
            <div>
              <img src={iosBankling} alt='abc'/>
              <p>Banking</p>
            </div>
          </div>
        </div>

        {/* Request the price */}
        <div className='Mobileappdev-request-for-price-section'>
          <p >Request the price of  <span>
              Mobile App
              <br/>
              Development
          </span> Services</p>

          <div className='Mobileappdev-request-for-price-section-container'>
              <div className='Mobileappdev-request-for-price-section-wrapper' >
                <p>01. Platform</p>
                <div>
                  <div>
                    <input type='checkbox' checked={platForm === 'IOS'} onChange={() => handlePlatformCheckboxChange('IOS')}/>
                    <p>IOS</p>
                  </div>
                  <div>
                    <input type='checkbox' checked={platForm === 'Android'} onChange={() => handlePlatformCheckboxChange('Android')}/>
                    <p>Android</p>
                  </div>
                  <div>
                    <input type='checkbox' checked={platForm === 'Cross-Platform'} onChange={() => handlePlatformCheckboxChange('Cross-Platform')}/>
                    <p>Cross-Platform</p>
                  </div>
                </div>
              </div>
              <div className='Mobileappdev-request-for-price-section-wrapper' >
                <p>02. Device</p>
                <div>
                  <div>
                    <input type='checkbox' checked={selectDevice === 'Mobile'} onChange={() => handleDeviceCheckboxChange('Mobile')}/>
                    <p>Mobile</p>
                  </div>
                  <div>
                    <input type='checkbox'  checked={selectDevice === 'Tablet'} onChange={() => handleDeviceCheckboxChange('Tablet')}/>
                    <p>Tablet</p>
                  </div>
                  <div>
                    <input type='checkbox'  checked={selectDevice === 'Watch'} onChange={() => handleDeviceCheckboxChange('Watch')}/>
                    <p>Watch</p>
                  </div>
                  <div>
                    <input type='checkbox'  checked={selectDevice === 'Smart TV'} onChange={() => handleDeviceCheckboxChange('Smart TV')}/>
                    <p>Smart TV</p>
                  </div>
                </div>
              </div>
          </div>
          <div className='Mobileappdev-request-for-price-section-container'>
            <div className='Mobileappdev-request-for-price-section-wrapper Mobileappdev-request-for-price-section-wrapper-mod3' >
                  <p>03. Stage</p>
                  <div>
                    <div>
                      <input type='checkbox' checked={stage === 'IOS'} onChange={() => handleStageCheckboxChange('IOS')}/>
                      <p>IOS</p>
                    </div>
                    <div>
                      <input type='checkbox' checked={stage === 'Idea'} onChange={() => handleStageCheckboxChange('Idea')}/>
                      <p>Idea</p>
                    </div>
                    <div>
                      <input type='checkbox' checked={stage === 'Prototype'} onChange={() => handleStageCheckboxChange('Prototype')}/>
                      <p>Prototype / Specification</p>
                    </div>
                    <div>
                      <input type='checkbox' checked={stage === 'MVP'} onChange={() => handleStageCheckboxChange('MVP')}/>
                      <p>MVP</p>
                    </div>
                    <div>
                      <input type='checkbox' checked={stage === 'Designed Solution'} onChange={() => handleStageCheckboxChange('Designed Solution')}/>
                      <p>Designed Solution</p>
                    </div>
                    <div>
                      <input type='checkbox' checked={stage === 'Developed Application'} onChange={() => handleStageCheckboxChange('Developed Application')}/>
                      <p>Developed Application</p>
                    </div>
                  </div>
            </div>
          </div>
          <div className='Mobileappdev-request-for-price-section-button' >
                <p>Select the checkbox to get price</p>
                {stage && selectDevice && platForm  ? (
                  <button onClick={handleCalculateTotalPrice}>Calculate</button>
                ) : ""}
                {totalPrice !== null ? (
                  <p>{totalPrice}$ per hour</p>
                ): ''}
              {/* <MdOutlineArrowForward size={22}/> */}
          </div>
        </div>
        
        {/* What It Looks Like */}
        <div className='Mobileappdev-looks-like'>
          <p>What It Looks Like</p>
          <div className='Mobileappdev-looks-like-section-1'>
            <div>
              <p>Market analysis to understand your goals and users</p>
            </div>
            <div><p>Collaboration and communication to stay in sync</p></div>
            <div><p>Rapid prototyping to test and validate concepts</p></div>
            <div><p>Iterative development to get you to market fast</p></div>
          </div>
          <p>Our Process</p>
          <div className='Mobileappdev-looks-like-section-2'>
            <Slider {...settings}>
              <div className='Mobileappdev-looks-like-section-2-part-1'>
                <p>1</p>
                <p>Analysis</p>
                <span>To explore your vision, customers, and the problem to be solved.</span>
              </div>
              <div className='Mobileappdev-looks-like-section-2-part-1'>
                <p>2</p>
                <p>Design</p>
                <span>To craft the look, feel, and flow of your product.</span>
              </div>
              <div className='Mobileappdev-looks-like-section-2-part-1'>
                <p>3</p>
                <p>Development</p>
                <span>To iteratively build the perfect product to meet your goals.</span>
              </div>
              <div className='Mobileappdev-looks-like-section-2-part-1'>
                <p>4</p>
                <p>Release</p>
                <span>To get your finished product out to your customers.</span>
              </div>
              <div className='Mobileappdev-looks-like-section-2-part-1'>
                <p>5</p>
                <p>Support</p>
                <span>To maintain and improve your product over time.</span>
              </div>
            </Slider>
          </div>
          <Link>Contact Us</Link>
        </div>

        <div className='Mobileappdev-portfolio'>
          <p>Our work speaks for itself ...</p>
          <span>Our design-first approach has helped transform innovative businesses like these.</span>
          <div>
            <Link>
              <img src={img10} alt='img'/>
              <span>Product Development, Digital Marketing</span>
              <p>CookinGenie became the leading at-home chef service in its state.</p>
            </Link>
            <Link>
              <img src={img10} alt='img'/>
              <span>Product Development, Digital Marketing</span>
              <p>CookinGenie became the leading at-home chef service in its state.</p>
            </Link>
            <Link>
              <img src={img10} alt='img'/>
              <span>Product Development, Digital Marketing</span>
              <p>CookinGenie became the leading at-home chef service in its state.</p>
            </Link>
            <Link>
              <img src={img10} alt='img'/>
              <span>Product Development, Digital Marketing</span>
              <p>CookinGenie became the leading at-home chef service in its state.</p>
            </Link>
          </div>
        </div>


        {/* top rated section */}
        <div className='Mobileappdev-top-rated-section'>
            <p >Top-rated mobile development company</p>
            <div >
              <img src={Clutch} alt='abc'/>
              <img src={ISO} alt='abc'/>
              <img src={Pasha} alt='abc'/>
              <img src={PSeb} alt='abc'/>
            </div>
        </div>

        <div className='Mobileappdev-faq'>
              <p>In case you're wondering ...</p>
              <div className='Mobileappdev-faq-wrapper'>
                {FAQDATA.map((data) => (
                  <div className='Mobileappdev-faq-container'>
                    <div key={data.id} onClick={() => toggleFaq(data.id)}>
                      <p>{data.heading}</p>
                      {openId === data.id ? <LuMinus size={24}/> : <GoPlus size={24}/>}
                    </div>
                    {openId === data.id && <span>{data.data}</span>}
                  </div>
                ))}
              </div>
        </div>

        {/* contactus section */}
        <ContactUs/>

        <Footer/>
      </div>
    )}
    
    </>
  )
}

export default MobileAppDevelopment