import React, { useState } from 'react'
import Nav from '../NavBar/Nav'
import Footer from '../Footer/Footer'
import './Contract.css'
import ContactUs from '../ContactUs/ContactUs'

const Contract = () => {
  

  return (
    <div className='contact-page-container'>

      <div className='contact-nav'>
        <Nav/>
      </div>

      <div className='contact-page-form'>
        <ContactUs/>
      </div>



      <Footer/>

    </div>
  )
}

export default Contract