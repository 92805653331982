import React from 'react'
import { IoLogoWhatsapp } from "react-icons/io";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import OdooPart2 from '../../OdooAssets/Group 12.png'
import icon1 from '../../OdooAssets/Icons/icon-1 1.png'
import icon2 from '../../OdooAssets/Icons/icon-2 1.png'
import icon3 from '../../OdooAssets/Icons/icon-3.png'
import icon4 from '../../OdooAssets/Icons/icon-4.png'
import icon5 from '../../OdooAssets/Icons/icon-5.png'
import icon6 from '../../OdooAssets/Icons/icon-6.png'
import icon7 from '../../OdooAssets/Icons/stock 1.png'
import icon8 from '../../OdooAssets/Icons/machine 1.png'
import icon9 from '../../OdooAssets/Icons/cargo 1.png'
import icon10 from '../../OdooAssets/Icons/patient 1.png'
import icon11 from '../../OdooAssets/Icons/hairstyle 1.png'
import icon12 from '../../OdooAssets/Icons/agreement 1.png'
import icon13 from '../../OdooAssets/Icons/customer-service-(1).png'
import icon14 from '../../OdooAssets/Icons/presentation.png'
import img1Part8 from '../../OdooAssets/c-sap 1.png'
import img2Part8 from '../../OdooAssets/c-community 1.png'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import Footer from '../Footer/Footer'


import './OdooImplementation.css'
import NavScrolled from '../NavBar/NavScrolled';

const OdooImplementation = () => {
  return (
    <div className='OdooImplementation'>

      <NavScrolled/>

      <div className='OdooImplementation-header'>
        <p>Fast-track Your Odoo Implementation</p>
        <span>Seeking swift deployment?</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooImplementation-part-2-container'>
        <div className='OdooImplementation-part-2'>
          <div>
            <p>Odoo Implementation</p>
            <span>
              Implementing Odoo is a complex endeavour that demands expertise to navigate potential challenges effectively. At Legions Soft, we offer seamless Odoo platform implementation tailored precisely to your business requirements. With our extensive experience and refined methodology, we ensure a smooth and efficient implementation process:
            </span>
            <div>
              <p><span>Comprehensive Analysis:</span> We begin by thoroughly analysing your business needs and requirements to determine the optimal Odoo configuration.</p>
              <p><span>Platform Development:</span> Our team of experts meticulously develops the Odoo platform, customising it to align perfectly with your business processes.</p>
              <p><span>Configuration:</span> Leveraging best practices and standardised procedures, we configure the Odoo platform to seamlessly integrate with your existing operations.</p>
              <p><span>Training:</span> We provide comprehensive training sessions to equip your team with the necessary skills to operate the Odoo platform effectively.</p>
              <p><span>Ongoing Support:</span> Our commitment doesn't end with implementation. We offer continuous support and maintenance services to ensure the long-term success of your Odoo platform deployment.</p>
            </div>
          </div>
          <img src={OdooPart2} alt='OdooPart2'/>
        </div>
      </div>

      <div className='OdooImplementation-part-3'>
      <div>
        <img src={icon1} alt='icon1'/>
        <p>Thorough Analysis</p>
        <span>
          Our journey begins with a comprehensive analysis of your business requirements, meticulously identifying the optimal Odoo configuration to support your operations.
        </span>
      </div>
      <div>
        <img src={icon2} alt='icon1'/>
        <p>Precise Development</p>
        <span>
        Our team of seasoned experts dedicates meticulous attention to detail in crafting your Odoo platform, customising it to seamlessly align with your specific business processes and workflows.
        </span>
      </div>
      <div>
        <img src={icon3} alt='icon1'/>
        <p>Strategic Configuration</p>
        <span>
        Leveraging industry best practices and standardised procedures, we meticulously configure the Odoo platform to seamlessly integrate with your existing operational infrastructure.
        </span>
      </div>
      <div>
        <img src={icon4} alt='icon1'/>
        <p>Comprehensive Training</p>
        <span>
        We offer comprehensive training sessions designed to empower your team with the requisite skills and knowledge to effectively navigate and utilise the Odoo platform.
        </span>
      </div>
      <div>
        <img src={icon5} alt='icon1'/>
        <p>Continuous Support</p>
        <span>
          Our commitment extends beyond implementation, as we provide ongoing support and maintenance services to ensure the sustained success and optimal performance of your Odoo deployment.
        </span>
      </div>
      <div>
        <img src={icon6} alt='icon1'/>
        <p>Continuous Improvement</p>
        <span>
        We embrace a culture of continuous improvement, proactively seeking opportunities to enhance and refine your Odoo implementation over time, ensuring it remains aligned with evolving business needs and technological advancements
        </span>
      </div>
      </div>

      <div className='OdooImplementation-part-6-container'>
          <p>Why Legions Soft</p>
          <span>Our team is here to help you identify any technical problems you're experiencing.</span>
          <div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> High coding standards</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Expert in all versions of Odoo</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 8+ years of experience</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 900+ satisfied customers across the globe</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 790+ Free modules contributions in Odoo apps</p>
            </div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> Ranks Fifth in Odoo app downloads</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High-quality deliverables</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Technical & Functional expertise in all Odoo modules</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Service in both enterprise & community editions</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High quality deliverables</p>
            </div>
          </div>
      </div>

      <div className='OdooImplementation-part-4-container'>
        <div className='OdooImplementation-part-4'>
          <p>Odoo ERP Implementation Services</p>
          <span>
            For a successful business, implementing ERP correctly is crucial, and your choice of implementation partner significantly impacts the outcome. With over 8 years of expertise, Legions Soft stands as a premier expert in Odoo implementation, integration, support, customization, and migration. As an esteemed Odoo Partner, we excel in crafting effective Odoo modules, boasting quality code, leading contributions to Odoo Apps, and unparalleled client satisfaction.
          </span>
        </div>
      </div>

      <div className='OdooImplementation-part-5'>
        <p>Chat with us for more information</p>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooImplementation-part-7'>
        <p>Business Industries in Odoo</p>
        <span>Each module performs certain functions which are interlinked with each other.</span>
        <div className='OdooImplementation-part-7-images'>
          <div>
            <span><img src={icon7} alt='icon7'/> </span>
            <p>Trading</p>
          </div>
          <div>
            <span><img src={icon8} alt='icon7'/> </span>
            <p>Embroidery</p>
          </div>
          <div>
            <span><img src={icon9} alt='icon7'/> </span>
            <p>Logistics</p>
          </div>
          <div>
            <span><img src={icon10} alt='icon7'/> </span>
            <p>Healthcare</p>
          </div>
          <div>
            <span><img src={icon11} alt='icon7'/> </span>
            <p>Salon Management</p>
          </div>
          <div>
            <span><img src={icon12} alt='icon7'/> </span>
            <p>Real Estate</p>
          </div>
          <div>
            <span><img src={icon13} alt='icon7'/> </span>
            <p>Education</p>
          </div>
          <div>
            <span><img src={icon14} alt='icon7'/> </span>
            <p>Whole Sale</p>
          </div>
        </div>
      </div>

      <div className='OdooImplementation-part-8-container'>
        <div className='OdooImplementation-part-8'>
          <p>Odoo Comparison</p>
          <span>Our in-house team of highly skilled developers and designers, proficient in diverse programming languages, ensures comprehensive support throughout the Odoo implementation process. We pride ourselves on our ability to deliver top-notch solutions tailored to your unique business needs, providing meticulous attention to detail and unwavering commitment to excellence.</span>
          <div className='OdooImplementation-part-8-compare'>
            <div>
              <img src={img1Part8} alt='img1Part8'/>
              <p>Odoo Vs SAP</p>
            </div>
            <div>
              <img src={img2Part8} alt='img1Part8'/>
              <p>Odoo Community Vs Enterprise</p>
            </div>

          </div>
        </div>
      </div>

      <div className='OdooImplementation-part-9'>
        <p>Our Odoo Services</p>
        <div className='OdooImplementation-part-9-items'>
          <div>
            <span><img src={icon15} alt='icon15'/></span>
            <p>Odoo Customization</p>
          </div>
          <div>
            <span><img src={icon16} alt='icon15'/></span>
            <p>Odoo Implementation</p>
          </div>
          <div>
            <span><img src={icon17} alt='icon15'/></span>
            <p>Odoo Integration</p>
          </div>
          <div>
            <span><img src={icon18} alt='icon15'/></span>
            <p>OOdoo Support & Maintenance</p>
          </div>
          <div>
            <span><img src={icon19} alt='icon15'/></span>
            <p>Odoo Migration</p>
          </div>
          <div>
            <span><img src={icon20} alt='icon15'/></span>
            <p>Odoo Training</p>
          </div>
          <div>
            <span><img src={icon21} alt='icon15'/></span>
            <p>Odoo Consultancy</p>
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooImplementation