import React from 'react'
import './OdooERP.css'
import Nav from '../NavBar/Nav'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import img1 from '../../OdooAssets/OdooNewAssets/Group 13 4353453.png'
import img2 from '../../OdooAssets/OdooNewAssets/odoo-enterprise-vs-community (1) 1.png'
import img3 from '../../OdooAssets/OdooNewAssets/odoo-erp 1.png'
import icon1 from '../../OdooAssets/OdooNewAssets/stock 3.png'
import icon2 from '../../OdooAssets/OdooNewAssets/machine 3.png'
import icon3 from '../../OdooAssets/OdooNewAssets/cargo 3.png'
import icon4 from '../../OdooAssets/OdooNewAssets/patient 3.png'
import icon5 from '../../OdooAssets/OdooNewAssets/hairstyle 3.png'
import icon6 from '../../OdooAssets/OdooNewAssets/agreement 3.png'
import icon7 from '../../OdooAssets/OdooNewAssets/presentation.png'
import icon8 from '../../OdooAssets/OdooNewAssets/customer-service-(1).png'
import icon9 from '../../OdooAssets/Icons/7737365 1.png'
import icon10 from '../../OdooAssets/Icons/7034576 1.png'
import icon11 from '../../OdooAssets/Icons/7100768 1.png'
import icon12 from '../../OdooAssets/Icons/Icon-for-sales-1-1 1.png'
import icon13 from '../../OdooAssets/Icons/website-clipart-web-icon-4 1.png'
import icon14 from '../../OdooAssets/Icons/8764182 1.png'
import icon15 from '../../OdooAssets/Icons/4990622 1.png'
import icon16 from '../../OdooAssets/Icons/new-project-icon-13 1.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const OdooERP = () => {
  return (
    <div className='OdooERP'>

        <NavScrolled/>

        <div className='OdooERP-header'>
            <p>The Leading Open-Source Solution</p>
            <span>Odoo ERP stands out as the premier open-source ERP, trusted by over 12 million users worldwide. It offers a unified platform adaptable to diverse business needs.</span>
        </div>

        <div className='OdooERP-part-1'>
            <p>What is Odoo ERP System?</p>
            <span>
                Odoo launched as TinyERP in 2005 and its subsequent rebranding as Odoo starting with Version 8, Odoo has developed into a feature-rich business management platform. Its modular design allows it to effectively manage a range of business tasks, and starting with Version 9, it offers community and enterprise editions to meet a variety of operating requirements. The most recent version, version 16, is known for its cutting-edge features and remarkable speed.                
            <br/><br/>
            </span>
            <p>Why is Odoo ERP a Leading Business Solution?</p>
            <span>
                Today with more than 12 million users all across the world Odoo ERP is stretched to become one of the most used business management software, all tracks to the reliability, efficiency, and cost-effectiveness in operations. One of the key reasons for this growth is the open-source feature of the Odoo ERP making it useful for all. Odoo also provides 40560 plus add-on modules including both paid and free apps, which provides additional features to the existing one and thus grow your business a step forward in ease of operations.
            </span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
        </div>

        <div className='OdooERP-part-2-container'>
            <div className='OdooERP-part-2'>
            <div>
                <p>What makes Odoo ERP stand out from its competitors?</p>
                <span>
                    Odoo is an open-source business management ERP that is considered one of the advanced and reliable tools for business operations management. Here are certain aspects of the Odoo platform which makes it stand out:
                </span>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Absence of vendor lock-in, providing flexibility and freedom for users.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Proven track record with over 12 million users worldwide.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Comprehensive suite of business management features catering to diverse needs.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Extensive library of over 43,900 supporting add-ons available through the Odoo apps store.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Active and supportive community dedicated to enhancing the platform for users' benefit.</p>
                </div>
            </div>
            <img src={img1} alt='img1'/>
            </div>
        </div>

        <div className='OdooERP-part-3'>
            <p>Why Opt for Odoo ERP?</p>
            <span>
            Odoo ERP offers a meticulously designed modular structure, tailored to streamline business operations with precision. Its application-specific modules seamlessly integrate to deliver reliable, efficient, and cost-effective management solutions. Furthermore, Odoo ERP adopts a centralised approach, employing a single inventory management system to oversee retail, wholesale, and e-commerce operations seamlessly. With a centralised database housing all company information, internal and external communication channels are optimised for effective collaboration.
            </span>
        </div>

        <div className='OdooERP-part-4-container'>
            <div className='OdooERP-part-4'>
            <div>
                <p>Odoo ERP enterprise Vs community editions:</p>
                <span>
                Each catering to different business needs based on factors such as scale, user requirements, and organisational structure. Here's a comprehensive comparison of the operational features between the Odoo Enterprise and Community editions, providing insights into their respective capabilities and functionalities.
                </span>
                <div>
                    <Link>Odoo Community Features </Link>
                    <Link>Odoo Enterprise Features </Link>
                </div>
            </div>
            <img src={img2} alt='img1'/>
            </div>
        </div>

        <div className='OdooERP-part-5'>
            <p>How does Odoo compare to others in the market?</p>
            <img src={img3} alt='img2'/>
        </div>

        <div className='OdooERP-part-8'>
            <p>Odoo   modules</p>
            <span>Each module within the Odoo ERP fulfils specific functions, working seamlessly together to accomplish business objectives.</span>
            <div className='OdooERP-part-8-container'>
                <div>
                    <div>
                        <img src={icon9} alt='icon'/>
                        <p>Odoo ERP Accounting Module:</p>
                    </div>
                    <p>The accounting module within Odoo serves as a comprehensive tool for managing all financial aspects of your company. Integrated directly with invoicing, sales, purchasing, and other supporting modules, it enables you to define taxes, payment methods, and options, manage payment acquirers, set fiscal year parameters, and more, ensuring efficient financial management.</p>
                </div>

                <div>
                    <div>
                        <img src={icon10} alt='icon'/>
                        <p>Odoo ERP Invoicing Module:</p>
                    </div>
                    <p>The invoicing module in Odoo handles the invoicing procedures for product and service sales. Integrated seamlessly with accounting, sales, e-commerce, POS, and other modules, it facilitates various aspects of invoice generation, management, payment assignment, and customization to align with company operational requirements.</p>
                </div>

                <div>
                    <div>
                        <img src={icon11} alt='icon'/>
                        <p>Odoo ERP CRM Module:</p>
                    </div>
                    <p>Customer Relationship Management (CRM) is a cornerstone of effective company operations, and Odoo offers a dedicated module to fulfil this need. With the CRM module, you can efficiently generate, enrich, and manage leads, as well as track activities and opportunities associated with them. Additionally, the module integrates seamlessly with marketing operations, enhancing lead nurturing capabilities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon12} alt='icon'/>
                        <p>Odoo ERP Sales Module:</p>
                    </div>
                    <p>The sales module within Odoo ERP serves as the central hub for all sales operations within the company. From generating and sending sales quotations to managing the sales process and providing detailed product descriptions to customers, this module streamlines the entire sales workflow. It seamlessly integrates with other key modules such as e-commerce, POS, invoicing, accounting, inventory, and purchasing, ensuring smooth and efficient sales operations across the organisation.</p>
                </div>

                <div>
                    <div>
                        <img src={icon13} alt='icon'/>
                        <p>Odoo ERP Website Module:</p>
                    </div>
                    <p>The Odoo ERP's website module provides comprehensive tools for businesses to create, design, configure, and manage their company websites effectively. Integrated with an intuitive website builder, this module facilitates seamless e-commerce operations and ensures efficient management of online presence and functionalities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon14} alt='icon'/>
                        <p>Odoo ERP E-Commerce Module:</p>
                    </div>
                    <p>The E-commerce module within Odoo ERP is integral for modern businesses, facilitating the establishment of robust online platforms. With comprehensive features and tools embedded in the website module, Odoo enables businesses to effectively describe, operate, and manage their e-commerce ventures, including product sales and operational functionalities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon15} alt='icon'/>
                        <p>Odoo ERP POS Module:</p>
                    </div>
                    <p>The Odoo Point of Sale (POS) module is a comprehensive retail sales management tool integrated within the Odoo platform. It offers seamless functionality for in-store transactions, providing direct invoicing capabilities. Additionally, the module facilitates operations for bars and restaurants, enabling efficient management in live environments.</p>
                </div>

                <div>
                    <div>
                        <img src={icon16} alt='icon'/>
                        <p>Odoo ERP Project Module:</p>
                    </div>
                    <p>The Odoo project module serves as a comprehensive tool for creating and managing projects within a company, facilitating seamless project management. Integrated with other operational modules of Odoo ERP, it enhances project operational efficiency and management capabilities across the organisation.</p>
                </div>

            </div>
        </div>

        <div className='OdooERP-part-6'>
            <div className='OdooERP-part-6-container'>
                <p>
                    Business Industries Odoo Support
                </p>
                <span>
                The modules within Odoo ERP are intricately interconnected, each serving specific functions that collectively contribute to the seamless operation of various business industries.
                </span>

                <div className='OdooERP-part-6-content'>
                    <div>
                        <img src={icon1} alt='icon1'/>
                        <p>Trading</p>
                    </div>
                    <div>
                        <img src={icon2} alt='icon1'/>
                        <p>Embroidery</p>
                    </div>
                    <div>
                        <img src={icon3} alt='icon1'/>
                        <p>Logistics</p>
                    </div>
                    <div>
                        <img src={icon4} alt='icon1'/>
                        <p>Healthcare</p>
                    </div>
                    <div>
                        <img src={icon5} alt='icon1'/>
                        <p>Salon Management</p>
                    </div>
                    <div>
                        <img src={icon6} alt='icon1'/>
                        <p>Real Estate</p>
                    </div>
                    <div>
                        <img src={icon7} alt='icon1'/>
                        <p>Education</p>
                    </div>
                    <div>
                        <img src={icon8} alt='icon1'/>
                        <p>Whole Sale</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='OdooERP-part-7-container'>
          <p>Why Choose Legions Soft for Your Odoo Needs?</p>
          <span>Our team at Legions Soft is dedicated to assisting you in diagnosing and resolving any technical challenges you may encounter. Here's why you should choose us:</span>
          <div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> Adherence to high coding standards ensures top-notch solutions.</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Proficient in all versions of Odoo, providing comprehensive support.</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Commitment to delivering high-quality results consistently.</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Over 8 years of industry experience, ensuring seasoned expertise.</p>
            </div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> A track record of satisfying over 900 customers worldwide.</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Offering services for both enterprise and community editions.</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Ranked fifth in Odoo app downloads</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Contributed over 790 free modules to the Odoo apps ecosystem.</p>
            </div>
          </div>
        </div>

        <Footer/>

    </div>
  )
}

export default OdooERP
