import React from 'react'
import Nav from '../NavBar/Nav'
import './GraphicsDesign.css'
import { GoDotFill } from "react-icons/go";
import img1 from '../../MarketingAssets/Tools-we-use-Graphic-Design-Services-Colorwhistle 1.png'
import img2 from '../../MarketingAssets/Thick Arrow Pointing Down black.png'
import img3 from '../../MarketingAssets/Our-Design-Process-Colorwhistle 1.png'
import img4 from '../../MarketingAssets/One-Stop-Graphic-Solution-on-Marketing-Materials-and-Digital-Creatives-for-All-Niche-Colorwhistle 1.png'
import img5 from '../../MarketingAssets/Project-Engagement-Models-ColorWhistle 1.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled';



const GraphicsDesign = () => {
  return (
    <div className='GraphicsDesign-container'>


        <NavScrolled/>

        <div className='GraphicsDesign-part-1'>
            <p>Graphic Design Services Company</p>
            <span>
                ColorWhistle is a creative and professional graphic design company that specializes in crafting unique digital designs to make your business stand out. Our team works quickly to deliver high-quality graphics that are sure to make an impact.
                <br/>
                Graphic design encompasses a wide range of creative and visual elements, including but not limited to: Branding and Identity, Print Design, Web Design, Digital Design, UI/UX Design, Illustration, Motion Graphics, AI generative graphics and much more.
            </span>
        </div>


        <div className='GraphicsDesign-part-2'>
            <p>Our Graphic Design Company Provides Professional Expertise and Top-quality Graphic Design Services</p>
            <div>
                <p>FAST & Creative Graphic Design Services</p>
                <p>Logo Design to Brand Marketing Graphics</p>
                <p>Website & Mobile UI/UX Graphic Design Services</p>
                <p>Digital Ads & Social Media Graphic Design Services</p>
            </div>
        </div>


        <div className='GraphicsDesign-part-3'>
            <div className='GraphicsDesign-part-3-section'>
                <p>Transform Your Website with Our Custom Graphic Design Services</p>
                <span>
                    We have an excellent team of web designers who are capable of creating innovative and high-performance website development of any complexity
                </span>
                <span>1. Experience outstanding and attention grabbing visuals for your business</span>
                <span>2. Develop a great impression on your prospects mind</span>
                <span>3. Establish a bright profile for your business online</span>
            </div>
            <img src={img1} alt='img'/>
        </div>


        <div className='GraphicsDesign-part-4'>
            <p>Optimize, Drive Engagement, and Increase Customer Base to Your Website with Our Eye Catching Graphic Designs</p>
            <div>
                <div>
                    <p>Ready to Unlock Your Graphic Design Business’s Full Potential ?- Get Partnered with ColorWhistle</p>
                    <span><GoDotFill/> Served at affordable graphic design price</span>
                    <span><GoDotFill/> Professional and unique graphic designs</span>
                    <span><GoDotFill/> Collaboration and consultation with design experts</span>
                    <span><GoDotFill/> Regular follow ups & streamlined communication</span>
                    <span><GoDotFill/> Timely delivery of the project</span>
                </div>
            </div>
        </div>

        <div className='GraphicsDesign-part-5'>
            <div>
                <p>OUR PROCESS</p>
                <img src={img2} alt='icon'/>
            </div>
            <img src={img3} alt="img"/>
        </div>


        <div className='GraphicsDesign-part-6'>
            <div>
                <p>One-Stop Graphic Design Services on Marketing Materials and Digital Creatives for All Niche – Whom Do We Work for?</p>
                <span>
                    Expert Graphic Design Services for a Wide Range of Industries. Our team of experienced graphic designers is here to help you stand out in your industry and drive business growth. Make a statement with professionally designed graphics that reflect your brand’s personality.
                    <br/>
                    We specialize in creating custom solutions for the travel, hotel & restaurant, education, healthcare, and real estate sectors. We can also help you with intended output based on marketing goals.
                </span>
            </div>
            <img src={img4} alt='img'/>
        </div>

        <div className='GraphicsDesign-part-7'>
            <p>Time-Efficient Graphic Design Services</p>
            <span>Creative Designing From a Graphic Design Services Company For Website Design Graphics, Graphic Design Posters, Graphic Design Logos, Sports & Car Graphic Designs</span>

            <div className='GraphicsDesign-part-7-section-1'>
                <div>
                    <p>Are You An Agency Looking for B2B Outsourcing Partnership for Graphic Design Services?</p>
                    <span>
                        Our graphic design services offer a one-stop solution for your outsourcing design needs. Our team of professionals will take care end-to-end, allowing you to focus on what you do best. Don’t let lack of design resources hold you back. Partner with us and watch your business thrive with top-quality graphics.
                    </span>
                </div>
                <div>
                    <p>Are You Looking For White-Label Graphic Design Service Partner?</p>
                    <span>
                    Our white-label graphic design services are here to help. Our team of skilled professionals will handle all of your design needs, freeing up your time to focus on the core aspects of your business. Enhance your business’s potential and turn heads with professional, top-quality graphics. Our team of skilled designers can handle all of your design needs, eliminating the burden of limited in-house resources.
                    </span>
                </div>
                <div>
                    <p>Are You Just Looking For Professional Graphic Design Services For Your Website?</p>
                    <span>
                        Our user-centric solutions are here to help. We craft designs with the ultimate user experience in mind, ensuring that your audience stays hooked on your brand. Transform your visual identity with our custom graphic design solutions. We help you to win over new and current audiences with our top-notch graphic designs.
                    </span>
                </div>
            </div>
            <img src={img5} alt="img"/>
        </div>

        <div className='GraphicsDesign-part-9'>
            <p>So, what are you waiting for?</p>
            <span>
                We’re ready to get social with you, so send us a message at contact@legionssoft.com or call us at -339-4010310 to start your journey with one of Australian leading social media agencies.
            </span>
        </div>

        <Footer/>

    </div>
  )
}

export default GraphicsDesign