import React from 'react'

import './OdooIntegration.css'
import Nav from '../NavBar/Nav'
import img1 from '../../OdooAssets/OdooNewAssets/fdfd 1.png'
import img2 from '../../OdooAssets/OdooNewAssets/LEGION SOFT (15) 1.png'
import img3 from '../../OdooAssets/OdooNewAssets/Odoo-erp-integraion 1.png'
import img4 from '../../OdooAssets/OdooNewAssets/Group 13 (1) asdsadadsad.png'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import { MdEmail } from "react-icons/md";
import Logo1 from '../../OdooAssets/Icons/wire-transfer-logo 1.png'
import Logo2 from '../../OdooAssets/Icons/Stripe-Logo 1.png'
import Logo3 from '../../OdooAssets/Icons/cash-on-delivery-1 1.png'
import Logo4 from '../../OdooAssets/Icons/Forward Button.png'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const OdooIntegration = () => {
  return (
    <div className='OdooIntegration'>

        <NavScrolled/>

        <div className='OdooIntegration-part-1'>
            <div>
                <p>Ready to streamline your business with Odoo ERP integration? </p>
                <span>Connect your Odoo platform seamlessly with your everyday tools and applications, propelling your business forward.</span>
                <Link><IoLogoWhatsapp/>Whatsapp</Link>
            </div>
            <img src={img1} alt='img'/>
        </div>


        <div className='OdooIntegration-part-2'>
            <div>
                <p>Optimise Business Processes with Legions Soft's Odoo Integration</p>
                {/* <span>Odoo Integration services</span> */}
                <span>
                    Legions Soft's Odoo integration services empower your business by seamlessly aligning your Odoo platform with essential business tools. Our technical expertise ensures flawless integration, maximising operational efficiency and effectiveness. As a premier Odoo partner, Legions Soft leads the industry, delivering unmatched solutions tailored to your business requirements.                
                </span>
            </div>
            <img src={img2} alt='img2'/>
        </div>

        <div className='OdooIntegration-part-3'>
            <p>
            Take the Legions Soft Odoo Integration Journey Now.
            </p>
        </div>

        <div className='OdooIntegration-part-4-container'>
            <div className='OdooIntegration-part-4'>
            <div>
                <p>Why integrating Odoo ERP into your business processes is necessary?</p>
                <span>
                The necessity for Odoo ERP integration stems from its role as a comprehensive open-source business management solution. Operating as a singular platform for all business functions, Odoo facilitates a transformative impact on company operations. From procurement to sales, recruitment to payroll management, manufacturing to repairs, and inventory management to logistics, Odoo serves as an all-encompassing solution.
                </span>
                <p> Integrating your Odoo platform with various Odoo modules</p>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Facilitates seamless use of everyday applications.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Enhances operational efficiency in business processes.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Provides complete control over your business operations.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Expands reachability and broadens the customer base.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Offers access to a wide array of sophisticated tools tailored to support your business needs.</p>
                </div>
            </div>
            <img src={img3} alt='img3'/>
            </div>
        </div>

        <div className='OdooIntegration-part-5'>
            <img src={img4} alt='img 4'/>
            <div>
                <p>
                What is Legion Soft's approach to Odoo API Integration?
                </p>
                <span>
                At Legion Soft, Odoo API Integration signifies the seamless incorporation of Odoo ERP into your business operations. Leveraging the robust capabilities of Odoo's API, we enable smooth communication and data exchange between Odoo and other systems. This integration streamlines processes, enhances data accuracy, and optimises overall efficiency across your organisation
                </span>
            </div>
        </div>

        <div className='OdooIntegration-part-6'>
            <p>Comprehensive Odoo Integration Solutions by Legion Soft</p>
            <span>
            At Legion Soft, we understand the importance of seamless integration between Odoo and other essential business tools. Our integration solutions are tailored to enhance management efficiency and provide full control over your operations. We offer a wide range of integration options with leading platforms including:
            </span>
            <div className='OdooIntegration-part-6-section'>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Payment Gateways</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Social Media Platforms</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> eCommerce Platforms</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Text Message Service Providers</p>
                </div>
                <div>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Advanced Biometric Devices</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Shipping Integrators</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Payment Acquirers.</p>
                </div>
            </div>
        </div>

        <div className='OdooIntegration-part-7'>
            <p>Payment Gateways</p>
            <div className='OdooIntegration-part-7-section-1'>
                <p>Seamless Odoo Integration with Multiple Payment Gateways</p>
                <span>
                    At Legion Soft, we specialise in seamlessly integrating Odoo with various leading payment gateways tailored to your regional requirements. This integration enhances your accessibility to a diverse customer base, ultimately expanding your reach and improving customer engagement. Trust Legion Soft Technologies to integrate Odoo with the payment gateway of your choice, supporting the currency of your operations for optimal business performance.
                </span>
            </div>
            <div className='OdooIntegration-part-7-section-2'>
                <div>
                    <img src={Logo1} alt='logo1'/>
                    <p>
                        Integrate the premier universal bank wire transfer gateway with Odoo to streamline payment operations and management directly within the platform
                    </p>
                </div>
                <div>
                    <img src={Logo2} alt='logo1'/>
                    <p>
                    Leverage Odoo integration with Stripe to enable seamless online payments from the United States, offering integrated and customizable payment solutions tailored to your needs.
                    </p>
                </div>
                <div>
                    <img src={Logo3} alt='logo1'/>
                    <p>
                    Cash on delivery is available for doorstep delivery, allowing you to pay upon receiving your order.                    
                    </p>
                </div>
            </div>
        </div>

        <div className='OdooIntegration-part-8'>
            <p>Why Legions Soft</p>
            <span>Our dedicated team is committed to diagnosing and resolving any technical issues you may encounter.</span>
            <div className='OdooIntegration-part-8-section-1'>
                <div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>High coding standards</p>
                    </div> 
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>Expert in all versions of Odoo</p>
                    </div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>8+ years of experience</p>
                    </div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>900+ satisfied customers across the globe</p>
                    </div>
                </div>
                <div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>Ranks Fifth in Odoo app downloads</p>
                    </div> 
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>High-quality deliverables</p>
                    </div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>Technical & Functional expertise in all Odoo modules</p>
                    </div>
                    <div>
                        <img src={Logo4} alt="Logo4"/>
                        <p>Service in both enterprise & community editions</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='OdooIntegration-part-9'>
            <p>Frequently Asked Questions</p>
            <span>Our team is dedicated to helping you resolve any technical issues you encounter.</span>
            
            <div>
                <p>Why Choose Legions Soft for Odoo Integration?</p>
                <span>
                As an official Odoo partner, Legions Soft offers comprehensive customization and integration services.
                </span>
                <span>
                Expertise in Payment Gateway, Social Media, SMS Gateway, and Biometric Device Integrations.
                </span>
            </div>

            <div>
                <p>What Integrations Does Legions Soft Provide?</p>
                <span>
                    Legions Soft provides with Payment Gateway Integrations, Social Media Integrations, SMS Gateway Integrations, Docparser Integrations and Biometric Device Integrations.
                </span>
            </div>
            <div>
                <p>What are the benefits of integrating E-commerce with an Odoo ERP?</p>
                <span>
                    Payment Gateway, Social Media, SMS Gateway, Docparser, and Biometric Device Integrations.                
                </span>
                <span>
                    Benefits of Integrating E-commerce with Odoo ERP
                </span>
                <span>
                    Reduced operational costs and improved customer experience.
                </span>
            </div>
            <div>
                <p>What are the benefits of integrating E-commerce with an Odoo ERP?</p>
                <span>
                    Customers can instantly check inventory availability and track orders.
                </span>
                <span>
                Easy access to product details and order status improves satisfaction.
                </span>
                <span>
                    Automation minimises manual tasks, cutting processing expenses.
                </span>
                <span>
                    Seamless integration optimises order fulfilment processes.
                </span>
            </div>
            <div>
                <p>Benefits of Social Media Integrations in Odoo:</p>
                <span>
                Facilitates sharing of documents, files, and media worldwide.
                </span>
                <span>
                Simplifies communication with partners via various messaging platforms.
                </span>
                <span>
                Integration with Twitter, LinkedIn, Facebook, and others enhances business visibility and performance.
                </span>
            </div>
        </div>

        <div className='OdooIntegration-part-10'>
          <p>Our Odoo Services</p>
          <div className='OdooIntegration-part-10-items'>
            <div>
              <span><img src={icon15} alt='icon15'/></span>
              <p>Odoo Customization</p>
            </div>
            <div>
              <span><img src={icon16} alt='icon15'/></span>
              <p>Odoo Implementation</p>
            </div>
            <div>
              <span><img src={icon17} alt='icon15'/></span>
              <p>Odoo Integration</p>
            </div>
            <div>
              <span><img src={icon18} alt='icon15'/></span>
              <p>OOdoo Support & Maintenance</p>
            </div>
            <div>
              <span><img src={icon19} alt='icon15'/></span>
              <p>Odoo Migration</p>
            </div>
            <div>
              <span><img src={icon20} alt='icon15'/></span>
              <p>Odoo Training</p>
            </div>
            <div>
              <span><img src={icon21} alt='icon15'/></span>
              <p>Odoo Consultancy</p>
            </div>
          </div>
        </div>

        <div className='OdooIntegration-part-11'>
            <p>Pick Your Developer</p>
            <span></span>
            <div>
                <div><MdEmail size={32}/><p>contact@legionssoft.com</p></div>
                <div><IoLogoWhatsapp size={32}/><p>+92-339-4010310</p></div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}

export default OdooIntegration