import React, { useEffect, useState } from 'react'
import Nav from '../NavBar/Nav'
import AOS from 'aos';
import 'aos/dist/aos.css';
import heroImage from '../../assets/banner-image-1 1.png'
import { Link } from 'react-router-dom'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { MdOutlineArrowForward } from 'react-icons/md'
import FeaturedCasesImg from '../../assets/featuredcases img.png'
import FeaturedcasesBodyImg from '../../assets/Pure-Plank-01-caa33339cb2db17d3ff98c6f654db80d.webp.png'
import dummyLogoTrans from '../../assets/dummy-logo-3 1.png'
import dummyLogoWhite from '../../assets/dummy-logo-3 3.png'
import LegionImg from '../../assets/hire-dev.png'
import LegionImg2 from '../../assets/hire-Group 1000005038.png'
import officeImg from '../../assets/hire-Group 1000005076.png'
import Loader from '../Loader/Loader'
import ContactUs from '../ContactUs/ContactUs'
import Footer from '../Footer/Footer'
import './HireDevelopers.css'

const HireDevelopers = () => {

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 230) {
        console.log("window.scrollY", window.scrollY)
        setIsScrolled(true);
    } else {
        setIsScrolled(false);
    }
  };

  console.log("isScrolled", window.scrollY)

  useEffect(() => {
    const imagesToLoad = [
      FeaturedCasesImg,
      FeaturedcasesBodyImg,
      dummyLogoTrans,
      dummyLogoWhite,
      LegionImg,
      LegionImg2,
      officeImg,
    ];

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadImages = async () => {
      try {
        const promises = imagesToLoad.map((image) => loadImage(image));
        await Promise.all(promises);
        setLoading(false); 
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    AOS.init({ duration: 2000 }); 
    setLoading(false); 
  }, []);



  return (
    <>
      {loading ? <Loader/>  : (
        <div>
        
          <div className='HireDevelopers-container'>

            <div className={`HireDevelopers-nav ${isScrolled ? 'HireDevelopers-nav-scrolled' : ''} `}>
                <Nav/>
            </div>

            <div className='hiredev-hero-container'>

              <div className='hiredev-dev-wrapper'>

                  <div className='Bread-crum-hiredev-dev'>
                    <Link to='/'>Legion Soft</Link><IoIosArrowRoundForward />
                    <Link className='active--hiredev-bread-crum-current-page'>Hire Developers</Link>
                  </div>

                  <div className='hiredev-app-hero-headings'>
                    <p data-aos="fade-up">
                      Odoo is Your
                      <br/>
                      Right Choice
                    </p>
                    <span data-aos="fade-up">Save time, money and resources with cross-platform app development</span>
                    <Link to={"/contact"} data-aos="fade-up">
                          Contact Us Today
                          <MdOutlineArrowForward size={22}/>
                    </Link>
                  </div>

              </div>

              <img src={heroImage} alt='abc' className='hiredev-hero-img' data-aos="fade-up"/>
            </div>

          </div>

          <div className='hire-meet-legions'>
              <p data-aos="fade-up">OUR <span>CLIENTS</span></p>

              <div className='hire-meet-legions-container' data-aos="fade-up">
                  
                  <div className=''>
                    <img src={dummyLogoWhite} alt='abc'/>
                  </div>
                  <div className='hire-meet-legions-image-active'>
                    <img src={dummyLogoTrans} alt='abc'/>
                    <span>Lorem ipsum dolor sit amet consectetur. Eu amet morbi morbi aliquet non. Pharetra.</span>
                  </div>
                  <div className=''>
                    <img src={dummyLogoWhite} alt='abc'/>
                  </div>
                  <div className=''>
                    <img src={dummyLogoWhite} alt='abc'/>
                  </div>
                  <div className=''>
                    <img src={dummyLogoWhite} alt='abc'/>
                  </div>
                  <div className=''>
                    <img src={dummyLogoWhite} alt='abc'/>
                  </div>
                
              </div>
          </div>

          <div className='hire-offices'>
            <div className='hire-offices-container'>
              <p>
                The Best <span>Talent</span> at every
                experience level
              </p>
              <div className='hire-offices-stats'>
                <div className='hire-offices-stats-container'>
                    <div>
                      <p>55%</p>
                      <span>Masters Degree</span>
                    </div>
                    <div>
                      <p>50%</p>
                      <span>Senior Developers</span>
                    </div>
                </div>
                <div className='hire-offices-stats-container'>
                    <div>
                      <p>30%</p>
                      <span>Mid-Level <br/> Developers</span>
                    </div>
                    <div>
                      <p>20%</p>
                      <span>Junior Developers</span>
                    </div>
                </div>
              </div>
            </div>
            <div className='hire-offices-wrapper'>
              <div className='hire-offices-wrapper-heading'>
                <p>Offices</p>
                <span>Representative Offices</span>
              </div>
                <img src={officeImg} alt='abc'/>
            </div>
          </div>

          <div className='hire-building'>
            <img src={LegionImg} alt='abc' className='hire-building-img1' />
            <div>
              <p>Start Building My <br/>
                <span>Development</span> Team Now
                <Link to={"/talk-to-us"}>
                  Hire Developers
                  <MdOutlineArrowForward size={22}/>
                </Link>
              </p>
            </div> 
            <img src={LegionImg2} alt='abc'/>
          </div>

          <div className='hire-featued-cases-container'>
                
                <div className='hire-featued-cases-header'>
                    <p>Featured Case Studies</p>
                    <Link>View All Stuided Cases</Link>
                </div>

                <div className='hire-featued-cases-body'>

                    <div className='hire-featued-cases-content'>
                        <img src={FeaturedCasesImg} alt='src'/>
                        <p>The Idea Behind Pure <br/>Plank</p>
                        <span>
                            Plank is an online platform for fitness and exercise
                            enthusiasts aiming to look after their health and
                            exercise at home. With video tutorials and challenges,
                            this platform brings a new idea to life. TekRevol has
                            developed several digital solutions, including mobile
                            apps, pertaining to the fitness and healthcare sector.
                            <br/>
                            <br/>
                            The Plank app is a complete solution surrounding the
                            planking exercise where users get all the functions and
                            features in one place to get the ideal results. The app
                            also had video tutorials and instructions for the users,
                            as well as the option to buy accessories with complete
                            payment integration options.
                        </span>
                    </div>
                    <img src={FeaturedcasesBodyImg} alt='abc'/>
                </div>

          </div>

            <ContactUs/>

          <div className='inside-footer'>
            <Footer/>
          </div>
        </div>
      )}
    </>
  )
}

export default HireDevelopers