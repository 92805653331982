import React from 'react'
import Nav from '../NavBar/Nav'
import img1 from '../../App Assets/Group 371.png'
import img2 from '../../App Assets/image 8.png'
import img3 from '../../App Assets/Group 442.png'

import img4 from '../../App Assets/Group 405.png'
import img5 from '../../App Assets/image 6.png'

import img6 from '../../App Assets/Group 399.png'
import img7 from '../../App Assets/image 7.png'

import './AndroidDevelopment.css'
import { IoIosArrowDown } from "react-icons/io";
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const AndroidDevelopment = () => {




  return (
    <div className='AndroidDevelopment'>

        <NavScrolled/>

        <div className='AndroidDevelopment-part-1'>
          <p>Revolutionize Your Android Presence with Legions Soft</p>
          <span>
            Discover boundless possibilities with Legions Soft as we lead the charge in 
            Android app development. From crafting high-performance native applications in 
            Android/Kotlin to pioneering cross-platform solutions, captivating mobile gaming 
            experiences, and cutting-edge SaaS products, we're dedicated to elevating your 
            digital footprint.
          </span>
        </div>

        <div className='AndroidDevelopment-part-2'>
          <p>Accelerate Your Success with Agile Development</p>
          <span>
            Experience the efficacy of our Agile development methodology, 
            fostering adaptability and flexibility across the Android app creation 
            lifecycle. Our iterative approach ensures not only timely delivery but 
            also cultivates an environment where each development phase enriches the 
            quality and functionality of your application. Witness the seamless 
            evolution of your ideas into a resilient, feature-rich solution that 
            excels in the ever-evolving app ecosystem.
          </span>
          <p>Harnessing Cutting-Edge Technologies for Future-Ready Applications</p>
          <span>
            Embark on a journey into the future with our steadfast dedication to 
            incorporating the latest advancements into your Android applications. 
            From the depths of AI and machine learning to the immersive realms of 
            augmented reality (AR), we harness cutting-edge technologies to ensure 
            that your apps not only remain current but also stand the test of time.
          </span>
        </div>

        <div className='AndroidDevelopment-part-3'> 
          <div>
            <p>30+</p>
            <span>APPS</span>
          </div>

          <div>
            <p>3 M+</p>
            <span>TOTAL USERS</span>
          </div>


          <div>
            <p>$20m</p>
            <span>RAISED</span>
          </div>


          <div>
            <p>9yrs</p>
            <span>DEVELOPER'S AVG EXPERIENCE</span>
          </div>
        </div>

        <div className='AndroidDevelopment-part-4'>
          <p>Our <span>Successfully</span> Developed Android Apps</p>
          <div className='AndroidDevelopment-part-4-section-1'>
            <p>
                DVAGO - Pharmacy & Healthcare
              <img src={img2} alt='img'/>
            </p>
            <div>
              <p>
                DVAGO is not just another online pharmacy; it's a trendsetter in 
                the industry. Offering a comprehensive product portfolio ranging 
                from essential medicines to vitamins and personal care items, DVAGO 
                ensures that your healthcare needs are met with utmost convenience 
                and efficiency.
                <br/>
                <br/>
                Our team conceptualized and developed the DVAGO app with a singular focus: 
                to provide seamless access to healthcare services. By integrating state-of-the-art 
                technology with unparalleled service, DVAGO delivers an experience that caters to 
                the demands of modern consumers.
              </p>
              <img src={img3} alt='img'/>
            </div>
            <img src={img1} alt='img'/>
          </div>
        </div>

        <div className='AndroidDevelopment-part-5'>
          <div className='AndroidDevelopment-part-5-section-1'>
            <p>
              GharPar - At home Beauty Salon
              <img src={img5} alt='img'/>
            </p>
            <div>
              <p>
                At Legions Soft, we're proud to have developed GharPar, an intuitive mobile app and web-based 
                solution dedicated to providing women with seamless access to high-quality, hygienic, and timely 
                at-home beauty services.
                <br/>
                <br/>
                Our team has meticulously crafted GharPar to offer an unparalleled user experience, 
                ensuring that booking beauty services is not only convenient but also enjoyable. 
                With a user-friendly interface and robust features, GharPar simplifies the process 
                of scheduling appointments, selecting preferred services, and managing payments, 
                all from the comfort of home.
              </p>
              <img src={img4} alt='img'/>
            </div>
            <img src={img1} alt='img'/>
          </div>
        </div>

        <div className='AndroidDevelopment-part-6'>
          <div className='AndroidDevelopment-part-6-section-1'>
            <p>
              Royal Taj Restaurant
              <img src={img7} alt='img'/>
            </p>
            <div>
              <p>
                With Zupas Cafe Loyalty, your patrons can enjoy the convenience of ordering high-quality, 
                from-scratch favorites with just a tap, eliminating the hassle of kitchen messes. Plus, 
                our loyalty point system incentivizes repeat business, allowing customers to earn points 
                on every order that can be redeemed for their favorite menu items.
                <br/>
                <br/>
                At Legions Soft, we take pride in crafting innovative solutions that redefine user experiences. 
                With our expertise in Android app development, we've created the Zupas Cafe Loyalty app to 
                streamline restaurant management and enhance customer satisfaction.
              </p>
              <img src={img6} alt='img'/>
            </div>
            <img src={img1} alt='img'/>
          </div>
        </div>


        <div className='AndroidDevelopment-part-7'>
          <div>
            <p>Native Development</p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>Cross-Platform Development </p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>Security Measures</p>
            <IoIosArrowDown size={35}/>
          </div>

          <div>
            <p>Agile Methodology</p>
            <IoIosArrowDown size={35}/>
          </div>
        </div>


        <div className='AndroidDevelopment-part-8'>
          <p>Frequently Asked Questions:</p>

          <div>
          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>What Sets Legions Soft Apart for iOS App Development?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              abc
            </div>
          </div>

          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>How does Legions Soft ensure the security of Android apps during development?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              abc
            </div>
          </div>

          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>Can Legions Soft develop native Android apps using Kotlin or Java?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>What are App development services?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>Is Legions Soft capable of aiding with both Android and web development for my project?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

          <div>
            <div className='AndroidDevelopment-part-8-section-to-hover'>
              <p>Can Legions Soft assist with both Android and web development for my project?</p>
              <IoIosArrowDown size={30}/>
            </div>
            <div className='AndroidDevelopment-part-8-section-to-display-on-hover'>
              asd
            </div>
          </div>

        </div>
      </div>

      <div className='AndroidDevelopment-part-9'>
        <p>Don't hesitate any longer!</p>
        <span>
          We're eager to connect with you on social media. Reach out to us today 
          at contact@legionssoft.com or give us a call at 0339-4010310 to embark on your 
          journey with one of Australia's premier social media agencies.
        </span>
      </div>
      <Footer/>
    </div>
  )
}

export default AndroidDevelopment