import React from 'react'
import icon1 from '../../Web Assets/icons/web-development 1.png'
import icon2 from '../../Web Assets/icons/distributed 1.png'
import icon3 from '../../Web Assets/icons/data 1.png'
import icon4 from '../../Web Assets/icons/web-design-(1) 1.png'
import icon5 from '../../Web Assets/icons/coding 1.png'
import icon6 from '../../Web Assets/icons/scope_2689759 1.png'
import icon7 from '../../Web Assets/icons/lamp_13503601 1.png'
import icon8 from '../../Web Assets/icons/settings_15163160 1.png'
import icon9 from '../../Web Assets/icons/wordpress.png'
import icon10 from '../../Web Assets/icons/sitecore.png'
import icon11 from '../../Web Assets/icons/jhoomla.png'
import icon12 from '../../Web Assets/icons/drupal.png'
import icon13 from '../../Web Assets/icons/brightspot.png'
import icon14 from '../../Web Assets/icons/html-5.png'
import icon15 from '../../Web Assets/icons/javascript.png'
import icon16 from '../../Web Assets/icons/php.png'
import icon17 from '../../Web Assets/icons/angularjs.png'
import './WebCMS.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'

const WebCMS = () => {
  return (
    <div className='WebCMS'>

      <NavScrolled/>

        <div className='WebCMS-part-1'>
          <p>Bespoke CMS Development Solutions</p>
          <span>
            Experience the pinnacle of content management with our custom CMS development services. We go beyond the ordinary, 
            offering intuitive interfaces, robust security measures, and personalised features tailored to your unique business needs.
          </span>
        </div>

        <div className='WebCMS-part-2'>
          <p>Highly Skilled Team of CMS Specialists</p>
          <span>
            Legions Soft's Custom CMS Solutions are SEO-friendly and results-oriented, meticulously crafted to seamlessly integrate with 
            your existing content management system. Elevate and optimize your website, application, social media network, e-commerce platform, 
            or blog with our tailored solutions designed for success.
          </span>
          <p>Diverse Range of Tailored CMS Services</p>
          <span>
            Experience seamless transitions, robust customization options, and dedicated ongoing maintenance. 
            With our comprehensive suite of CMS solutions, empower your digital presence to thrive in the dynamic 
            online landscape.
          </span>
        </div>


        <div className='WebCMS-part-3'>

          <p>Acquire Tailored CMS Solutions Today</p>

          <div className='WebCMS-part-3-section-1'>
            <div>
              <div>
                <img src={icon1} alt='icon'/>
                <p>CMS Development Services</p>
              </div>
              <p>
                Our team of CMS developers excels in crafting and customizing CMS solutions tailored to diverse industries. 
                From bespoke platforms like Joomla to industry-standard systems like WordPress, our adept developers deliver 
                precise solutions aligned with your specific requirements.
              </p>
            </div>

            <div>
              <div>
                <img src={icon2} alt='icon'/>
                <p>CMS Integrations</p>
              </div>
              <p>
                We seamlessly integrate a diverse array of crucial plugins, extensions, and tools into your 
                current CMS solutions and business frameworks. This tailored approach optimizes workflows, 
                ensuring heightened efficiency and increased return on investment (ROI) for your enterprise.
              </p>
            </div>

            <div>
              <div>
                <img src={icon3} alt='icon'/>
                <p>CMS Migration</p>
              </div>
              <p>
                In addition to website updates and redesigns, we specialize in website migrations aimed at 
                enhancing loading speeds, transitioning from outdated platforms, implementing HTTPS protocols, 
                and enhancing scalability to accommodate increased traffic influxes.
              </p>
            </div>

            <div>
              <div>
                <img src={icon4} alt='icon'/>
                <p>CMS Plugin Development</p>
              </div>
              <p>
                Whether your priority lies in drag-and-drop functionality, customizable themes, or 
                integrated SEO-enhancement modules, our team of CMS experts is adept at developing and 
                implementing a plethora of effective plugins. These tools empower you to effortlessly 
                integrate new features and enact changes at your convenience, ensuring flexibility and 
                seamless adaptation to evolving needs.
              </p>
            </div>
          
          </div>

        </div>


        <div className='WebCMS-part-4'>
          <p>Acquire Tailored CMS Solutions Today</p>

          <div className='WebCMS-part-4-section-1'>

            <div>
              <div>
                <img src={icon5} alt='icon'/>
                <p>Custom CMS Solutions</p>
              </div>
              <p>
                Our team of CMS developers excels in crafting and customizing solutions across diverse industries. Whether you require a 
                bespoke CMS platform like Joomla or WordPress, our skilled 
                developers deliver precisely tailored solutions to meet your 
                unique requirements.
              </p>
            </div>

            <div>
              <div>
                <img src={icon6} alt='icon'/>
                <p>Open-Source CMS Solutions</p>
              </div>
              <p>
                We offer the development of new open-source CMS 
                solutions such as WordPress, Drupal, or Joomla. 
                Alternatively, we can integrate customizable features 
                and modifications into your existing platform, enhancing 
                functionality, interoperability, and workflow efficiency.
              </p>
            </div>

            <div>
              <div>
                <img src={icon7} alt='icon'/>
                <p>Enterprise CMS Solutions</p>
              </div>
              <p>
                Our seasoned engineers specialize in crafting custom 
                enterprise-level CMS solutions tailored to empower 
                large-scale companies to manage their content, SEO, 
                and marketing campaigns efficiently. With a centralized 
                CMS platform, enterprises gain full control over their 
                digital assets, ensuring streamlined operations and 
                enhanced brand visibility.
              </p>
            </div>

            <div>
              <div>
                <img src={icon8} alt='icon'/>
                <p>Secure CMS Solutions</p>
              </div>
              <p>
                We provide headless CMS solutions fortified with PCI, 
                HIPAA, and other regulatory standards to guarantee 
                compliance. Additionally, we implement custom security 
                measures and coding practices to thwart spam SEO tactics, 
                DDoS attacks, drive-by downloads, and data breaches, 
                ensuring the utmost protection for your digital assets.
              </p>
            </div>


          </div>

        </div>


        <div className='WebCMS-part-5'>
          <p>Enhance Your Website with CMS Plugins & Integrations</p>
          <span>
            Transform your vision into a feature-rich, custom-tailored 
            reality by adding custom features, integrating plugins, and 
            implementing add-ons to your website. At Legions Soft, we 
            elevate your existing content management system (CMS) to new 
            heights, empowering you with a cutting-edge digital platform.
          </span>
          <Link>INTEGRATE YOUR CMS NOW</Link>
        </div>

        <div className='WebCMS-part-6'>

          <div className='WebCMS-part-5-section-1'>
            <p>CMS Platforms</p>
            <span>
              Unlock the potential of your CMS platforms through our integration of customizable 
              features, modules, plugins, and extensions. From WordPress and Drupal to Joomla, 
              Brightspot, and Sitestore, we optimize workflows to suit your needs.
            </span>
            <div>
              <img src={icon9} alt='icon'/>
              <img src={icon10} alt='icon'/>
              <img src={icon11} alt='icon'/>
              <img src={icon12} alt='icon'/>
              <img src={icon13} alt='icon'/>
            </div>
          </div>

          <div className='WebCMS-part-5-section-1'>
            <p>Open-Source Technologies</p>
            <span>
              Unlock the potential of your CMS platforms through our integration of 
              customizable features, modules, plugins, and extensions. From WordPress 
              and Drupal to Joomla, Brightspot, and Sitestore, we optimize workflows to 
              suit your needs.
            </span>
            <div>
              <img src={icon14} alt='icon'/>
              <img src={icon15} alt='icon'/>
              <img src={icon16} alt='icon'/>
              <img src={icon17} alt='icon'/>
            </div>
          </div>

        </div>

        <Footer/>

    </div>
  )
}

export default WebCMS