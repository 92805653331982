import React from 'react'
import Nav from '../NavBar/Nav'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import OdooPart2 from '../../OdooAssets/Group 12 (2).png'

import './OdooSupport.css'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import SupportSupportPart3 from '../../OdooAssets/Group 12 support.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'



const OdooSupport = () => {
  return (
    <div className='OdooSupport-b'>

      <NavScrolled/>

      <div className='OdooSupport-b-header'>
        <p>Odoo Support</p>
        <span>Expert support for your Odoo ERP journey.</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      <div className='OdooSupport-part-2'>
          <div>
            <p>Legions Soft ERP Support & Maintenance </p>
            <span>
            Legions Soft provides comprehensive support and maintenance services for Odoo ERP. Our team, comprising both functional consultants and technical experts, addresses a wide range of issues encountered by our clients. We excel in identifying and resolving various technical challenges, providing customized solutions, documentation, bug fixes, guidance, and other related assistance. Leveraging our extensive experience and technical proficiency, we deliver tailored support services to optimise your Odoo experience.
            </span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
          </div>
          <img src={OdooPart2} alt='OdooPart2'/>
      </div>

      <div className='OdooSupport-part-3'>
        <img src={SupportSupportPart3} alt='SupportSupportPart3'/>
        <div className='OdooSupport-part-2-content'>
          <p>Odoo AMC Service</p>
          <span>
            Odoo, a comprehensive software solution, requires continuous support from Odoo service partners and the community to address issues promptly. Legions Soft, with over 15 years of experience, stands as a reliable partner in providing tailored Odoo AMC services.
          </span>
          <div className='OdooSupport-part-2-bullets'>
            <p><MdKeyboardDoubleArrowRight size={30}/>Dedicated team of expert professionals and developers committed to resolving your Odoo issues.</p>
            <p><MdKeyboardDoubleArrowRight size={30}/>Best-in-class Odoo developers and consultants available to rectify any challenges.</p>
            <p><MdKeyboardDoubleArrowRight size={30}/>24/7 Customer Support from Experienced Odoo Developers</p>
            <p><MdKeyboardDoubleArrowRight size={30}/>Customised Odoo AMC packages designed to meet the specific needs of your organisation.</p>
          </div>
        </div>
      </div>
      
      <div className='OdooSupport-part-4-container'>
            <p>Why Legions Soft</p>
            <span>Our team would help you in finding out the technical problem you facing.</span>
            <div>
              <div>
                <p><MdKeyboardDoubleArrowRight size={30}/> Our team specialises in identifying and resolving the technical challenges you encounter.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> We offer training sessions tailored to your business needs, covering relevant Odoo modules.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/>  With a large team of Odoo specialists, we provide extensive support to meet your requirements.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Our contributions to Odoo apps demonstrate our commitment to enhancing the platform's functionality.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Service in both enterprise and community editions</p>
              </div>
              <div>
                <p><MdKeyboardDoubleArrowRight size={30}/> We excel in handling all versions of Odoo, ensuring compatibility and seamless operation</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> Our services cater to both enterprise and community editions of Odoo, providing comprehensive support.</p>
                <p><MdKeyboardDoubleArrowRight size={30}/> With over 8 years of experience, we bring a wealth of knowledge and expertise to every project.</p>
              </div>
            </div>
      </div>

      <div className='OdooSupport-part-5'>
          <p>Our Odoo Services</p>
          <div className='OdooSupport-part-5-items'>
            <div>
              <span><img src={icon15} alt='icon15'/></span>
              <p>Odoo Customization</p>
            </div>
            <div>
              <span><img src={icon16} alt='icon15'/></span>
              <p>Odoo Implementation</p>
            </div>
            <div>
              <span><img src={icon17} alt='icon15'/></span>
              <p>Odoo Integration</p>
            </div>
            <div>
              <span><img src={icon18} alt='icon15'/></span>
              <p>OOdoo Support & Maintenance</p>
            </div>
            <div>
              <span><img src={icon19} alt='icon15'/></span>
              <p>Odoo Migration</p>
            </div>
            <div>
              <span><img src={icon20} alt='icon15'/></span>
              <p>Odoo Training</p>
            </div>
            <div>
              <span><img src={icon21} alt='icon15'/></span>
              <p>Odoo Consultancy</p>
            </div>
          </div>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooSupport