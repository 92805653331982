import React, {useState, useEffect, useRef } from 'react'
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Home.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom';

import SWORD from '../../assets/Sowrd.png'
import UPWORK from '../../assets/upwork.png'
import ISO from '../../assets/ISO.png'
import CLUTCH from '../../assets/clutuch.png'
import RATING from '../../assets/rating.png'
import dummyLogoTrans from '../../assets/dummy-logo-3 1.png'
import dummyLogoWhite from '../../assets/dummy-logo-3 3.png'
import meet_legion_heading from '../../assets/meet legiosn heading.png'
import meet_legions_branding_img1 from '../../assets/agile-home.png'
import meet_legions_branding_img2 from '../../assets/design-home.png'
import meet_legions_branding_img3 from '../../assets/empathy-home.png'
import meet_legions_branding_img4 from '../../assets/friction-home.png'
import recognizeImg5 from '../../assets/Logo-Strategic-Partnerships-2022_1-2-2 1.png';
import recognizeImg2 from '../../assets/clutch-top-info-tech-white 1.png';
import recognizeImg3 from '../../assets/Clip path group.png';
import recognizeImg4 from '../../assets/ENG-Inc.-5000 1.png';
import recognizeImg1 from '../../assets/ISO.png';
import bannerVidep from '../../videos/Brocoders - On-demand Technical Team for SaaS Products and Midsize Businesses.mp4'

import techimg1 from '../../assets/Property-1Variant2.svg'
import techimg2 from '../../assets/Property-1Variant3.svg'
import techimg3 from '../../assets/logo-javascript-1.svg'
import techimg4 from '../../assets/Typescript_logo_2020-1.svg'
import techimg5 from '../../assets/Property-1Default.svg'
import techimg7 from '../../assets/Property-1Variant4.svg'
import techimg8 from '../../assets/Property-1Variant5.svg'
import techimg9 from '../../assets/Property-1Variant12.svg'
import techimg10 from '../../assets/Property-1Variant17.svg'
import techimg11 from '../../assets/Property-1Variant7.svg'
import techimg12 from '../../assets/Property-1Variant8.svg'
import techimg13 from '../../assets/Property-1Variant18.svg'
import techimg14 from '../../assets/Property-1Rust.svg'
import techimg15 from '../../assets/icon-2-1.svg'
import techimg16 from '../../assets/Property-1Unreal-Engine.svg'
import techimg17 from '../../assets/icon-4-1.svg'
import techimg18 from '../../assets/Property-1Variant7 (1).svg'
import techimg19 from '../../assets/Property-1Variant14.svg'
import techimg20 from '../../assets/Property-1Variant11.svg'
import techimg21 from '../../assets/Property-1-2Default.svg'
import techimg22 from '../../assets/Xamarin.svg'
import techimg23 from '../../assets/NET-MAUI.svg'
import techimg24 from '../../assets/Property-1Variant16.svg'
import techimg25 from '../../assets/Property-1Variant9-cloud.svg'
import techimg26 from '../../assets/Property-1Variant13-cloud.svg'
import techimg27 from '../../assets/Property-1Variant10-cloud.svg'
import techimg28 from '../../assets/icon-3-cloud.svg'
import techimg29 from '../../assets/carbon_virtual-private-cloud.svg'
import techimg30 from '../../assets/icon-5-1-erp.svg'
import techimg31 from '../../assets/Dynamics365_scalable-1-erp.svg'
import techimg32 from '../../assets/Vector-erp.svg'
import techimg33 from '../../assets/icon-6-1-erp.svg'

import techDomain1 from '../../assets/tech domain icons/Artificial intelligence/Machine learning.png'
import techDomain2 from '../../assets/tech domain icons/Artificial intelligence/Deep learning.png'
import techDomain3 from '../../assets/tech domain icons/Artificial intelligence/Computer vision.png'
import techDomain4 from '../../assets/tech domain icons/Artificial intelligence/Natural language processing.png'
import techDomain5 from '../../assets/tech domain icons/Artificial intelligence/Natural language understanding.png'
import techDomain6 from '../../assets/tech domain icons/Artificial intelligence/Generative AI.png'
import techDomain7 from '../../assets/tech domain icons/Process automation/Robotic process automation-01.png'
import techDomain8 from '../../assets/tech domain icons/Process automation/Intelligent automation-01.png'
import techDomain9 from '../../assets/tech domain icons/Process automation/Busincess process automation-01.png'
import techDomain10 from '../../assets/tech domain icons/Process automation/Document data extraction & processing-01.png'
import techDomain11 from '../../assets/tech domain icons/Process automation/Workflow orchestration-01.png'
import techDomain12 from '../../assets/tech domain icons/Process automation/Virtual assistance & support-01.png'
import techDomain13 from '../../assets/tech domain icons/Business intelligence/Data visualtization and reporting.png'
import techDomain14 from '../../assets/tech domain icons/Business intelligence/Dashboards and scorecards-01.png'
import techDomain15 from '../../assets/tech domain icons/Business intelligence/Data integration and ETL-01.png'
import techDomain16 from '../../assets/tech domain icons/Business intelligence/Data governance-01.png'
import techDomain17 from '../../assets/tech domain icons/Business intelligence/Data analytics.png'
import techDomain18 from '../../assets/tech domain icons/Business intelligence/Real-time business monitoring-01.png'
import techDomain19 from '../../assets/tech domain icons/Big Data/Data engineering-01.png'
import techDomain20 from '../../assets/tech domain icons/Big Data/Data lakes and data warehouses-01.png'
import techDomain21 from '../../assets/tech domain icons/Big Data/Distributed data processing-01.png'
import techDomain22 from '../../assets/tech domain icons/Big Data/Cloud data solutions-01.png'
import techDomain23 from '../../assets/tech domain icons/Big Data/Real-time streaming analytics-01.png'
import techDomain24 from '../../assets/tech domain icons/Big Data/Data security and compliance-01.png'
import techDomain25 from '../../assets/tech domain icons/Embedded/Firmware development-01.png'
import techDomain26 from '../../assets/tech domain icons/Embedded/BSP and drivers development-01.png'
import techDomain27 from '../../assets/tech domain icons/Embedded/PCB design-01.png'
import techDomain28 from '../../assets/tech domain icons/Embedded/PCB manufacturing-01.png'
import techDomain29 from '../../assets/tech domain icons/Embedded/SoC design-01.png'
import techDomain30 from '../../assets/tech domain icons/Embedded/FPGA development-01.png'
import techDomain31 from '../../assets/tech domain icons/IoT/Connected devices-01.png'
import techDomain32 from '../../assets/tech domain icons/IoT/Remote sensors-01.png'
import techDomain33 from '../../assets/tech domain icons/IoT/Telematics-01.png'
import techDomain34 from '../../assets/tech domain icons/IoT/Smart homes.png'
import techDomain35 from '../../assets/tech domain icons/IoT/IIoT-01.png'
import techDomain36 from '../../assets/tech domain icons/IoT/IIoT-01.png'
import techDomain37 from '../../assets/tech domain icons/ROBOTICS/Software development.png'
import techDomain38 from '../../assets/tech domain icons/ROBOTICS/Firmware development-01.png'
import techDomain39 from '../../assets/tech domain icons/ROBOTICS/Prototyping-01.png'
import techDomain40 from '../../assets/tech domain icons/ROBOTICS/SLAM development-01.png'
import techDomain41 from '../../assets/tech domain icons/ROBOTICS/Mobile robotics-01.png'
import techDomain42 from '../../assets/tech domain icons/ROBOTICS/Robotics-01.png'
import techDomain43 from '../../assets/tech domain icons/Blockchain/Custom blockchain solutions.png'
import techDomain44 from '../../assets/tech domain icons/Blockchain/Tokenizations-01.png'
import techDomain45 from '../../assets/tech domain icons/Blockchain/Smart contracts-01.png'
import techDomain46 from '../../assets/tech domain icons/Blockchain/Crypto wallets-01.png'
import techDomain47 from '../../assets/tech domain icons/Blockchain/Decentralized applications-01.png'
import techDomain48 from '../../assets/tech domain icons/Blockchain/Exchanges (CEX and DEX)-01.png'
import techDomain49 from '../../assets/tech domain icons/AR, VR, MR/MR apps for Apple Vision Pro-01.png'
import techDomain50 from '../../assets/tech domain icons/AR, VR, MR/VR games and entertainment-01.png'
import techDomain51 from '../../assets/tech domain icons/AR, VR, MR/VR training and learning-01.png'
import techDomain52 from '../../assets/tech domain icons/AR, VR, MR/VR tours and presentations-01.png'
import techDomain53 from '../../assets/tech domain icons/AR, VR, MR/MR monitoring and assistance-01.png'
import techDomain54 from '../../assets/tech domain icons/AR, VR, MR/AR applications-01.png'

import legionChar1 from '../../assets/icons/Asset 1@330x.png'
import legionChar2 from '../../assets/icons/Asset 2@330x.png'

import { IoPhonePortraitOutline } from "react-icons/io5";

import { featuredCases } from './CaseStudies';

import Footer from '../Footer/Footer';


import Loader from '../Loader/Loader';
import ContactUs from '../ContactUs/ContactUs';



const Home = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeSecondaryTab, setActiveSecondaryTab] = useState('Odoo Services');
    const [technologyTabs, setTechnologyTabs] = useState('Front-end');
    const [technologicalDomainTabs, setTechnologicalDomainTabs] = useState('Artificial intelligence');
    const [loading, setLoading] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);


    const meetLegionsRef = useRef(null);

    const nextCase = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % featuredCases.length);
    };

    const prevCase = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? featuredCases.length - 1 : prevIndex - 1
        );
    };

    const { imgSrc, title, description, bodyImgSrc } = featuredCases[currentIndex];


    const handleScroll = () => {
        if (window.scrollY > 230) {
            console.log("window.scrollY", window.scrollY)
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };
    

  
    const handleSecondaryTabClick = (tab) => {
      setActiveSecondaryTab(tab);
    };

    const handleTechnologyTabClick = (tab) => {
        setTechnologyTabs(tab);
    };

    const handleTechnologicalDomainTabClick = (tab) => {
        setTechnologicalDomainTabs(tab);
    };

    useEffect(() => {
        
        const imagesToLoad = [
          SWORD, UPWORK, ISO, CLUTCH, RATING,
          dummyLogoTrans, dummyLogoWhite,
          // ... (list all your image imports here)
        ];
    
        const loadImage = (src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = src;
          });
        };
    
        const loadImages = async () => {
          try {
            const promises = imagesToLoad.map((image) => loadImage(image));
            await Promise.all(promises);
            setLoading(false); // All images are loaded
          } catch (error) {
            console.error('Error loading images:', error);
          }
        };
    
        loadImages();
      }, []);
    

      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        AOS.init({ duration: 2000 }); 
        setLoading(false); 
        return () => {
            window.removeEventListener('scroll', handleScroll);
            meetLegionsRef.current = null;
          };
      }, []);
    

  return (
    <>
    {loading ? <Loader/> : 
    (

        <div>

            <div className='home-container'>

                <video className='background-video' autoPlay loop muted>
                    <source src={bannerVidep} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            
                <div className={`home-nav  ${isScrolled ? 'home-nav-scrolled' : ''} `}>
                    <Nav/>
                </div>

                <div className='home-middle-wrapper'  >

                    <div className='home-book-call'>
                        <Link>
                            <IoPhonePortraitOutline size={26}/>Book a call
                        </Link>
                    </div>

                    <p>Software Development Company</p>
                    <span>No #1 Odoo Service Provider in Asia</span>
                    <Link to={'/contact'}>
                        Let's Discuss Your Project
                        <MdOutlineArrowForward size={22}/>
                    </Link>

                </div>
            </div>

            <div className='meet-legions'>
                

                <div className='meet-legions-body' ref={meetLegionsRef}>
                    <div className='meet-legions-body-container'>
                        <img src={meet_legion_heading} alt='abc'  />
                        <p>
                            At Legions Soft, we pride ourselves on our ability to deliver custom software development and software product engineering solutions that are tailored to your unique needs. Our team of seasoned professionals is dedicated to understanding your vision and bringing it to life through innovative and cutting-edge technology.
                        
                            <br/>
                            <br/>
                            We believe in the power of technology to transform businesses and drive innovation. Let us be your partner in success - contact us today to learn more about how we can help you achieve your goals through custom software development and software product engineering.
                        </p>


                        <div className='meet-legions-body-footer' >
                            <div>
                                <img src={meet_legions_branding_img4} alt='abc'  />
                                <p>Low<br/>Friction</p>
                            </div>  
                            <div>
                                <img src={meet_legions_branding_img1} alt=''  />
                                <p>Agile</p>
                            </div>  
                            <div>
                                <img src={meet_legions_branding_img2} alt=''  />
                                <p>Design<br/>Thinking</p>
                            </div>  
                            <div>
                                <img src={meet_legions_branding_img3} alt=''  />
                                <p>High<br/>Empathy</p>
                            </div>    
                        </div>

                    </div>

                </div>  
            </div>
            
            {/* Services */}
            <div className='home-services'>
            
                <div className='home-services-header'>
                    <p>Services</p>
                    {/* <Link>All services</Link> */}
                </div>    

                <div className='home-services-wrapper'>
                    
                    {/* tabs  */}
                    <div className='home-tabs-container'>

                        <div className='home-secondary-tabs'  >
                            <button
                                className={activeSecondaryTab === 'Odoo Services' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleSecondaryTabClick('Odoo Services')}
                            >
                            Odoo Services
                            </button>
                            <button
                                className={activeSecondaryTab === 'App Development' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleSecondaryTabClick('App Development')}
                            >
                            App Development
                            </button>
                            <button
                                className={activeSecondaryTab === 'Web Development' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleSecondaryTabClick('Web Development')}
                            >
                            Web Development
                            </button>
                            <button
                                className={activeSecondaryTab === 'Digital Marketing' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleSecondaryTabClick('Digital Marketing')}
                            >
                            Digital Marketing
                            </button>
                            <button
                                className={activeSecondaryTab === 'IT Consulting & Support' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleSecondaryTabClick('IT Consulting & Support')}
                            >
                            IT Consulting & Support
                            </button>
                        </div>

                    </div>


                    {/* tabs content */}
                    <div className='home-tabs-content'>
                        
                        {activeSecondaryTab === 'Odoo Services' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Odoo Services</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Odoo implementation</p>
                                    <span>
                                        Implementing Odoo is a complex endeavour that demands expertise to navigate potential challenges effectively.
                                    </span>
                                    {/* <button id='slide-button'><IoArrowForward size={24}/></button> */}
                                </div>
                                <div>
                                    <p>Odoo customization</p>
                                    <span>
                                        At Legions Soft Technologies, we specialise in tailoring Odoo solutions to meet your specific business needs. 
                                    </span>
                                </div>
                                <div>
                                    <p>Odoo support</p>
                                    <span>
                                        Legions Soft provides comprehensive support and maintenance services for Odoo ERP
                                    </span>
                                </div>
                                <div>
                                    <p>Odoo training</p>
                                    <span>
                                        Enhance your Odoo expertise with our comprehensive functional training program tailored for clients, infrastructure providers.
                                    </span>
                                </div>
                                <div>
                                    <p>Odoo integration</p>
                                    <span>
                                        Integration services empower your business by seamlessly aligning your Odoo platform with essential business tools.
                                    </span>
                                </div>
                                <div>
                                    <p>Odoo migration</p>
                                    <span>
                                        Odoo Migration is the pivotal process of propelling your business to new heights through the latest advancements in the Odoo.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'App Development' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>App Development</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>iOS App Development</p>
                                    <span>
                                        From concept to deployment, we prioritize seamless user experience and uphold top-notch quality.
                                    </span>
                                </div>
                                <div>
                                    <p>Android App Development</p>
                                    <span>
                                        Dominate the Android market with our cutting-edge app development services, reaching millions worldwide.
                                    </span>
                                </div>
                                <div>
                                    <p>Front-end developers</p>
                                    <span>Extend your IT team with our skilled
                                        front-end developers keeping fully
                                        abreast of the latest tech trends
                                    </span>
                                </div>
                                <div>
                                    <p>Mobile App UI/UX Design</p>
                                    <span>
                                        Crafting captivating UI/UX is crucial for mobile apps. Our team excels in designing visually stunning interfaces.
                                    </span>
                                </div>
                                <div>
                                    <p>App Testing and Quality Assurance</p>
                                    <span>
                                        Ensuring flawless performance across devices and platforms, our rigorous app testing guarantees superior quality
                                    </span>
                                </div>
                                <div>
                                    <p>App Maintenance and Support</p>
                                    <span>
                                        Our support services ensure to keep your app running smoothly, efficiently and optimizing performance. 
                                    </span>
                                </div>
                                <div>
                                    <p>Enterprise App Development</p>
                                    <span>
                                        From productivity tools to CRM systems, we ensure security and seamless integration for improved collaboration and efficiency.
                                    </span>
                                </div>
                                <div>
                                    <p>App Strategy and Consulting</p>
                                    <span>
                                        Ensure your app's success with our expert strategy and consulting services. We define goals, target audiences, and provide insights for optimal performance.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Web Development' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Web Development</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Responsive Web Design</p>
                                    <span>
                                        Deliver seamless user experience with responsive 
                                        web design across all devices, ensuring flawless functionality
                                    </span>
                                </div>
                                <div>
                                    <p>E-Commerce Development</p>
                                    <span>
                                        Elevate your online business with our e-commerce development services. 
                                        We create secure, scalable.
                                    </span>
                                </div>
                                <div>
                                    <p>Custom Web Application Development</p>
                                    <span>
                                        Empower businesses with tailored web solutions. 
                                        From CRM to inventory management, we leverage latest 
                                        tech for productivity.
                                    </span>
                                </div>
                                <div>
                                    <p>Content Management Systems (CMS)</p>
                                    <span>
                                        We specialize in crafting custom CMS solutions, empowering you to 
                                        effortlessly manage website content. From WordPress to bespoke 
                                        systems.
                                    </span>
                                </div>
                                <div>
                                    <p>Website Maintenance and Support</p>
                                    <span>
                                        Keep your website secure, updated, and running smoothly with our maintenance 
                                        and support services. We handle software updates and offer technical assistance.
                                    </span>
                                </div>
                                <div>
                                    <p>Search Engine Optimization</p>
                                    <span>
                                        Boost your website's visibility in search results with our SEO services. 
                                        We employ proven strategies like keyword research, on-page optimization.
                                    </span>
                                </div>
                                <div>
                                    <p>Performance Optimization</p>
                                    <span>
                                        We enhance your website's speed, navigation, and user experience by 
                                        optimizing code, compressing images, and configuring servers for peak performance.
                                    </span>
                                </div>
                                <div>
                                    <p>Security and Compliance</p>
                                    <span>
                                        We prioritize website security, implementing strong measures against cyber 
                                        threats and ensuring compliance with industry standards. From encryption to 
                                        audits.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Digital Marketing' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Digital Marketing</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Search Engine Optimization (SEO)</p>
                                    <span>
                                        Boost online visibility & drive organic traffic with our 
                                        SEO services. We optimize content, meta tags & backlinks for 
                                        higher SERP rankings.
                                    </span>
                                </div>
                                <div>
                                    <p>Social Media Marketing (SMM)</p>
                                    <span>
                                        Leverage our SMM services to engage with your audience across Facebook, 
                                        Instagram, Twitter, and LinkedIn. Increase brand awareness, drive traffic.
                                    </span>
                                </div>
                                <div>
                                    <p>Content Marketing</p>
                                    <span>
                                        Drive engagement and conversions with our content marketing services. 
                                        We create compelling and relevant content to establish thought leadership.
                                    </span>
                                </div>
                                <div>
                                    <p>Pay-Per-Click Advertising (PPC)</p>
                                    <span>
                                        Maximize ROI with our PPC services. We create targeted campaigns 
                                        on Google Ads & social media, ensuring ads reach the right audience 
                                        efficiently.
                                    </span>
                                </div>
                                <div>
                                    <p>Email Marketing</p>
                                    <span>
                                        Build customer relationships with personalized email campaigns. 
                                        From strategy to automation, we optimize messaging for engagement.
                                    </span>
                                </div>
                                <div>
                                    <p>Influencer Marketing</p>
                                    <span>
                                        Leverage influencer marketing to boost brand visibility. 
                                        We identify aligned influencers for impactful partnerships, 
                                        from outreach to tracking results.
                                    </span>
                                </div>
                                <div>
                                    <p>Website Analytics and Reporting</p>
                                    <span>
                                        Track website metrics, analyze user behavior & conversions. 
                                        Get comprehensive reports for informed decisions & optimized marketing strategies.
                                    </span>
                                </div>
                                <div>
                                    <p>Conversion Rate Optimization</p>
                                    <span>
                                        Optimize your digital campaigns for better results. 
                                        We analyze UX, make improvements, and conduct tests to boost 
                                        conversions. Turn visitors into customers with our CRO services.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'IT Consulting & Support' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>IT Consulting & Support</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Network Infrastructure Assessment</p>
                                    <span>
                                        Expert team assesses network infrastructure, 
                                        identifies strengths/weaknesses, offers detailed 
                                        analysis/recommendations to optimize performance/security/scalability.
                                    </span>
                                </div>
                                <div>
                                    <p>Cybersecurity Solutions</p>
                                    <span>
                                        Shield your business from cyber threats with our 
                                        holistic cybersecurity solutions. From assessments 
                                        to implementing robust security measures, we safeguard 
                                        data & systems.
                                    </span>
                                </div>
                                <div>
                                    <p>Cloud Computing Strategy</p>
                                    <span>
                                        Leverage cloud computing with our tailored strategy and 
                                        implementation services, optimizing agility, scalability, 
                                        and cost-effectiveness in your IT environment.
                                    </span>
                                </div>
                                <div>
                                    <p>Data Management and Analytics</p>
                                    <span>
                                        Leverage our data management & analytics: from integration 
                                        to visualization, unlock data's potential for informed decisions & business growth.
                                    </span>
                                </div>
                                <div>
                                    <p>IT Infrastructure Design and Deployment</p>
                                    <span>
                                        Establish a robust IT infrastructure foundation with our collaborative 
                                        design and deployment services, tailored to your needs for reliability, 
                                        performance, and efficiency.
                                    </span>
                                </div>
                                <div>
                                    <p>Disaster Recovery and Business Continuity</p>
                                    <span>
                                        Prepare for the unexpected with our disaster recovery & business continuity 
                                        solutions. We develop plans & implement resilient systems to ensure seamless 
                                        operations during disruptions.
                                    </span>
                                </div>
                                <div>
                                    <p>Managed IT Services</p>
                                    <span>
                                        Let us handle your IT needs while you focus on your core business. 
                                        Our managed IT services include proactive monitoring, maintenance.
                                    </span>
                                </div>
                                <div>
                                    <p>IT Strategy and Roadmap</p>
                                    <span>
                                        Sync your IT plans with business goals. Our strategic consulting 
                                        crafts IT strategies fueling innovation, efficiency, and growth
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Odoo Implementation' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Odoo Implementation</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Odoo Implementation Overview</p>
                                    <span>
                                        Our Odoo Implementation service is designed to streamline your 
                                        business processes, improve efficiency, and drive growth.
                                    </span>
                                </div>
                                <div>
                                    <p>Needs Assessment and Planning</p>
                                    <span>
                                        Before starting the Odoo implementation, we conduct a 
                                        thorough needs assessment to understand your business goals. 
                                    </span>
                                </div>
                                <div>
                                    <p>Customization and Configuration</p>
                                    <span>
                                        Odoo's flexibility is a key advantage. Our team customizes and 
                                        configures Odoo to align with your business processes, tailoring 
                                        modules, workflows, and reports to meet your specific needs.
                                    </span>
                                </div>
                                <div>
                                    <p>Data Migration</p>
                                    <span>
                                        Migrating data to Odoo is critical. We ensure accurate transfer of 
                                        customer records, product info, and transactions, maintaining data 
                                        integrity and security for a seamless transition.
                                    </span>
                                </div>
                                <div>
                                    <p>Optimization and Continuous Improvement</p>
                                    <span>
                                        Our journey extends beyond implementation. We ensure continuous improvement, 
                                        optimizing your Odoo solution to deliver ongoing value and stay ahead of 
                                        emerging trends and technologies.
                                    </span>
                                </div>
                                
                                <div>
                                    <p>Integration with Third-Party Systems</p>
                                    <span>
                                    Integrating Odoo with your existing systems and third-party apps maximizes efficiency. 
                                    Our team ensures seamless integration with accounting, CRM, e-commerce, and more to 
                                    streamline processes.
                                    </span>
                                </div>
                                <div>
                                    <p>Go-Live and Support</p>
                                    <span>
                                    As you go live with Odoo, we ensure seamless deployment with minimal disruption. 
                                    Post-launch, we offer continuous support and maintenance to meet your evolving business 
                                    needs.
                                    </span>
                                </div>
                                <div>
                                    <p>Training and User Adoption</p>
                                    <span>
                                        User adoption is crucial for ERP success. We provide comprehensive training, 
                                        from basic navigation to advanced features, tailored to equip your team with 
                                        essential Odoo skills.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Odoo Customization' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Odoo Customization</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Custom Module Development</p>
                                    <span>
                                        We excel in crafting bespoke Odoo modules, 
                                        precisely aligning with your business needs. Whether new builds or customization.
                                    </span>
                                </div>
                                <div>
                                    <p>Workflow Optimization</p>
                                    <span>
                                        Optimizing Odoo workflows for efficiency and growth. Analyzing, 
                                        automating, and enhancing productivity. Experience smoother operations 
                                        with our services.
                                    </span>
                                </div>
                                <div>
                                    <p>User Interface Customization</p>
                                    <span>
                                        Enhance user experience with our Odoo UI customization. Our designers 
                                        craft intuitive interfaces that reflect your brand and engage users 
                                        effectively.
                                    </span>
                                </div>
                                <div>
                                    <p>Report Customization</p>
                                    <span>
                                        Unlock business insights with customized Odoo reports. Tailored financial, 
                                        sales, and inventory summaries for actionable analytics.
                                    </span>
                                </div>
                                <div>
                                    <p>Integration with Third-Party Systems</p>
                                    <span>
                                        Integrate Odoo seamlessly with third-party systems for enhanced functionality. 
                                        Sync data across platforms, streamline operations, and optimal performance.
                                    </span>
                                </div>
                                <div>
                                    <p>Localization and Internationalization</p>
                                    <span>
                                        Broaden your global presence with our Odoo localization services, tailoring it to meet 
                                        linguistic, cultural, and regulatory needs across regions.
                                    </span>
                                </div>
                                <div>
                                    <p>Performance Optimization</p>
                                    <span>
                                        Enhance Odoo performance with our optimization. We identify bottlenecks & inefficiencies, 
                                        tuning databases, optimizing code, & configuring servers.
                                    </span>
                                </div>
                                <div>
                                    <p>Training and Support</p>
                                    <span>
                                        Equip your team with specialized Odoo training and support. Tailored sessions and 
                                        responsive assistance ensure seamless operations
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Odoo Integration' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Odoo Integration</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Network Infrastructure</p>
                                    <span>
                                        Expert team assesses network infrastructure, 
                                        identifies strengths/weaknesses, offers detailed 
                                        analysis/recommendations to optimize performance/security/scalability.
                                    </span>
                                </div>
                                <div>
                                    <p>Cybersecurity Solutions</p>
                                    <span>
                                        Shield your business from cyber threats with our holistic cybersecurity 
                                        solutions. From assessments to implementing robust security measures, 
                                        we safeguard data & systems.
                                    </span>
                                </div>
                                <div>
                                    <p>Cloud Computing Strategy</p>
                                    <span>
                                        ELeverage cloud computing with our tailored strategy and implementation services, 
                                        optimizing agility, scalability, and cost-effectiveness in your IT environment.
                                    </span>
                                </div>
                                <div>
                                    <p>Data Management and Analytics</p>
                                    <span>
                                        Leverage our data management & analytics: from integration to visualization, 
                                        unlock data's potential for informed decisions & business growth.
                                    </span>
                                </div>
                                <div>
                                    <p>IT Infrastructure Design and Deployment</p>
                                    <span>
                                        Establish a robust IT infrastructure foundation with our collaborative design 
                                        and deployment services, tailored to your needs for reliability, performance, and efficiency.
                                    </span>
                                </div>
                                <div>
                                    <p>Disaster Recovery and Business Continuity</p>
                                    <span>
                                        Prepare for the unexpected with our disaster recovery & business continuity solutions. We develop 
                                        plans & implement resilient systems to ensure seamless operations during disruptions.
                                    </span>
                                </div>
                                <div>
                                    <p>Managed IT Services</p>
                                    <span>
                                        Let us handle your IT needs while you focus on your core business. Our managed IT services include 
                                        proactive monitoring, maintenance.
                                    </span>
                                </div>
                                <div>
                                    <p>IT Strategy and Roadmap</p>
                                    <span>
                                        Sync your IT plans with business goals. Our strategic consulting crafts IT strategies fueling innovation, efficiency, and growth
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {activeSecondaryTab === 'Odoo Migration' && (
                        <div className='home-tabs-content-body-container'  >
                            <p>Odoo Migration</p>
                            <div className='home-tabs-content-body-wrapper'>
                                <div>
                                    <p>Strategy Development</p>
                                    <span>
                                        Legions Soft ensures successful Odoo migration 
                                        with a tailored plan, strategic assessment, and 
                                        meticulous planning for a seamless transition.
                                    </span>
                                </div>
                                <div>
                                    <p>Data Migration and Validation</p>
                                    <span>
                                        Data migration in Odoo projects is critical. 
                                        We use top tools and methods to ensure seamless 
                                        transition and data integrity. Trust us with your data.
                                    </span>
                                </div>
                                <div>
                                    <p>Risk Assessment and Mitigation</p>
                                    <span>
                                        Prior to migration, we assess risks, incl. 
                                        data integrity, system compatibility, and 
                                        downtime. We mitigate these to ensure seamless outcomes.
                                    </span>
                                </div>
                                <div>
                                    <p>User Training and Adoption</p>
                                    <span>
                                        We offer thorough user training for seamless transition 
                                        to Odoo. Our experienced trainers ensure your team is 
                                        equipped with essential skills.
                                    </span>
                                </div>
                                <div>
                                    <p>Post-Migration Support and Optimization</p>
                                    <span>
                                        Our commitment extends beyond migration. We offer ongoing 
                                        support for optimal Odoo system performance. From troubleshooting 
                                        to optimization.
                                    </span>
                                </div>
                                <div>
                                    <p>Compliance and Security Assurance</p>
                                    <span>
                                        We prioritize compliance & security, ensuring Odoo system meets 
                                        industry standards. From encryption to audits, we safeguard 
                                        data integrity & confidentiality.
                                    </span>
                                </div>
                                <div>
                                    <p>Scalability and Future-Proofing</p>
                                    <span>
                                        Adapt your Odoo system as your business grows. 
                                        Our scalable migration solutions ensure it remains 
                                        valuable for years, regardless of your size.
                                    </span>
                                </div>
                                <div>
                                    <p>Monitoring and Support</p>
                                    <span>
                                        Our dedication to your success extends beyond migration. 
                                        Continuous monitoring and support ensure your Odoo system 
                                        stays efficient.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                    </div>
                
                </div>

            </div>

            <div className='home-recognize-container'>
                
                <p>Recognized among the best, by the best</p>
                <div  >
                    <img src={recognizeImg1} alt='abc'/>
                    <img src={recognizeImg2} alt='abc'/>
                    <img src={recognizeImg3} alt='abc'/>
                    <img src={recognizeImg4} alt='abc'/>
                    <img src={recognizeImg5} alt='abc'/>
                </div>
            </div>
            

            {/* Technological domains */}
            <div className='home-technological-domain-container'>
                
                <div className='home-services-header'>
                    <p>Technological domains</p>
                    <img src={legionChar1} alt='ch' width={240}/>
                </div> 

                 <div className='home-technologies-wrapper'>
                    
                    {/* tabs  */}
                    <div className='home-technologies-tabs-container'>

                        <div className='home-technologies-secondary-tabs home-technological-secondary-tabs'  >
                            <button
                                className={technologicalDomainTabs === 'Artificial intelligence' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Artificial intelligence')}
                            >
                            Artificial intelligence
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Process automation' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Process automation')}
                            >
                            Process automation
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Business intelligence' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Business intelligence')}
                            >
                            Business intelligence
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Big data' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Big data')}
                            >
                            Big data
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Embedded' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Embedded')}
                            >
                            Embedded
                            </button>
                            <button
                                className={technologicalDomainTabs === 'IoT' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('IoT')}
                            >
                            IoT
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Robotics' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Robotics')}
                            >
                            Robotics
                            </button>
                            <button
                                className={technologicalDomainTabs === 'Blockchain' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('Blockchain')}
                            >
                            Blockchain
                            </button>
                            <button
                                className={technologicalDomainTabs === 'AR, VR, MR' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologicalDomainTabClick('AR, VR, MR')}
                            >
                            AR, VR, MR
                            </button>
                        </div>

                    </div>


                    {/* tabs content */}
                    <div className='home-technologies-tabs-content'>
                        
                        {technologicalDomainTabs === 'Artificial intelligence' && (
                        <div className='home-technological-domain-tabs-content-body-container'  >
                            <p>Artificial intelligence</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain1} alt='img'/>
                                    </p>
                                    <span>
                                        Machine learning
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain2} alt='img'/>
                                    </p>
                                    <span>
                                        Deep learning
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain3} alt='img'/>
                                    </p>
                                    <span>
                                        Computer vision
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain4} alt='img'/>
                                    </p>
                                    <span>
                                        Natural language processing
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain5} alt='img'/>
                                    </p>
                                    <span>
                                        Natural language understanding
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain6} alt='img'/>
                                    </p>
                                    <span>
                                        Generative AI
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Process automation' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Process automation</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain7} alt='img'/>
                                    </p>
                                    <span>
                                        Robotic process automation
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain8} alt='img'/>
                                    </p>
                                    <span>
                                        Intelligent automation
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain9} alt='img'/>
                                    </p>
                                    <span>
                                        Busincess process automation
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain10} alt='img'/>
                                    </p>
                                    <span>
                                        Document data extraction & processing
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain11} alt='img'/>
                                    </p>
                                    <span>
                                        Workflow orchestration
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain12} alt='img'/>
                                    </p>
                                    <span>
                                        Virtual assistance & support
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Business intelligence' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Business intelligence</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain13} alt='img'/>
                                    </p>
                                    <span>
                                        Data visualtization and reporting
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain14} alt='img'/>
                                    </p>
                                    <span>
                                        Dashboards and scorecards
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain15} alt='img'/>
                                    </p>
                                    <span>
                                        Data integration and ETL
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain16} alt='img'/>
                                    </p>
                                    <span>
                                        Data governance
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain17} alt='img'/>
                                    </p>
                                    <span>
                                        Data analytics
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain18} alt='img'/>
                                    </p>
                                    <span>
                                        Real-time business monitoring
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Big data' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Big data</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain19} alt='img'/>
                                    </p>
                                    <span>
                                        Data engineering
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain20} alt='img'/>
                                    </p>
                                    <span>
                                        Data lakes and data warehouses
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain21} alt='img'/>
                                    </p>
                                    <span>
                                        Distributed data processing
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain22} alt='img'/>
                                    </p>
                                    <span>
                                        Cloud data solutions
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain23} alt='img'/>
                                    </p>
                                    <span>
                                        Real-time streaming analytics
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain24} alt='img'/>
                                    </p>
                                    <span>
                                        Data security and compliance
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Embedded' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Embedded</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain25} alt='img'/>
                                    </p>
                                    <span>
                                        Firmware development
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain26} alt='img'/>
                                    </p>
                                    <span>
                                        BSP and drivers development
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain27} alt='img'/>
                                    </p>
                                    <span>
                                        PCB design
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain28} alt='img'/>
                                    </p>
                                    <span>
                                        PCB manufacturing
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain29} alt='img'/>
                                    </p>
                                    <span>
                                        SoC design
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain30} alt='img'/>
                                    </p>
                                    <span>
                                        FPGA development
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'IoT' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>IoT</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain31} alt='img'/>
                                    </p>
                                    <span>
                                        Connected devices
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain32} alt='img'/>
                                    </p>
                                    <span>
                                        Remote sensors
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain33} alt='img'/>
                                    </p>
                                    <span>
                                        Telematics
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain34} alt='img'/>
                                    </p>
                                    <span>
                                        Smart homes
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain35} alt='img'/>
                                    </p>
                                    <span>
                                        IIoT
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain36} alt='img'/>
                                    </p>
                                    <span>
                                        IoMT
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Robotics' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Robotics</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain37} alt='img'/>
                                    </p>
                                    <span>
                                        Software development
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain38} alt='img'/>
                                    </p>
                                    <span>
                                        Firmware development
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain39} alt='img'/>
                                    </p>
                                    <span>
                                        Prototyping
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain40} alt='img'/>
                                    </p>
                                    <span>
                                        SLAM development
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain41} alt='img'/>
                                    </p>
                                    <span>
                                        Mobile robotics
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain42} alt='img'/>
                                    </p>
                                    <span>
                                        AI integration
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'Blockchain' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Blockchain</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain43} alt='img'/>
                                    </p>
                                    <span>
                                        Custom blockchain solutions
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain44} alt='img'/>
                                    </p>
                                    <span>
                                        Tokenizations
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain45} alt='img'/>
                                    </p>
                                    <span>
                                        Smart contracts
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain46} alt='img'/>
                                    </p>
                                    <span>
                                        Crypto wallets
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain47} alt='img'/>
                                    </p>
                                    <span>
                                        Decentralized applications
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain48} alt='img'/>
                                    </p>
                                    <span>
                                        Exchanges (CEX and DEX)
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologicalDomainTabs === 'AR, VR, MR' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>AR, VR, MR</p>
                            <div className='home-technological-domain-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        <img src={techDomain49} alt='img'/>
                                    </p>
                                    <span>
                                        MR apps for Apple Vision Pro
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain50} alt='img'/>
                                    </p>
                                    <span>
                                        VR games and entertainment
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain51} alt='img'/>
                                    </p>
                                    <span>
                                        VR training and learning
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain52} alt='img'/>
                                    </p>
                                    <span>
                                        VR tours and presentations
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain53} alt='img'/>
                                    </p>
                                    <span>
                                        MR monitoring and assistance
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        <img src={techDomain54} alt='img'/>
                                    </p>
                                    <span>
                                        AR applications
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}


                    </div>
                
                </div>  

            </div>


            {/* Technology section */}
            <div className='home-technologies-container'>
                <img src={legionChar2} alt='ch' />
                <div className='home-services-header'>
                    <p>Technologies</p>
                    {/* <Link>All technologies</Link> */}
                </div> 

                 <div className='home-technologies-wrapper'>
                    
                    {/* tabs  */}
                    <div className='home-technologies-tabs-container'>

                        <div className='home-technologies-secondary-tabs'  >
                            <button
                                className={technologyTabs === 'Front-end' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('Front-end')}
                            >
                            Front-end
                            </button>
                            <button
                                className={technologyTabs === 'Back-end' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('Back-end')}
                            >
                            Back-end
                            </button>
                            <button
                                className={technologyTabs === 'Mobile' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('Mobile')}
                            >
                            Mobile
                            </button>
                            <button
                                className={technologyTabs === 'Clouds' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('Clouds')}
                            >
                            Clouds
                            </button>
                            <button
                                className={technologyTabs === 'ERP & CRM' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('ERP & CRM')}
                            >
                            ERP & CRM
                            </button>
                            <button
                                className={technologyTabs === 'Other' ? 'home-secondary-tabs-active' : ''}
                                onClick={() => handleTechnologyTabClick('Other')}
                            >
                            Other
                            </button>
                        </div>

                    </div>


                    {/* tabs content */}
                    <div className='home-technologies-tabs-content'>
                        
                        {technologyTabs === 'Front-end' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Front-end</p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        React
                                        <img src={techimg1} alt='img'/>
                                    </p>
                                    <span>
                                        React, Redux, Redux-Saga, RxJS, React-Native, Three.JS, NextJS
                                    </span>
                                    {/* <button id='slide-button'><IoArrowForward size={24}/></button> */}
                                </div>
                                <div>
                                    <p>
                                        Angular
                                        <img src={techimg2} alt='img'/>
                                    </p>
                                    <span>
                                        Dominate the Android market with our cutting-edge app development services, reaching millions worldwide.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Vue.js
                                        <img src={techimg3} alt='img'/>
                                    </p>
                                    <span>Extend your IT team with our skilled
                                        front-end developers keeping fully
                                        abreast of the latest tech trends
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        JavaScript
                                        <img src={techimg4} alt='img'/>
                                    </p>
                                    <span>
                                        Crafting captivating UI/UX is crucial for mobile apps. Our team excels in designing visually stunning interfaces.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        TypeScript
                                        <img src={techimg5} alt='img'/>
                                    </p>
                                    <span>
                                        Ensuring flawless performance across devices and platforms, our rigorous app testing guarantees superior quality
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                        {technologyTabs === 'Back-end' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Back-end </p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        Java
                                        <img src={techimg7} alt='img'/>
                                    </p>
                                    <span>
                                        Java, Spring Framework (Core, Data, Boot, Batch, Cloud, Security, Retry, MVC.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        .Net
                                        <img src={techimg8} alt='img'/>
                                    </p>
                                    <span>
                                        .NET Core, ASP.NET Core MVC, ASP.NET Web API, ASP.NET MVC.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Python
                                        <img src={techimg9} alt='img'/>
                                    </p>
                                    <span>
                                        Django, Django Rest Framework, Flask, FastAPI, AsyncIo, AioHttp, Tornado.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Php
                                        <img src={techimg18} alt='img'/>
                                    </p>
                                    <span>
                                        DUKPT, Laravel, Symfony, Yii2, WordPress, Drupal, MySQL, MongoDB.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Node.js
                                        <img src={techimg10} alt='img'/>
                                    </p>
                                    <span>
                                        NestJS, NextJS, ExpressJS, MeteorJS, Koa, Electron, AWS, PostgreSQL, MongoDB.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Unity
                                        <img src={techimg11} alt='img'/>
                                    </p>
                                    <span>
                                    Unity, Godot, Blender, Krita, Amplify Shader Editor, MapBox SDK, Unity IAP.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Ruby
                                        <img src={techimg12} alt='img'/>
                                    </p>
                                    <span>
                                        Ruby on Rails, Sinatra, Hanami, Padrino, Volt, EventMachine, Rack,
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Go
                                        <img src={techimg13} alt='img'/>
                                    </p>
                                    <span>
                                        Go, gORM, Gin, protobuf, gRPC, AWS, GCP, Docker, Redis, Mongo DB,
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Rust
                                        <img src={techimg14} alt='img'/>
                                    </p>
                                    <span>
                                    Rust, Rustup, Cargo, clap, structopt, argh, actix-web, gotham, nickel, rocket.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        C/C++
                                        <img src={techimg15} alt='img'/>
                                    </p>
                                    <span>
                                    STL/C++11/14/17/20, GCC, CLang, MSVC++, WinDBG, GDB, Visual Studio.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Unreal Engine
                                        <img src={techimg16} alt='img'/>
                                    </p>
                                    <span>
                                    Unreal Engine, C++, C, C#, Blueprints, UAT, UE4 Shader Graph, Unity URP HLSL.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Cobol
                                        <img src={techimg17} alt='img'/>
                                    </p>
                                    <span>
                                        Cobol, GnuCOBOL, VSAM, JCL, TSO/ISPF, XPEDITER, FileAid, Changeman, CICS.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {technologyTabs === 'Mobile' && (
                        <div className='home-technologies-technologies-tabs-content-body-container'  >
                            <p>Mobile</p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        IOS
                                        <img src={techimg24} alt="img"/>
                                    </p>
                                    <span>
                                        Objective-C/Swift, MVP, MVVM, Clean Swift, SnapKit, GCD, Alamofire, URLSession.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Android
                                        <img src={techimg19} alt="img"/>
                                    </p>
                                    <span>
                                        Java/Kotlin, MVP, MVVM, Clean Architecture, AAC (ViewModel, LiveData.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Flutter
                                        <img src={techimg20} alt="img"/>
                                    </p>
                                    <span>
                                        Bloc, Clean Architecture, Flutter, Dart async, RxDart, Dart Streams, Navigator 2.0.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        React Native
                                        <img src={techimg21} alt="img"/>
                                    </p>
                                    <span>
                                        React-Native, React-Navigation, Redux, Redux-Saga, TypeScript.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Xamarin
                                        <img src={techimg22} alt="img"/>
                                    </p>
                                    <span>
                                        Xamarin.Native (Xamarin.iOS, Xamarin.Android.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        .NET MAUI
                                        <img src={techimg23} alt="img"/>
                                    </p>
                                    <span>
                                        .NET MAUI, .NET MAUI Blazor, Visual Studio, .NET Core, REST/SOAP API backend.
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {technologyTabs === 'Clouds' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Clouds</p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        AWS
                                        <img src={techimg25} alt='img'/>
                                    </p>
                                    <span>
                                        Amazon Elastic Compute Cloud (EC2), Amazon Simple Storage Service (S3),
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Azure
                                        <img src={techimg26} alt='img'/>
                                    </p>
                                    <span>
                                        Azure Virtual Machines, Azure Web Apps, Azure Functions, Azure Blob.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        GCP
                                        <img src={techimg27} alt='img'/>
                                    </p>
                                    <span>
                                        Google Compute Engine, Google App Engine, Google Kubernetes Engine, Google.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        DigitalOcean
                                        <img src={techimg28} alt='img'/>
                                    </p>
                                    <span>
                                        Docker, Kubernetes, Rancher, Saltstack, Puppet, Ansible, Droplets, Spaces.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Private clouds
                                        <img src={techimg29} alt='img'/>
                                    </p>
                                    <span>
                                        Terraform, Ansible, Chef, Kubernetes, Openshift, PowerShell (DSC).
                                    </span>
                                </div>
                            </div>
                        </div>
                        )}

                        {technologyTabs === 'ERP & CRM' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>ERP & CRM</p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>
                                        SAP
                                        <img src={techimg30} alt='img'/>
                                    </p>
                                    <span>
                                        SAP ABAP 7.5+, SAP UI5, SAP Fiori, JavaScript, HTML, XML, JSON, SAP S/4Hana(HANADB).
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        MS Dynamics 365
                                        <img src={techimg31} alt='img'/>
                                    </p>
                                    <span>
                                        XrmToolBox, Level Up, Sitemap Editor, Azure, Power Apps, Power BI, etc.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Odoo
                                        <img src={techimg32} alt='img'/>
                                    </p>
                                    <span>
                                    OdooRPC, Odoo Mobile Framework.
                                    </span>
                                </div>
                                <div>
                                    <p>
                                        Salesforce
                                        <img src={techimg33} alt='img'/>
                                    </p>
                                    <span>
                                        Salesforce, Apex code, Visualforce, Site.com, Customer 360 Platform.
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                        )}

                        {technologyTabs === 'Other' && (
                        <div className='home-technologies-tabs-content-body-container'  >
                            <p>Other</p>
                            <div className='home-technologies-tabs-content-body-wrapper'>
                                <div>
                                    <p>Embedded</p>
                                    <span>
                                        C, C++, Zephyr RTOS, FreeRTOS, eRTOS, Yocto, Buildroot, Altium Designer.
                                    </span>
                                </div>
                                <div>
                                    <p>Mainframes</p>
                                    <span>
                                        TSO, SDSF, ISPF, MQ, IPCS, SCLM, XDC, RACF, SMF, SMPE, DMS, JCL, JES, CICS.
                                    </span>
                                </div>
                                <div>
                                    <p>BI tools</p>
                                    <span>
                                        Microsoft Power BI, Tableau, Qlik, Looker, Grafana, Apache Superset, etc.
                                    </span>
                                </div>
                                <div>
                                    <p>eCommerce platforms</p>
                                    <span>
                                        Salesforce, Shopify, Magento, Oracle, PrestaShop, WooCommerce.
                                    </span>
                                </div>
                                <div>
                                    <p>CMS</p>
                                    <span>
                                        WordPress, Magento, Drupal, TYPO3, Concrete CMS, MODX.
                                    </span>
                                </div>
                                
                                <div>
                                    <p>And many more</p>
                                    <span>
                                        For a full list of our stack, visit the Technologies page or use the search function.
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        )}

                    </div>
                
                </div>  

            </div>

            {/* Case Studies */}
            <div className='home-featued-cases-container'>
                
                <div className='home-featued-cases-header' >
                    <p  >Featured Case Studies</p>
                    <Link>View All Stuided Cases</Link>
                </div>

                <div className='home-featued-cases-body' >

                    <div className='home-featued-cases-content'  >
                        <img src={imgSrc} alt='src'/>
                        <p>{title}</p>
                        <span>
                           {description}
                        </span>
                    </div>
                    <img src={bodyImgSrc} alt='abc'  />
                </div>

                <div className='home-featued-cases-button'>
                    <button onClick={prevCase}><MdOutlineArrowBack size={25}/></button>
                    <button onClick={nextCase}><MdOutlineArrowForward size={25}/></button>
                </div>

            </div>

           
            {/* contactus section */}
            <ContactUs/>

            <div className='inside-footer'>
                <Footer/>
            </div>

        </div>
    )}
    </>
  )
}

export default Home