import React from 'react'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import './OdooMigration.css'
import Nav from '../NavBar/Nav'
import img1 from '../../OdooAssets/OdooNewAssets/logo-odoo-couleur_0cf0a14c 2.png'
import img2 from '../../OdooAssets/OdooNewAssets/Data Migration.png'
import img3 from '../../OdooAssets/OdooNewAssets/Loading Bar.png'
import img4 from '../../OdooAssets/OdooNewAssets/f8607ce2-5835-4352-bee9-2113916701bf (1) 1.png'
import img5 from '../../OdooAssets/OdooNewAssets/Group 12 3432.png'
import img6 from '../../OdooAssets/OdooNewAssets/Group 98 3432.png'
import img7 from '../../OdooAssets/OdooNewAssets/Group 98 (1) 3432.png'
import icon1 from '../../OdooAssets/Icons/Gears.png'
import icon2 from '../../OdooAssets/Icons/Performance Macbook.png'
import icon3 from '../../OdooAssets/Icons/Security Shield.png'
import icon4 from '../../OdooAssets/Icons/3D House.png'
import icon5 from '../../OdooAssets/Icons/Query Inner Join.png'
import icon6 from '../../OdooAssets/Icons/Workflow.png'
import icon7 from '../../OdooAssets/Icons/Future.png'
import icon8 from '../../OdooAssets/Icons/Customer Insight.png'
import icon9 from '../../OdooAssets/Icons/Request Money.png'
import icon10 from '../../OdooAssets/Icons/Solar Energy.png'
import icon15 from '../../OdooAssets/Icons/settings-(1) 2.png'
import icon16 from '../../OdooAssets/Icons/arrows 5.png'
import icon17 from '../../OdooAssets/Icons/skill-development 5.png'
import icon18 from '../../OdooAssets/Icons/skill 5.png'
import icon19 from '../../OdooAssets/Icons/data-transfer 5.png'
import icon20 from '../../OdooAssets/Icons/presentation-(1) 3.png'
import icon21 from '../../OdooAssets/Icons/hg 2.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const OdooMigration = () => {
  return (
    <div className='OdooMigration'>

        <NavScrolled/>

        <div className='OdooMigration-part-1'>
            <img src={img1} alt='img1'/>
            <span>Migration</span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
        </div>

        <div className='OdooMigration-part-2'>
            <p>
                <img src={img2} alt='img'/>
                Seamless Odoo Migration
            </p>
            <span>
                Odoo Migration is the pivotal process of propelling your business to new heights through the latest advancements in the Odoo platform. It involves meticulous attention to detail, focusing on the smooth transition of vital data and the seamless integration of existing applications with updated features. Odoo Migration refers to the process of upgrading the Odoo platform to newer versions, incorporating the latest features and advancements into existing business operations. This entails meticulous attention to two key aspects.
            </span>
            <p>
                <img src={img3} alt='img'/>
                Pioneering Progress with Legion Soft
            </p>
            <span>
                Legion Soft specializes in providing flawless Odoo Migration services that are customized to meet your company's requirements. Our seasoned team of professionals optimizes the functionality of current programs in the upgraded version while also ensuring the seamless transfer of critical data, including financial records, customer and vendor details, and reports.
            </span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
        </div>

        <div className='OdooMigration-part-3'>
            <div>
                <p>Advanced Odoo Apps Migration</p>
                <span>
                    The process of migrating Odoo apps entails painstaking coding changes to bring installed apps and add-ons up to date with the most recent Odoo release. Our skilled staff applies cutting-edge Python coding methods to apply required modifications, guaranteeing smooth integration and improved performance. Furthermore, we put a high priority on preserving the recognizable frontend interface while adding cutting-edge functionality for the best possible user experience.
                </span>
                <Link><IoLogoWhatsapp/>Whatsapp</Link>
            </div>
            <img src={img4} alt='img'/>
        </div>

        <div className='OdooMigration-part-4'>
            <img src={img5} alt='img'/>
            <div>
                <p>Strategic Data Migration</p>
                <span>
                    When it comes to migration projects, data migration is critical. To guarantee a seamless transition, it requires cautious handling. Our knowledgeable staff carefully sets up current data to conform to the specifications of new platforms and applications. We map and modify tables thoroughly so that the upgraded version imports flawlessly and has optimal functionality
                </span>
                <Link><IoLogoWhatsapp/>Whatsapp</Link>
            </div>
        </div>

        <div className='OdooMigration-part-5'>
            <div>
                <p>Rigorous Migration Testing</p>
                <span>
                Beyond the realm of modifications lies the critical phase of migration testing, where our expertise truly shines. We subject the new platform to rigorous testing under diverse scenarios, ensuring its resilience and reliability. Through meticulous quality checks, we ascertain optimal performance and usability, ensuring a seamless transition for your operations.
                </span>
                <Link><IoLogoWhatsapp/>Whatsapp</Link>
            </div>
            <img src={img6} alt='img'/>
        </div>

        <div className='OdooMigration-part-6'>
            <img src={img7} alt='img'/>
            <div>
                <p>Importance of Migration</p>
                <span>
                The Odoo migration allows the users to upgrade to the new version of Odoo which is better, faster, and more reliable than the previous one. Odoo is a management software that keeps on updating with new versions, inculcating advanced operations. and adding new features that will help to run the business more effectively. Therefore this would be beneficial for the user as it will improve the productivity of the company.
                </span>
                <Link><IoLogoWhatsapp/>Whatsapp</Link>
            </div>
        </div>

        <div className='OdooMigration-part-7'>
            <p>The Significance of Odoo Migration</p>
            <span>Here are the key significance of Odoo migration</span>
            <div className='OdooMigration-part-6-content'>
                <div>
                    <div>
                        <img src={icon1} alt='logo'/>
                        <p>Access to advanced features and functionalities</p>
                    </div>
                    <div>
                        <img src={icon2} alt='logo'/>
                        <p>Improved performance and efficiency</p>
                    </div>
                    <div>
                        <img src={icon3} alt='logo'/>
                        <p>Enhanced security measures</p>
                    </div>
                    <div>
                        <img src={icon4} alt='logo'/>
                        <p>Better compatibility with new technologies</p>
                    </div>
                    <div>
                        <img src={icon5} alt='logo'/>
                        <p>Seamless integration with third-party applications</p>
                    </div>
                </div>
                <div>
                    <div>
                        <img src={icon6} alt='logo'/>
                        <p>Optimization of business processes</p>
                    </div>
                    <div>
                        <img src={icon7} alt='logo'/>
                        <p>Future-proofing your ERP system</p>
                    </div>
                    <div>
                        <img src={icon8} alt='logo'/>
                        <p>Streamlined user experience</p>
                    </div>
                    <div>
                        <img src={icon9} alt='logo'/>
                        <p>Reduction of operational costs</p>
                    </div>
                    <div>
                        <img src={icon10} alt='logo'/>
                        <p>Compliance with industry standards and regulations</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='OdooMigration-part-8'>
          <p>Our Odoo Services</p>
          <div className='OdooMigration-part-8-items'>
            <div>
              <span><img src={icon15} alt='icon15'/></span>
              <p>Odoo Customization</p>
            </div>
            <div>
              <span><img src={icon16} alt='icon15'/></span>
              <p>Odoo Implementation</p>
            </div>
            <div>
              <span><img src={icon17} alt='icon15'/></span>
              <p>Odoo Integration</p>
            </div>
            <div>
              <span><img src={icon18} alt='icon15'/></span>
              <p>OOdoo Support & Maintenance</p>
            </div>
            <div>
              <span><img src={icon19} alt='icon15'/></span>
              <p>Odoo Migration</p>
            </div>
            <div>
              <span><img src={icon20} alt='icon15'/></span>
              <p>Odoo Training</p>
            </div>
            <div>
              <span><img src={icon21} alt='icon15'/></span>
              <p>Odoo Consultancy</p>
            </div>
          </div>
        </div>

        <Footer/>

    </div>
  )
}

export default OdooMigration