import React from 'react'
import './OdooDemo.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import img1 from '../../OdooAssets/OdooNewAssets/Group 13 2342342s.png'
import img2 from '../../OdooAssets/OdooNewAssets/odoo-enterprise-vs-community 1.png'
import img3 from '../../OdooAssets/OdooNewAssets/Group 190.png'
import img4 from '../../OdooAssets/OdooNewAssets/c-community 2.png'
import img5 from '../../OdooAssets/OdooNewAssets/c-sap 2.png'
import icon1 from '../../OdooAssets/OdooNewAssets/stock 3.png'
import icon2 from '../../OdooAssets/OdooNewAssets/machine 3.png'
import icon3 from '../../OdooAssets/OdooNewAssets/cargo 3.png'
import icon4 from '../../OdooAssets/OdooNewAssets/patient 3.png'
import icon5 from '../../OdooAssets/OdooNewAssets/hairstyle 3.png'
import icon6 from '../../OdooAssets/OdooNewAssets/agreement 3.png'
import icon7 from '../../OdooAssets/OdooNewAssets/presentation.png'
import icon8 from '../../OdooAssets/OdooNewAssets/customer-service-(1).png'
import icon9 from '../../OdooAssets/Icons/7737365 1.png'
import icon10 from '../../OdooAssets/Icons/7034576 1.png'
import icon11 from '../../OdooAssets/Icons/7100768 1.png'
import icon12 from '../../OdooAssets/Icons/Icon-for-sales-1-1 1.png'
import icon13 from '../../OdooAssets/Icons/website-clipart-web-icon-4 1.png'
import icon14 from '../../OdooAssets/Icons/8764182 1.png'
import icon15 from '../../OdooAssets/Icons/4990622 1.png'
import icon16 from '../../OdooAssets/Icons/new-project-icon-13 1.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled'


const OdooDemo = () => {
  return (
    <div className='OdooDemo'>

        <NavScrolled/>

        <div className='OdooDemo-header'>
            <p>Request Your Complimentary Live Demo!</p>
            <span>Discover the comprehensive capabilities of Odoo firsthand with our free personalised demonstration.</span>
            <Link><IoLogoWhatsapp/>Whatsapp</Link>
        </div>

        <div className='OdooDemo-part-1'>
            <p>What is Odoo ERP?</p>
            <span>
            Odoo ERP, a comprehensive enterprise resource planning solution, offers businesses a versatile platform for managing their operations. Developed in the early 2000s, Odoo stands out as an open-source software, allowing extensive customization to suit diverse business needs. With a user base surpassing 12 million worldwide, Odoo continues to grow in popularity, owing to its efficiency and effectiveness in streamlining operations.
            </span>
        </div>

        <div className='OdooDemo-part-2-container'>
            <div className='OdooDemo-part-2'>
            <div>
                <p>Value of an Odoo Software Demo</p>
                <span>
                    A demonstration of the Odoo software provides an in-depth exploration of its features and functionalities, offering you valuable insights into its potential benefits for your business. At Legions Soft, we offer personalised, one-on-one demos with our experienced consultants, allowing you to delve into the intricacies of the Odoo platform tailored to your specific needs and objectives.
                    <br/> <br/>
                    In addition to scheduling a demo session, we provide a curated playlist encompassing various functional aspects of Odoo, presented in video format, to further enhance your understanding of its capabilities.
                </span>
                <div>
                    <span>Key Considerations for Your Odoo Demo:</span>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Define your priorities and specific areas of interest to ensure a customised demonstration aligned <br/> with your business requirements.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Schedule your demo at your convenience, selecting a time that suits your schedule.</p>
                    <p><MdKeyboardDoubleArrowRight size={30}/> Prepare a list of questions and queries to discuss with our consultants during the session, <br/>maximising the value of your demo experience.</p>
                    <div><Link><IoLogoWhatsapp/>Whatsapp</Link></div>
                </div>
            </div>
            <img src={img1} alt='img1'/>
            </div>
        </div>

        <div className='OdooDemo-part-3-container'>
            <div className='OdooDemo-part-3'>
            <div>
                <p>Exploring the Power of Odoo ERP</p>
                <span>
                    Harness the capabilities of Odoo ERP to streamline your business operations and drive growth.
                </span>
                <div>
                    <p><span>Open-Source Solution:</span>Benefit from Odoo's open-source nature for flexible customization.</p>
                    <p><span>Cost-Effective Efficiency:</span>Enjoy efficient operations without hefty expenses.</p>
                    <p><span>Versatile Across Industries:</span>Odoo caters to diverse business needs.</p>
                    <p><span>Extensive User Base:</span>Trusted by over 12 million users worldwide.</p>
                    <p><span>Vast App Ecosystem:</span>Access numerous Odoo apps for tailored functionality.</p>
                    <p><span>Seamless Customization:</span>Easily customise Odoo to fit your unique requirements.</p>
                </div>
            </div>
            <img src={img3} alt='img1'/>
            </div>
        </div>


        <div className='OdooDemo-part-4'>
            <div className='OdooDemo-part-4-container'>
                <p>
                    Business Industries in Odoo
                </p>
                <span>
                    Odoo can undergo full-fledged customization which makes it capable of operating in any form of industry. In addition, there are numerous modules of operations that can be installed to run in the platform of various application-specific operations which can be downloaded from the Odoo apps store. Here, is a list of industries which the Odoo platform will suit better in function which are not limited to also:
                </span>

                <div className='OdooDemo-part-4-content'>
                    <div>
                        <img src={icon1} alt='icon1'/>
                        <p>Trading</p>
                    </div>
                    <div>
                        <img src={icon2} alt='icon1'/>
                        <p>Embroidery</p>
                    </div>
                    <div>
                        <img src={icon3} alt='icon1'/>
                        <p>Logistics</p>
                    </div>
                    <div>
                        <img src={icon4} alt='icon1'/>
                        <p>Healthcare</p>
                    </div>
                    <div>
                        <img src={icon5} alt='icon1'/>
                        <p>Salon Management</p>
                    </div>
                    <div>
                        <img src={icon6} alt='icon1'/>
                        <p>Real Estate</p>
                    </div>
                    <div>
                        <img src={icon7} alt='icon1'/>
                        <p>Education</p>
                    </div>
                    <div>
                        <img src={icon8} alt='icon1'/>
                        <p>Whole Sale</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='OdooDemo-part-5'> 
            <p>Odoo Enterprise vs Odoo Community</p>
            <img src={img2} alt='img2'/>
            <div>
                <Link>Odoo Community Features </Link>
                <Link>Odoo Enterprise Features </Link>
            </div>
        </div>

        <div className='OdooDemo-part-6-container'>
          <p>Why Legions Soft</p>
          <span>Our team is here to help you identify any technical problems you're experiencing.</span>
          <div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> High coding standards</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Expert in all versions of Odoo</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 8+ years of experience</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 900+ satisfied customers across the globe</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> 790+ Free modules contributions in Odoo apps</p>
            </div>
            <div>
              <p><MdKeyboardDoubleArrowRight size={30}/> Ranks Fifth in Odoo app downloads</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High-quality deliverables</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Technical & Functional expertise in all Odoo modules</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> Service in both enterprise & community editions</p>
              <p><MdKeyboardDoubleArrowRight size={30}/> High quality deliverables</p>
            </div>
          </div>
        </div>

        <div className='OdooDemo-part-8'>
            <p>Odoo   modules</p>
            <span>Each module within the Odoo ERP fulfils specific functions, working seamlessly together to accomplish business objectives.</span>
            <div className='OdooDemo-part-8-container'>
                <div>
                    <div>
                        <img src={icon9} alt='icon'/>
                        <p>Odoo ERP Accounting Module:</p>
                    </div>
                    <p>The accounting module within Odoo serves as a comprehensive tool for managing all financial aspects of your company. Integrated directly with invoicing, sales, purchasing, and other supporting modules, it enables you to define taxes, payment methods, and options, manage payment acquirers, set fiscal year parameters, and more, ensuring efficient financial management.</p>
                </div>

                <div>
                    <div>
                        <img src={icon10} alt='icon'/>
                        <p>Odoo ERP Invoicing Module:</p>
                    </div>
                    <p>The invoicing module in Odoo handles the invoicing procedures for product and service sales. Integrated seamlessly with accounting, sales, e-commerce, POS, and other modules, it facilitates various aspects of invoice generation, management, payment assignment, and customization to align with company operational requirements.</p>
                </div>

                <div>
                    <div>
                        <img src={icon11} alt='icon'/>
                        <p>Odoo ERP CRM Module:</p>
                    </div>
                    <p>Customer Relationship Management (CRM) is a cornerstone of effective company operations, and Odoo offers a dedicated module to fulfil this need. With the CRM module, you can efficiently generate, enrich, and manage leads, as well as track activities and opportunities associated with them. Additionally, the module integrates seamlessly with marketing operations, enhancing lead nurturing capabilities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon12} alt='icon'/>
                        <p>Odoo ERP Sales Module:</p>
                    </div>
                    <p>The sales module within Odoo ERP serves as the central hub for all sales operations within the company. From generating and sending sales quotations to managing the sales process and providing detailed product descriptions to customers, this module streamlines the entire sales workflow. It seamlessly integrates with other key modules such as e-commerce, POS, invoicing, accounting, inventory, and purchasing, ensuring smooth and efficient sales operations across the organisation.</p>
                </div>

                <div>
                    <div>
                        <img src={icon13} alt='icon'/>
                        <p>Odoo ERP Website Module:</p>
                    </div>
                    <p>The Odoo ERP's website module provides comprehensive tools for businesses to create, design, configure, and manage their company websites effectively. Integrated with an intuitive website builder, this module facilitates seamless e-commerce operations and ensures efficient management of online presence and functionalities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon14} alt='icon'/>
                        <p>Odoo ERP E-Commerce Module:</p>
                    </div>
                    <p>The E-commerce module within Odoo ERP is integral for modern businesses, facilitating the establishment of robust online platforms. With comprehensive features and tools embedded in the website module, Odoo enables businesses to effectively describe, operate, and manage their e-commerce ventures, including product sales and operational functionalities.</p>
                </div>

                <div>
                    <div>
                        <img src={icon15} alt='icon'/>
                        <p>Odoo ERP POS Module:</p>
                    </div>
                    <p>The Odoo Point of Sale (POS) module is a comprehensive retail sales management tool integrated within the Odoo platform. It offers seamless functionality for in-store transactions, providing direct invoicing capabilities. Additionally, the module facilitates operations for bars and restaurants, enabling efficient management in live environments.</p>
                </div>

                <div>
                    <div>
                        <img src={icon16} alt='icon'/>
                        <p>Odoo ERP Project Module:</p>
                    </div>
                    <p>The Odoo project module serves as a comprehensive tool for creating and managing projects within a company, facilitating seamless project management. Integrated with other operational modules of Odoo ERP, it enhances project operational efficiency and management capabilities across the organisation.</p>
                </div>

            </div>
        </div>

        <div className='OdooDemo-part-9'>
            <p>Odoo Comparison</p>
            <span>makes Odoo stand out among other ERPs.</span>
            <div className='OdooDemo-part-9-container'>
                <div>
                    <img src={img5} alt='img'/>
                    <p>Odoo Vs SAP</p>
                </div>
                <div>
                    <img src={img4} alt='img'/>
                    <p>Odoo Community Vs Enterprise</p>
                </div>
            </div>
        </div>

        <div className='OdooDemo-part-10'>
            <p>Frequently Asked Questions</p>
            <span>For users of the Odoo community edition, there is no charge, as it is an open-source platform. However, for the Odoo enterprise edition, a subscription fee is incurred based on the number of users and selected add-ons.</span>
            
            <div>
                <p>Is there a fee for using Odoo?</p>
                <span>
                As an official Odoo partner, Legions Soft offers comprehensive customization and integration services.
                </span>
            </div>

            <div>
                <p>What is the pricing structure for Odoo enterprise?</p>
                <span>
                    The pricing for Odoo enterprise varies depending on the selected apps and the number of users. You can find detailed pricing information on our Odoo Pricing page, where you can customise your plan according to your specific needs.
                </span>
            </div>
            <div>
                <p>Is the Odoo community edition offered free of charge?</p>
                <span>
                Yes, the Odoo community edition is available at no cost. The only potential expenses are related to server hosting and any customization required for your implementation.
                </span>
            </div>
            <div>
                <p>Are there any hidden fees associated with Odoo enterprise?</p>
                <span>
                    In addition to the subscription fee, there may be charges for server hosting and additional integrations, such as connectors for shipping services or voice over IP (VoIP) systems, depending on your specific requirements.
                </span>
            </div>
            <div>
                <p>How is the Odoo subscription billed?</p>
                <span>
                    Odoo subscriptions are billed annually, although monthly subscription options are available for Odoo online users.
                </span>
            </div>
        </div>

        <Footer/>

    </div>
  )
}

export default OdooDemo