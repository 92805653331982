import React from 'react'
import { IoLogoWhatsapp } from "react-icons/io";

import './OdooERPEducation.css'
import Nav from '../NavBar/Nav'
import { Link } from 'react-router-dom';
import img1 from '../../OdooAssets/OdooSubServicesAssets/education-infographic 1.png'
import img2 from '../../OdooAssets/OdooSubServicesAssets/industries-education-erp-01 1.png'
import img3 from '../../OdooAssets/OdooSubServicesAssets/industries-education-erp-02 1.png'
import icon1 from '../../OdooAssets/Icons/Checkmark.png'
import Footer from '../Footer/Footer'
import NavScrolled from '../NavBar/NavScrolled';



const OdooERPEducation = () => {
  return (
    <div className='OdooERPEducation'>

      <NavScrolled/>

      <div className='OdooERPEducation-header'>
        <p>Odoo ERP for Education</p>
        <span>The unique tool for the educational institution management</span>
        <Link><IoLogoWhatsapp/>Whatsapp</Link>
      </div>

      

      <div className='OdooERPEducation-part1'>
        <p>Managing the diverse needs of students and staff across educational institutions can be challenging. With Odoo, tailor comprehensive management solutions to meet your specific operational requirements, ensuring smoother operations for schools, colleges, and other institutions.</p>
        <span></span>
      </div>

      <div className='OdooERPEducation-part2'>
        <p>Odoo Education Institution Management</p>
        <img src={img1} alt='Service'/>
      </div>

      <div className='OdooERPEducation-part3'>
        <p>Efficient Staff Management</p>
        <span>Utilize Odoo's HR modules to seamlessly handle everything from recruitment to employee payments, supported by intuitive operational tools.</span>
        <div className='OdooERPEducation-part3-content'>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Online recruitment</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Employee payroll</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Attendance management</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Timesheets are used to depict the time spent on tasks</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Create students profile</p>
            </div>
          </div>

          <div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Examination and valuation</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Attendance monitoring on students</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Generate monthly payrolls</p>
            </div>
            <div>
              <img src={icon1} alt='icon1'/>
              <p>Print out payslips of each employee</p>
            </div>
          </div>

        </div>
        <img src={img2} alt='img2'/>
      </div>

      <div className='OdooERPEducation-part4'>
        <p>Student Attendance Tracking</p>
        <span>Odoo's dedicated attendance management module can be customized to effectively monitor and track student attendance.</span>
        <div>
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Register attendance using a biometric device</p>
          </div>
          <div>
            <img src={icon1} alt='icon1'/>
            <p>Dedicated operational methodology</p>
          </div>
          {/* <div>
            <img src={icon1} alt='icon1'/>
            <p>Register attendance using a biometric device</p>
          </div> */}
        </div>
        <img src={img3} alt='img3'/>
      </div>

      <div className='OdooERPService-part5'>
        <div>
          <div></div>
          <p>Why choose Legions Soft?</p>
          <span>Crafting an excellent service management solution can only be done by experts, professionals and developers who have high amounts of expertise in it.Odoo experts who are well capable of crafting exceptional service management solutions for your business.</span>
        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default OdooERPEducation